import { IonContent, IonPage } from '@ionic/react';
import OrderList from 'components/order-list/OrderListing';

const OrderListing: React.FC = () => {

  return (
    <IonPage className='page-with-menu'>
      <IonContent>
        <OrderList />
      </IonContent>
    </IonPage>
  );
};

export default OrderListing;
