import { IonHeader, IonToolbar, IonButtons, IonMenuButton, IonButton, IonIcon, IonBadge, IonRouterLink} from '@ionic/react';
import { search, notificationsOutline, cartOutline } from  'ionicons/icons';
import { useHistory } from 'react-router'; 
import { useCart } from '../../services/useCart';
import { useEffect, useState } from 'react';
import './Header.scss';
import Notification from "./notification/Notification";
import { useSession } from '../../services/useSession';
import { getStoredKey, setStoredKey } from 'services/storage-service';
import constants from '../../constants';
import { User } from 'models/User';
import { getTranslation } from 'translations';
import CachedImage from 'components/cached-image/CachedImage';
import { getBrandConfig } from 'services/auth-service';

const Header: React.FC = () => {
  const [hasAccess, setHasAccess] = useState(false);
  const [displayDiscount, setDisplayDiscount] = useState(false);
  const [exchangeUser, setExchangeUser] = useState(false);
  const [clientLogo, setClientLogo] = useState("");
  const history = useHistory(); 
  const { isAuthenticated, getUserInfo, userLanguageCode, logout, changeExchangeStatus } = useSession();

  const { totalCartItems, remainingCredits } = useCart();

  useEffect(() => {
    async function getData() {
        let logoImage = await getStoredKey(constants.CLIENT_LOGO);
        await setClientLogo(logoImage);

        const result: User | undefined = await getUserInfo();
        const clientExchangeUser: boolean = await getStoredKey(constants.CLIENT_EXCHANGE_USER);
        setExchangeUser(clientExchangeUser);

        console.log("user data", result)
        console.log("user exchange", clientExchangeUser)

        if((result && result.customer && result.customer.b2c) || clientExchangeUser){
          setHasAccess(true);
        } else if(!result) {
          setTimeout(() => {
            logout();
          }, 1000);
        }
        
        const brandConfig: any = await getBrandConfig(constants.CONFIG_TYPE, true);

        if(brandConfig.brandDynamic.config.flags.directoryMode){
          await setStoredKey(constants.CLIENT_EXCHANGE_USER, true);
          changeExchangeStatus(true);
        }
    }

    if(isAuthenticated) {
      getData();
    }
  }, [isAuthenticated, history]);

  useEffect(() => {
    setDisplayDiscount(history.location.pathname == "/cart")

    history.listen((event: any) => {
      setDisplayDiscount(event.pathname == "/cart")
    })
  }, []);

  return (
    <>
      {isAuthenticated && hasAccess && 
        <IonHeader className='app-header'>
          <IonToolbar mode='md'>
            <IonButtons slot="start">
              <IonMenuButton />
              <IonButton routerLink={"/search"}>
                <IonIcon icon={search} />
              </IonButton>
            </IonButtons>
            {clientLogo != "" && <IonRouterLink routerLink='/explore'><CachedImage className='menu-logo' src={clientLogo} /></IonRouterLink>}
            <IonButtons slot="end">
              <IonButton routerLink={"/notifications"} className="semi-hidden">
                {/* This is visually hidden for now, likely to come back in later phases. It needs to be here so that the brand logo stays centered as per design */}
                <IonIcon icon={notificationsOutline} color="primary" />
              </IonButton>
              <IonButton className={'cart-button ' + (exchangeUser ? "semi-hidden" : "")} routerLink={"/cart"}>
                <IonIcon icon={cartOutline} color="primary" />
              </IonButton>
            </IonButtons>
            {totalCartItems > 0 && <IonBadge className='cart-badge-number' color="danger">{totalCartItems}</IonBadge>}
          </IonToolbar>
          <Notification />
          {remainingCredits && displayDiscount && totalCartItems > 0 && <p className={'total-discount ' + ((remainingCredits < 0) ? "over" : "")}>{getTranslation('remainingCredits', userLanguageCode)}: <strong>${remainingCredits.toFixed(2)}</strong></p>}
        </IonHeader>
      }
    </>
  );
};

export default Header;
