import { IonIcon, IonicSlides, IonButton } from "@ionic/react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useSession } from "services/useSession";
import { getTranslation } from "translations";
import "./ExploreAnnouncements.scss";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperInterface } from 'swiper';

interface ExploreAnnouncementsProps {
  announcements: any[];
}

const ExploreAnnouncements: React.FC<ExploreAnnouncementsProps> = (props) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [sorted, setSorted] = useState<boolean>(false);

  const { userLanguageCode } = useSession();

  useEffect(() => {

    if (props.announcements && props.announcements.length > 1) {
      props.announcements.sort((a: any, b: any) => (new Date(a.created_at) as any) - (new Date(b.created_at) as any));
    }

    setSorted(true)
  }, [props.announcements]);

  const moveToNewSlide = async (direction: string) => {
    if (swiperInstance) {
      if (direction === "next") {
        swiperInstance.slideNext();
      } else if (direction === "prev") {
        swiperInstance.slidePrev();
      }
    }
  };

  const onWillChange = async (event: any) => {
    setCurrentIndex(event.realIndex);
  };

  return (
    <div className="explore-announcements">
      {props.announcements.length > 0 && sorted && (
        <div className="announcements-container">
          <Swiper onSwiper={(swiper) => setSwiperInstance(swiper)} 
            modules={[IonicSlides]} 
            pagination={false} 
            autoHeight={true}
            slidesPerView={1}
            onSlideChangeTransitionStart={(event: any) => onWillChange(event)}
          >
            {props.announcements.map((announcement: any, index: number) => {
              return (
                <SwiperSlide key={index}>
                  <div className="slide-wrapper">
                    <div className="announce-title">{getTranslation('latestAnnouncements', userLanguageCode)}</div>
                    <div className="announce-text">{announcement.text}</div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {props.announcements.length > 1 &&
            <div className="nav">
              <IonButton disabled={currentIndex === 0} fill="clear" onClick={() => moveToNewSlide("prev")}>
                <IonIcon icon={chevronBackOutline}></IonIcon>{" "}
              </IonButton>
              <div className="nav-text">
                {currentIndex + 1} {getTranslation('of', userLanguageCode)} {props.announcements.length}
              </div>
              <IonButton disabled={(currentIndex + 1) === props.announcements.length} fill="clear" onClick={() => moveToNewSlide("next")}>
                <IonIcon icon={chevronForwardOutline}></IonIcon>{" "}
              </IonButton>
            </div>
          }
        </div>
      )}
    </div>
  );
};

export default ExploreAnnouncements;
