import { useContext } from 'react';
import { ConnectionContext } from './ConnectionContext';

export const useConnection = () => {
  const { connected } =
    useContext(ConnectionContext);

  if (ConnectionContext === undefined) throw new Error('useConnection must be used within a ConnectionContext');

  return { connected };
};