import { IonButton, IonButtons, IonInput, IonItem, IonLabel, IonList } from '@ionic/react';
import { useSession } from 'services/useSession';
import { getTranslation } from 'translations';

interface ProductSizeMenuProps {
  product: any;
  pendingQuantities: any[];
  groups: string[];
  currentIndex: number;
  updateStockQuantity: Function;
  selectedGroup: string;
  selectGroup: Function;
}

const ProductSizeMenu: React.FC<ProductSizeMenuProps> = (props) => {
  const { userLanguageCode } = useSession();
  return (
    <>
      {props.groups.length > 1 &&
        <div className='group-menu'>
          <div className="title">{getTranslation('size', userLanguageCode)}</div>
            <IonButtons>
              {props.groups.map((group: any, index: any) => {
                return (
                  <div key={index}>
                    {!group.hide &&
                      <IonButton className="button-size" onClick={() => props.selectGroup(group.name)} color={props.selectedGroup === group.name ? "secondary" : "primary"}>{group.name}</IonButton>
                    }
                  </div>
                );
              })}
            </IonButtons>
        </div>
      }
      {props.groups.length == 0 && props.selectedGroup === "" && 
        <p className='loading-groups'>Loading sizes...</p>
      }
      <div className="sizing-menu">
        <div className="title">{getTranslation('enterQuantities', userLanguageCode)}</div>
        {props.selectedGroup &&
          <IonList>
            <IonItem lines="none" className='size-items-header'>
              <IonLabel className='quantity-label' slot='start'>{getTranslation('qty', userLanguageCode)}</IonLabel>
              <IonLabel slot='start'>{getTranslation('size', userLanguageCode)}</IonLabel>
              {/* //TODO: for Phase 2 */}
              {/* <IonLabel slot='end'>Availible</IonLabel> */}
            </IonItem>

            {props.product.variations[props.currentIndex].stock_items.map((size: any, index: any) => {
              let existingQuantity = props.pendingQuantities[props.currentIndex].stock_items[index];
              return (
                <div key={index}>
                  {(props.selectedGroup === "ALL" || props.selectedGroup === size.name) &&
                    <IonItem lines='none' className='size-item'>
                      <IonInput
                        disabled={existingQuantity.quantity_availible <= 0}
                        slot={'start'}
                        class={"text-input"}
                        type={"text"}
                        inputmode={"numeric"}
                        onIonChange={async (event: any) => props.updateStockQuantity(event, index)}
                        value={existingQuantity.quantity === 0 ? null : existingQuantity.quantity}
                      />
                      <IonLabel className="label-size" slot='start' text-wrap>{props.selectedGroup === "ALL" ? size.name : size.alt_name}</IonLabel>
                      {/* //TODO: for Phase 2 */}
                      {/* <IonBadge slot='end'>10</IonBadge> */}
                    </IonItem>
                  }
                </div>
              );
            })}
          </IonList>
        }
        {props.selectedGroup === "" &&
          <div className='select-group'>
            <p>{getTranslation('chooseA', userLanguageCode)} <strong>{getTranslation('size', userLanguageCode)}</strong> {getTranslation('aboveInOrder', userLanguageCode)}</p>
          </div>
        }
      </div>
    </>
  );
};

export default ProductSizeMenu;
