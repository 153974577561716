import { IonCol, IonText, IonCard, IonCardHeader, IonCardContent } from '@ionic/react';
import CachedImage from 'components/cached-image/CachedImage';
import './ProductItem.scss';

interface ProductItemProps {
  index: number;
  imageToShow: string;
  name: string;
  number: string;
  priceRangeToShow: string;
  customizable: any;
  variations: any[];
}

const ProductItem: React.FC<ProductItemProps> = (props) => {

  function productDetailsText () {
    if(props.customizable) {
      return " - Customizable";
    }

    if(props.variations.length > 1) {
      return props.variations.filter((item) => { return !item.filtered }).length + " Colors";
    }
    
    return "";
  }

  return (
    <IonCol size="6" style={{"--ion-grid-column-padding": "0"}} className={(props.index % 2 === 0 ? "ion-padding-end" : "ion-padding-start")}>
      <IonCard className={'product'} routerLink={"/product-detail/" + props.number}>
        <IonCardHeader>
          <CachedImage className='product-image' src={props.imageToShow} />
        </IonCardHeader>
        <IonCardContent className="ion-padding">
          <h3>{props.name} <br /></h3>
          <IonText color="medium">{props.number} <br /></IonText> 
          <IonText color="medium">{props.priceRangeToShow} <br /></IonText> 
          <IonText color="medium">{productDetailsText()}</IonText> 
        </IonCardContent>
      </IonCard>
    </IonCol>
  );
};

export default ProductItem;