import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonLabel, IonModal, IonSelect, IonSelectOption, IonToolbar } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React from 'react';
import { StateObject, useStates } from 'react-us-states';
import { useSession } from 'services/useSession';
import { getTranslation } from 'translations';

interface DropShipModalProps {
  setDropShipOpen: Function;
  dropShipOpen: boolean;
  modalRef: any;
  dropShipErrorRef: any;
  dropShipAddressError: boolean;
  dropShipFieldErrors: any;
  dropShipFieldRegExps: any
  dropShipEmailField: boolean;
  dropShipPhoneField: boolean;
  dropShipFieldMaxLengths: any;
  setNewDropShipAddress: Function;
  dropshipAllowed: boolean;
  newDropShipAddress: any;
  doChangeDropShipAddress: Function;
}

const DropShipModal: React.FC<DropShipModalProps> = (props) => {
  const usStates = useStates();
  const { userLanguageCode } = useSession();

  return (
    <IonModal className='pwa-modal form-modal' onIonModalDidDismiss={() => { props.setDropShipOpen(false) }} isOpen={props.dropShipOpen} ref={props.modalRef} initialBreakpoint={1}>
      <IonHeader className="no-border-toolbar">
        <IonToolbar>
          <h2>{getTranslation('dropShipAddressTitle', userLanguageCode)}</h2>
          <IonButtons slot="end">
            <IonButton onClick={() => { props.modalRef.current?.dismiss(); }}><IonIcon class="icon" size="large" icon={closeOutline} color="primary" /></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='address-form'>
          {props.dropShipAddressError && 
            <div className='error-text'>
              <p ref={props.dropShipErrorRef} id="error">{getTranslation('dropShipAddressError', userLanguageCode)}</p>
            </div>
          }
          {props.dropShipEmailField &&
            <div className='input-wrapper'>
              <IonLabel position="stacked">{getTranslation('email', userLanguageCode)}</IonLabel>
              <IonInput maxlength={props.dropShipFieldMaxLengths.emailMaxLength} value={ props.newDropShipAddress.email } onIonBlur={(event: any) => { 
                  props.setNewDropShipAddress((oldAddress: any) => ({
                    ...oldAddress,
                    email: event.detail.target.value}));
                }}></IonInput>
                {props.dropShipFieldErrors.email && 
                  <div className='error-text'>
                    <p>{getTranslation('dropShipRegExError', userLanguageCode)} {props.dropShipFieldRegExps.emailRegExp}</p>
                  </div>
                }
            </div>
          }
          {props.dropShipPhoneField &&
            <div className='input-wrapper'>
              <IonLabel position="stacked">{getTranslation('phone', userLanguageCode)}</IonLabel>
              <IonInput maxlength={props.dropShipFieldMaxLengths.phoneMaxLength} value={ props.newDropShipAddress.phone } onIonBlur={(event: any) => { 
                  props.setNewDropShipAddress((oldAddress: any) => ({
                    ...oldAddress,
                    phone: event.detail.target.value}));
                }}></IonInput>
                {props.dropShipFieldErrors.phone && 
                  <div className='error-text'>
                    <p>{getTranslation('dropShipRegExError', userLanguageCode)} {props.dropShipFieldRegExps.phoneRegExp}</p>
                  </div>
                }
            </div>
          }
          <div className='input-wrapper'>
            <IonLabel position="stacked">{getTranslation('attentionTo', userLanguageCode)}</IonLabel>
            <IonInput maxlength={props.dropShipFieldMaxLengths.nameMaxLength} value={ props.newDropShipAddress.name } onIonBlur={(event: any) => { 
                props.setNewDropShipAddress((oldAddress: any) => ({
                  ...oldAddress,
                  name: event.detail.target.value}));
              }}></IonInput>
              {props.dropShipFieldErrors.name && 
                <div className='error-text'>
                  <p>{getTranslation('dropShipRegExError', userLanguageCode)} {props.dropShipFieldRegExps.nameRegExp}</p>
                </div>
              }
          </div>
          <div className='input-wrapper'>
            <IonLabel position="stacked">{getTranslation('address1', userLanguageCode)}</IonLabel>
            <IonInput maxlength={props.dropShipFieldMaxLengths.address1MaxLength} value={ props.newDropShipAddress.address1 } onIonBlur={(event: any) => { 
                props.setNewDropShipAddress((oldAddress: any) => ({
                  ...oldAddress,
                  address1: event.detail.target.value}));
              }}></IonInput>
              {props.dropShipFieldErrors.address1 && 
                <div className='error-text'>
                  <p>{getTranslation('dropShipRegExError', userLanguageCode)} {props.dropShipFieldRegExps.address1RegExp}</p>
                </div>
              }
          </div>
          <div className='input-wrapper'>
            <IonLabel position="stacked">{getTranslation('address2', userLanguageCode)}</IonLabel>
            <IonInput maxlength={props.dropShipFieldMaxLengths.address2MaxLength} value={ props.newDropShipAddress.address2 } onIonBlur={(event: any) => { 
                props.setNewDropShipAddress((oldAddress: any) => ({
                  ...oldAddress,
                  address2: event.detail.target.value}));
              }}></IonInput>
              {props.dropShipFieldErrors.address2 && 
                <div className='error-text'>
                  <p>{getTranslation('dropShipRegExError', userLanguageCode)} {props.dropShipFieldRegExps.address2RegExp}</p>
                </div>
              }
          </div>
          <div className='input-wrapper'>
            <IonLabel position="stacked">{getTranslation('city', userLanguageCode)}</IonLabel>
            <IonInput maxlength={props.dropShipFieldMaxLengths.cityMaxLength} value={ props.newDropShipAddress.city } onIonBlur={(event: any) => { 
                props.setNewDropShipAddress((oldAddress: any) => ({
                  ...oldAddress,
                  city: event.detail.target.value}));
              }}></IonInput>
              {props.dropShipFieldErrors.city && 
                <div className='error-text'>
                  <p>{getTranslation('dropShipRegExError', userLanguageCode)} {props.dropShipFieldRegExps.cityRegExp}</p>
                </div>
              }
          </div>
          <div className='input-wrapper custom-select'>
            <IonLabel position="stacked">{getTranslation('state', userLanguageCode)}</IonLabel>
            <IonSelect placeholder="Choose an Option" value={ props.newDropShipAddress.state } onIonChange={(event: any) => { 
              props.setNewDropShipAddress((oldAddress: any) => ({
                ...oldAddress,
                state: event.detail.value}));
            }} >
              {usStates.map((state: StateObject, index: number) => {
                return(
                  <IonSelectOption key={index} value={state.abbreviation}>
                    {state.name}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
            {props.dropShipFieldErrors.state && 
              <div className='error-text'>
                <p>{getTranslation('dropShipRegExError', userLanguageCode)} {props.dropShipFieldRegExps.stateRegExp}</p>
              </div>
            }
          </div>
          <div className='input-wrapper'>
            <IonLabel position="stacked">{getTranslation('zip', userLanguageCode)}</IonLabel>
            <IonInput maxlength={props.dropShipFieldMaxLengths.zipMaxLength} value={ props.newDropShipAddress.zip } onIonBlur={(event: any) => { 
                props.setNewDropShipAddress((oldAddress: any) => ({
                  ...oldAddress,
                  zip: event.detail.target.value}));
              }}></IonInput>
              {props.dropShipFieldErrors.zip && 
                <div className='error-text'>
                  <p>{getTranslation('dropShipRegExError', userLanguageCode)} {props.dropShipFieldRegExps.zipRegExp}</p>
                </div>
              }
          </div>
          <div className='input-wrapper custom-select'>
            <IonLabel position="stacked">{getTranslation('country', userLanguageCode)}</IonLabel>
            <IonSelect value={"united_states"}>
              <IonSelectOption value={ props.newDropShipAddress.country }>
                United States
              </IonSelectOption>
            </IonSelect>
          </div>
        </div>
        <div className='modal-actions'>
          <IonButton
            type="button"
            expand="block"
            color={"secondary"}
            onClick={() => { props.modalRef.current?.dismiss(); }}
          >
            {getTranslation('cancel', userLanguageCode)}
          </IonButton>
          <IonButton
            type="button"
            expand="block"
            onClick={() => { props.doChangeDropShipAddress(); }}
          >
            {getTranslation('continue', userLanguageCode)}
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default DropShipModal;
