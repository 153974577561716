import { getStoredKey, setStoredKey, shouldUseCachedValue } from './storage-service';
import constants from '../constants';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';

const client = new ApolloClient({
    uri: `${process.env.REACT_APP_GRAPHQL_CDA_URL}?access_token=${process.env.REACT_APP_GRAPHQL_CDA_ACCESS_TOKEN}`,
    cache: new InMemoryCache(),
});

export async function getBrand(online: boolean, brandKey: string = "") {
    const key = `${constants.BRAND_LIST}_${brandKey}`
    const storedValue = await getStoredKey(key);
    if (shouldUseCachedValue(storedValue, online, true)) {
        return storedValue;
    } else {
        const query = await client.query({ query: BRAND_QUERY, variables: { brandKey: decodeURIComponent(brandKey) } })
        const result = query.data.brandCollection.items[0]
        await setStoredKey(key, result)
        return result;
    }
}

const BRAND_QUERY = gql`
    query GetBrandDetails($brandId: String, $brandKey: String) {
        brandCollection(
            where: {OR: [{key: $brandKey}, {sys: {id: $brandId}}]}
            limit: 1
        ) {
            items {
            sys {
                id
                __typename
            }
            name
            key
            companyAddress
            companyHeadquarter {
                city
                state
                country
                __typename
            }
            enterpriseUrl
            tagsValues
            contentBlocksCollection(limit: 10) {
                items {
                __typename
                ... on BrandIntro {
                    sys {
                    id
                    __typename
                    }
                    description
                    __typename
                }
                ... on BrandContactDetails {
                    sys {
                    id
                    __typename
                    }
                    phone
                    email
                    businessWebSite
                    socialLinksCollection(limit: 10) {
                    items {
                        sys {
                        id
                        __typename
                        }
                        type
                        uri
                        __typename
                    }
                    __typename
                    }
                    __typename
                }
                ... on ColumnContainer {
                    sys {
                    id
                    __typename
                    }
                    columnsCollection(limit: 10) {
                    items {
                        sys {
                        id
                        __typename
                        }
                        heading
                        description
                        image {
                        url(
                            transform: {width: 587, height: 783, quality: 90, format: JPG, resizeStrategy: FIT}
                        )
                        width
                        height
                        __typename
                        }
                        __typename
                    }
                    __typename
                    }
                    __typename
                }
                ... on Marquee {
                    sys {
                    id
                    __typename
                    }
                    image {
                    url(transform: {quality: 90, format: JPG})
                    height
                    width
                    __typename
                    }
                    videoUrl
                    __typename
                }
                ... on FeaturedProducts {
                    sys {
                    id
                    __typename
                    }
                    productsCollection(limit: 3) {
                    items {
                        __typename
                        ... on ElasticFeaturedProduct {
                        sys {
                            id
                            __typename
                        }
                        region
                        catalogId
                        productId
                        __typename
                        }
                        ... on FeaturedProduct {
                        sys {
                            id
                            __typename
                        }
                        name
                        number
                        description
                        image {
                            url(
                            transform: {width: 397, height: 470, quality: 90, format: JPG, resizeStrategy: FIT}
                            )
                            __typename
                        }
                        __typename
                        }
                    }
                    __typename
                    }
                    __typename
                }
                }
                __typename
            }
            teamMembersCollection(limit: 10) {
                items {
                sys {
                    id
                    __typename
                }
                photo {
                    url(
                    transform: {width: 74, height: 74, quality: 90, format: JPG, resizeStrategy: CROP, resizeFocus: FACE}
                    )
                    __typename
                }
                name
                title
                email
                aboutSection
                phoneNumber
                socialLinksCollection {
                    items {
                    sys {
                        id
                        __typename
                    }
                    type
                    uri
                    __typename
                    }
                    __typename
                }
                __typename
                }
                __typename
            }
            __typename
            }
            __typename
        }
    }
`
