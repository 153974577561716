import { CartItem, CartItemVariation, ClientFields, DropShipAddress, ElasticOrder, Order, Price } from 'models/CartTypes';
import React, { createContext, useState, useEffect, useLayoutEffect } from 'react';
import { cloneDeep } from 'lodash';
import constants from '../constants';
import { useSession } from './useSession';
import { getOrder, saveElasticOrder, updateElasticOrder, getProductImages, removeZeroedCartItems, modifyOrderWithAvailibility, getPrimaryWarehouse } from './order-service';
import { getStoredKey, setStoredKey, removeStoredKey } from './storage-service';
import { useToaster } from 'services/useToast';
import { AutoSaveNotification, SaveNotification, SubmitNotification } from 'components/header/notification/NotificationTypes';
import { getFullCatalog } from './catalog-service';
import { User } from 'models';
import { useConnection } from './useConnection';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import { getBrandConfig } from './auth-service';

export const CartContext = createContext<{
  cartItems: CartItem[],
  currentOrder: ElasticOrder | null,
  remainingCredits: number | null,
  updateRemainingCredits: (amount: number) => Promise<void>,
  dropShipAddress: DropShipAddress,
  changeDropShipAddress: (address: DropShipAddress) => Promise<void>,
  loadExistingOrder: (orderId: string) => Promise<boolean>,
  totalCartItems: number,
  showModifiedError: boolean,
  appPriceType: string,
  stockQuantityAlreadyInCart: (productNumber: string, variationCode: string, stockKey: string) => number,
  anyCurrentEmbellishments: (productNumber: string, variationCode: string) => any,
  addByProductIDAndCode: (id: string, variationCode: string, stock_key: string, quantity: number, embellishments: any) => Promise<void>,
  removeCartItem: (productNumber: string, colorCode: string) => Promise<void>,
  createOrder: (auto: boolean, submitOrder: boolean) => Promise<any>,
  clearOrder: () => Promise<void>
}>({
  cartItems: [],
  totalCartItems: 0,
  showModifiedError: false,
  appPriceType: "elastic_wholesale",
  remainingCredits: null,
  currentOrder: null,
  dropShipAddress: {
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "united_states",
    inUse: false
  },
  changeDropShipAddress: (address: DropShipAddress) => {
    throw new Error('Method not implemented');
  },
  updateRemainingCredits: (amount: number) => {
    throw new Error('Method not implemented');
  },
  loadExistingOrder: (orderId: string) => {
    throw new Error('Method not implemented');
  },
  stockQuantityAlreadyInCart: (productId: string, variationCode: string, stockKey: string) => {
    throw new Error('Method not implemented');
  },
  anyCurrentEmbellishments: (productId: string, variationCode: string) => {
    throw new Error('Method not implemented');
  },
  addByProductIDAndCode: async (id: string, variationCode: string, stock_key: string, quantity: number, embellishments: any) => {
    throw new Error('Method not implemented');
  },
  removeCartItem: async (productId: string, colorCode: string) => {
    throw new Error('Method not implemented');
  },
  createOrder: async (auto: boolean, submitOrder: boolean) => {
    throw new Error('Method not implemented');
  },
  clearOrder: async () => {
    throw new Error('Method not implemented');
  }
});

export const CartProvider: React.FC<any> = ({ children }) => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [remainingCredits, setRemainingCredits] = useState<number | null>(null);
  const [currentOrder, setCurrentOrder] = useState<ElasticOrder | null>(null);
  const [appPriceType, setAppPriceType] = useState<string>("elastic_wholesale");
  const [showModifiedError, setShowModifiedError] = useState<boolean>(false);
  const [dropShipAddress, setDropShipAddress] = useState<DropShipAddress>({
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "united_states",
    inUse: false
  });
  const [totalCartItems, setTotalCartItems] = useState<number>(0);

  const { getUserInfo } = useSession();
  const { addNotification } = useToaster();
  const { connected } = useConnection();
  const history = useHistory(); 
  const location = useLocation();

  useEffect(() => {
    async function getData(){
      let curentOrderId: string = await getStoredKey(constants.CURRENT_ORDER_ID);
      if(curentOrderId && curentOrderId != ""){
        await loadExistingOrder(curentOrderId);
      }
    }

    getData();

  }, []);

  // should only auto save if the cart is updated somehow, and will 
  // only do it once until it is updated again.
  // checks in 5 minutes after an update
  useEffect(() => {
    if(showModifiedError && cartItems.length > 0) {
      createOrder(true, false)
    }

    const timer = setTimeout(() => {
      console.log("checking if I should save")
      if(currentOrder) {
        createOrder(true, false);
      }
    }, 300000);

    console.log("cart items: ", cartItems);
    setTotalCartItems(calculateTotalCartItems());

    return () => clearTimeout(timer);
  }, [cartItems]);

  useEffect(() => {
    async function setPriceType (){
      const brandConfig: any = await getBrandConfig(constants.CONFIG_TYPE, true);

      if(brandConfig.brandDynamic.config.flags.initialPriceType) {
        let priceType = brandConfig.brandDynamic.config.flags.initialPriceType;
        if(priceType == "wholesale") {
          setStoredKey(constants.APP_PRICE_TYPE, "elastic_wholesale");  
          setAppPriceType("elastic_wholesale")
        }

        if(priceType == "retail") {
          setStoredKey(constants.APP_PRICE_TYPE, "elastic_retail");  
          setAppPriceType("elastic_retail")
        }

        if(priceType == "both") {
          setStoredKey(constants.APP_PRICE_TYPE, "both");  
          setAppPriceType("both")
        }

        if(priceType == "none") {
          setStoredKey(constants.APP_PRICE_TYPE, "none");  
          setAppPriceType("none")
        }
      } else {
        setStoredKey(constants.APP_PRICE_TYPE, "elastic_wholesale");
        setAppPriceType("elastic_wholesale")
      }      
    }

    setPriceType();
  }, []);

  useLayoutEffect(() => {
    if(history.location.pathname == "/cart" && totalCartItems > 0 && !currentOrder) {
      createOrder(false, false);
    }
    
  }, [location]);

  const changeDropShipAddress = async (address: DropShipAddress): Promise<void> => {
    setDropShipAddress(address)
  }

  const getUnitPricesForProduct = (page_items: CartItemVariation[]): CartItemVariation[] => {

    page_items.forEach((stock: CartItemVariation) => {
      if(stock.total_prices && stock.quantity > 0) {
        stock.prices = {
          elastic_retail: (parseFloat(stock.total_prices?.elastic_retail) / stock.quantity).toFixed(2),
          elastic_wholesale: (parseFloat(stock.total_prices?.elastic_wholesale) / stock.quantity).toFixed(2),
          rBASEUSD: (parseFloat(stock.total_prices?.rBASEUSD) / stock.quantity).toFixed(2),
          wBASEUSD: (parseFloat(stock.total_prices?.wBASEUSD) / stock.quantity).toFixed(2)
        }
      }
    });

    return page_items;
  }

  const checkModifications = async (order: ElasticOrder, itemsForCart: CartItem[]): Promise<void> => {
    let modifiedOrderItems = await modifyOrderWithAvailibility(order?.catalog_key, order?.customer, itemsForCart);
    setShowModifiedError(modifiedOrderItems.modified)
    if(modifiedOrderItems.modified) {
      itemsForCart = modifiedOrderItems.items;
    }

    if(itemsForCart.length > 0) {
      await setCurrentOrder(order);
      await setStoredKey(constants.CURRENT_ORDER_ID, order._id)
    } else {
      await setCurrentOrder(null);
      await removeStoredKey(constants.CURRENT_ORDER_ID)
    }

    await setCartItems(itemsForCart);
}

  const loadExistingOrder = async (orderId: string): Promise<boolean> => {

    try {
      let order: ElasticOrder | null = await getOrder(orderId, true)
      let user: User | undefined = await getUserInfo();
      console.log("retrieved order", order);
      if(order != null && user) {
        let catalogToLoad = "";
        if(order.catalog_key) {
          catalogToLoad = order.catalog_key;
        }

        if(order.pages){
          let itemsForCart: CartItem[] = []
          order.pages[0].page_products.forEach((product: CartItem) => {
            product.isCustomized = product.embellishments ? true : false;
            if(order!.products){
              let images = getProductImages(product, order!.products);
              let primary_warehouse = getPrimaryWarehouse(product, order!.products)
              product.primary_warehouse = primary_warehouse;
              if(images) {
                product.images = images;
              }
            }

            // populate these items price per unit (we only get back total pricings)
            product.page_items = getUnitPricesForProduct(product.page_items);

            itemsForCart.push(product)
          });
          await getFullCatalog(
            catalogToLoad,
            user.customer_number,
            "price",
            "",
            false,
            true,
            true,
            "",
            connected
          );

          await checkModifications(order, itemsForCart);
        }
      }
      return true;
    } catch {
      return false;
    }
  }

  const updateRemainingCredits = async (amount: number): Promise<void> => {
    setRemainingCredits(amount);
  }

  const clearOrder = async (): Promise<void> => {
    await setCurrentOrder(null);
    await setCartItems([]);
    await removeStoredKey(constants.CURRENT_ORDER_ID)
  }

  const removeCartItem = async (productNumber: string, colorCode: string): Promise<void> => {
    setShowModifiedError(false)
    
    let newCartItems: CartItem[] = [];
    cartItems.forEach((product: CartItem) => {
      if(!(product.product_number == productNumber && product.color_code == colorCode)) {
        newCartItems.push(product)
      }
    });
    
    await setCartItems(newCartItems);
    if(newCartItems.length == 0) {
      clearOrder();
    }
  };

  const doUpdate = (existingCartItems: CartItem[], cartItem: CartItem, stock_key: string) => {
    let tempCartItems = cloneDeep(existingCartItems);
    tempCartItems.forEach((item: CartItem) => {
      if(item.product_number == cartItem.product_number && item.color_code == cartItem.color_code){
        item.embellishments = cartItem.embellishments;
        item.page_items = mixStock(item.page_items, cartItem.page_items, stock_key);
        item.total_prices = generateItemTotalPrices(item.page_items)
      }
    });

    return removeZeroedCartItems(tempCartItems);
  }

  // combine the current stock quantites with the incoming stock quantities
  const mixStock = (originalStock: CartItemVariation[], newStock: CartItemVariation[], stock_key: string): CartItemVariation[] => {
    
    for (let stockIndex = 0; stockIndex < originalStock.length; stockIndex++) {
      const stock: CartItemVariation = originalStock[stockIndex];
      if(stock.stock_item_key == stock_key) {
        const newStockItem: CartItemVariation = newStock[stockIndex];
        originalStock[stockIndex].quantity = newStockItem.quantity;
        originalStock[stockIndex].prices = newStockItem.prices;

        if(originalStock[stockIndex].quantity < 0) {
          originalStock[stockIndex].quantity = 0;
        }
        if(stock.quantity_source && stock.quantity_source.length > 0 && newStockItem.quantity_source && newStockItem.quantity_source.length > 0) {
          let hasSameSource = false;
          for (let quantityIndex = 0; quantityIndex < stock.quantity_source.length; quantityIndex++) {
            const quantity_source = stock.quantity_source[quantityIndex];
            if(quantity_source.source == newStockItem.quantity_source[0].source){

              stock.quantity_source[quantityIndex].quantity = newStockItem.quantity_source[0].quantity;
              if(stock.quantity_source[quantityIndex].quantity < 0) {
                stock.quantity_source[quantityIndex].quantity = 0;
              }
              hasSameSource = true;
            }
          }

          if(!hasSameSource) {
            stock.quantity_source.push(newStockItem.quantity_source[0]);
          }
        } else if(newStockItem.quantity_source && newStockItem.quantity_source.length > 0) {
          originalStock[stockIndex].quantity_source = newStockItem.quantity_source;
        }
      }
    }

    return originalStock;
  }

  const calculateTotalCartItems = () => {
    let total = 0;
    cartItems.forEach((cartItem: CartItem) => {
      cartItem.page_items.forEach((stock: CartItemVariation) => {
        total += stock.quantity;
      });
    });

    return total;
  }

  const updateCreatedOrder = async (autoSave: boolean, submitOrder: boolean, orderCartItems: CartItem[]): Promise<any> => {
    let orderToUpdate = cloneDeep(currentOrder);

    if(orderToUpdate) {
      if(orderToUpdate.pages && orderToUpdate.pages.length > 0){
        orderToUpdate.pages[0].page_products = orderCartItems;
      }
      
      orderToUpdate.platform_updated = navigator.userAgent;

      if(dropShipAddress && dropShipAddress.inUse) {
        orderToUpdate.pages[0].drop_ship_address = dropShipAddress
        delete orderToUpdate.pages[0].drop_ship_address.inUse
      } else {
        orderToUpdate.pages[0].drop_ship_address = null
      }

      console.log("updating order")

      // if this is not a submission, just update the current order object
      // and use the right notifications
      if(!submitOrder) {
        let saveResult = await updateElasticOrder(orderToUpdate, connected)
        if(saveResult.updateOrderDynamic._id) {
          await setCurrentOrder(orderToUpdate);

          if(!autoSave) {
            await addNotification(SaveNotification);  
          } else {
            await addNotification(AutoSaveNotification);  
          }

          return saveResult.updateOrderDynamic.number;
        }
      // if this is a submission, just change that flag, then update the order with Elastic
      } else {
        orderToUpdate.do_submit = true;

        let submitResult = await updateElasticOrder(orderToUpdate, connected)
        if(submitResult.updateOrderDynamic._id) {
          await setCurrentOrder(null);
          await addNotification(SubmitNotification);
          clearOrder();

          return submitResult.updateOrderDynamic.number;
        }
      }
    }

    return false;
  }

  const createNewOrder = async (orderCartItems: CartItem[], userData: User): Promise<any> => {
    if(userData.catalogs) {

      let clientFields: ClientFields = {
        ship_via: ""
      }

      let order: Order = {
        arrive_on: moment().format("YYYY-MM-DD"),
        client_fields: clientFields,
        customer_number: userData.customer_number,
        location_number: userData.customer.locations[0].number,
        name: 'Mobile Order 1',
        page_products: orderCartItems,
        type: ''
      }

      if(dropShipAddress && dropShipAddress.inUse) {
        order.drop_ship_address = dropShipAddress
        delete order.drop_ship_address.inUse
      }

      let elasticOrder: ElasticOrder = {
        catalog_key: userData.catalogs[0].key,
        client_created: constants.CONFIG_TYPE,
        client_updated: constants.CONFIG_TYPE,
        customer: userData.customer_number,
        do_reject: false,
        do_review: false,
        do_submit: false,
        exclude_zero_quantity: false,
        name: 'Mobile Order',
        note: '',
        notes: '',
        pages: [order],
        platform_created: navigator.userAgent,
        platform_updated: navigator.userAgent,
        programs: [],
        version_created: '16e761a',
        version_updated: '16e761a'
      };

      let result = await saveElasticOrder(elasticOrder, connected)
      console.log("saved order", result)
      if(result.saveOrderDynamic.number && result.saveOrderDynamic._id) {
        elasticOrder._id = result.saveOrderDynamic._id;
        await setCurrentOrder(elasticOrder);
        await setStoredKey(constants.CURRENT_ORDER_ID, result.saveOrderDynamic._id)
        await addNotification(SaveNotification);
        return result.saveOrderDynamic.number;
      }
    }

    return false;
  }

  const createOrder = async (autoSave: boolean, submitOrder: boolean): Promise<any> => {
    let userData: any = await getUserInfo();

    // after cart items are put into this order, the get slightly modified for the API
    // but we do not want this object to change, so we need to clone it to prevent that.
    let orderCartItems = cloneDeep(cartItems);

    if(userData.customer && currentOrder && currentOrder._id && currentOrder._id != "") {
      // update existing draft order
      return updateCreatedOrder(autoSave, submitOrder, orderCartItems);

    } else if (userData.customer) {
      
      // create new order
      return createNewOrder(orderCartItems, userData);
    }
    
    return false;
  }

  async function addByProductIDAndCode(product: any, variation: any, stock: any, quantity: number, embellishments: any){
    setShowModifiedError(false)
    let stockItems = generateStockItems(variation, stock, quantity);
    let cartItem: CartItem = {
      _id: product._id,
      color_code: variation.code,
      color_name: variation.name,
      page_items: stockItems,
      position: 1,
      product_number: product.number,
      embellishments: embellishments,
      name: product.name,
      isCustomized: product.customizable,
      total_prices: generateItemTotalPrices(stockItems),
      images: variation.images,
      primary_warehouse: product.primary_warehouse
    }

    await setCartItems((items) => { 
      if(checkIfProductInCart(items, cartItem)){
        return doUpdate(items, cartItem, stock.key);
      } else {
        if(quantity != 0) {
          return ([...items, cartItem] ) ;
        } else {
          return items;
        }
      }
    });
  }

  function generateItemTotalPrices(stocks: any[]): Price{

    let price: Price = {
      elastic_retail: "0.00",
      elastic_wholesale: "0.00",
      rBASEUSD: "0.00",
      wBASEUSD: "0.00"
    }

    stocks.forEach((stock) => {
      if(stock.prices){
        price.elastic_retail = (parseFloat(price.elastic_retail) + (parseFloat(stock.prices.elastic_retail) * stock.quantity)).toString();
        price.elastic_wholesale = (parseFloat(price.elastic_wholesale) + (parseFloat(stock.prices.elastic_wholesale) * stock.quantity)).toString()
        price.rBASEUSD = (parseFloat(price.rBASEUSD) + (parseFloat(stock.prices.rBASEUSD) * stock.quantity)).toString()
        price.wBASEUSD = (parseFloat(price.wBASEUSD) + (parseFloat(stock.prices.wBASEUSD) * stock.quantity)).toString()
      } else if(stock.total_prices) {
        price.elastic_retail = (parseFloat(price.elastic_retail) + parseFloat(stock.total_prices.elastic_retail)).toString()
        price.elastic_wholesale = (parseFloat(price.elastic_wholesale) + parseFloat(stock.total_prices.elastic_wholesale)).toString()
        price.rBASEUSD = (parseFloat(price.rBASEUSD) + parseFloat(stock.total_prices.rBASEUSD)).toString()
        price.wBASEUSD = (parseFloat(price.wBASEUSD) + parseFloat(stock.total_prices.wBASEUSD)).toString()
      }
    });

    return price;
  }

  function checkIfProductInCart(items: CartItem[], cartItem: CartItem){
    let foundItem = false;
    items.forEach((item: CartItem) => {
      if(item.product_number == cartItem.product_number && item.color_code == cartItem.color_code){
        foundItem = true;
      }
    });

    return foundItem;
  }

  function generateStockItems(variation: any, stock: any, quantity: number){
    let stockItems: any[] = [];
    variation.stock_items.forEach((stockItem: any) => {
      if(stock.key == stockItem.key){
        stockItems.push({
          quantity: quantity,
          quantity_source: [{
            quantity: quantity,
            source: variation.primary_warehouse
          }],
          stock_item_key: stockItem.key,
          stock_item_sku: stockItem.sku,
          stock_item_upc: stockItem.upc,
          prices: stock.prices
        })
      } else {
        stockItems.push({
          quantity: 0,
          stock_item_key: stockItem.key,
          stock_item_sku: stockItem.sku,
          stock_item_upc: stockItem.upc,
          prices: stock.prices
        })
      }
    });

    return stockItems;
  }

  function stockQuantityAlreadyInCart(productNumber: string, variationCode: string, stockKey: string): number{
    let quantity = 0;
    cartItems.forEach((product) => {
      if(product.product_number == productNumber && product.color_code == variationCode) {
        product.page_items.forEach((stockItem) => {
          if(stockItem.stock_item_key == stockKey) {
            quantity = stockItem.quantity;
          }
        });
      }
    });

    return quantity;
  }

  function anyCurrentEmbellishments(productNumber: string, variationCode: string): number{
    let embillishments: any = null;
    cartItems.forEach((product) => {
      if(product.product_number == productNumber && product.color_code == variationCode) {
        embillishments = product.embellishments;
      }
    });

    return embillishments;
  }

  return (
    <CartContext.Provider
      value={{
        cartItems,
        totalCartItems,
        currentOrder,
        dropShipAddress,
        appPriceType,
        showModifiedError,
        remainingCredits,
        updateRemainingCredits,
        changeDropShipAddress,
        loadExistingOrder,
        stockQuantityAlreadyInCart,
        anyCurrentEmbellishments,
        addByProductIDAndCode,
        removeCartItem,
        createOrder,
        clearOrder
      }}
    >
      {children}
    </CartContext.Provider>
  );
};