import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonRow, useIonLoading } from '@ionic/react';
import EmptyList from 'components/empty-list/EmptyList'
import './CartDetails.scss';
import { cartOutline, chevronDownOutline, alertCircleOutline } from  'ionicons/icons';
import { useCart } from '../../services/useCart';
import { CartItem, CartItemVariation, DropShipAddress, DropShipFieldErrors, DropShipFieldMaxLengths, DropShipFieldRegExps, SalesProgram } from 'models';
import SummaryItem from 'components/summary-item/SummaryItem';
import { useEffect, useRef, useState } from 'react';
import { useSession } from 'services/useSession';
import { useHistory } from 'react-router';
import { validateAddress, getTotalOrderPrice, getSalesPrograms } from '../../services/order-service';
import { cloneDeep } from 'lodash';
import { getBrandConfig } from 'services/auth-service';
import constants from '../../constants';
import { useConnection } from '../../services/useConnection';
import Breadcrumbs from 'components/header/breadcrumbs/Breadcrumbs';
import RemoveProductModal from './remove-product-modal/RemoveProductModal';
import ShipToModal from './ship-to-modal/ShipToModal';
import DropShipModal from './drop-ship-modal/DropShipModal';
import { getTranslation } from 'translations';

const CartDetails: React.FC = () => {
  const { createOrder, totalCartItems, currentOrder, cartItems, changeDropShipAddress, dropShipAddress, updateRemainingCredits, showModifiedError } = useCart();
  const [availibleLocations, setAvailibleLocations] = useState<[]>([]);
  const [activeSalesProgram, setActiveSalesProgram] = useState<SalesProgram | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [selectedLocationName, setSelectedLocationName] = useState<string>("");
  const [selectedLocationDisplay, setSelectedLocationDisplay] = useState<any>(null);
  const [totalPrice, setTotalPrice] = useState<number>(0.00);  
  const [maxOrderAmount, setMaxOrderAmount] = useState<number>(0.00);
  const [minOrderAmount, setMinOrderAmount] = useState<number>(0.00);  
  const [totalDiscount, setTotalDiscount] = useState<number>(0.00); 
  const [totalAmountDue, setTotalAmountDue] = useState<number>(0.00);  
  const [productToDelete, setProductToDelete] = useState<any>(null);
  const [shippingAddressOpen, setShippingAddressOpen] = useState<boolean>(false);
  const [dropShipOpen, setDropShipOpen] = useState<boolean>(false);
  const [dropShipOnly, setDropShipOnly] = useState<boolean>(false);
  const [dropShipAddressError, setDropShipAddressError] = useState<boolean>(false);
  const [dropshipAllowed, setDropShipAllowed] = useState<boolean>(false);
  const [dropShipEmailField, setDropShipEmailField] = useState<boolean>(false);
  const [dropShipPhoneField, setDropShipPhoneField] = useState<boolean>(false);
  const [orderSaving, setOrderSaving] = useState<boolean>(false);
  const [dropShipFieldMaxLengths, setDropShipFieldMaxLengths] = useState<DropShipFieldMaxLengths>({
    address1MaxLength: 1000,
    address2MaxLength: 1000,
    cityMaxLength: 1000,
    nameMaxLength: 1000,
    stateMaxLength: 1000,
    zipMaxLength: 1000,
    emailMaxLength: 1000,
    phoneMaxLength: 1000
  });

  const [dropShipFieldRegExps, setDropShipFieldRegExps] = useState<DropShipFieldRegExps>({
    nameRegExp: undefined,
    address1RegExp: undefined,
    address2RegExp: undefined,
    cityRegExp: undefined,
    stateRegExp: undefined,
    zipRegExp: undefined,
    phoneRegExp: undefined,
    emailRegExp: undefined
  });

  const [dropShipFieldErrors, setDropShipFieldErrors] = useState<DropShipFieldErrors>({
    address1: false,
    address2: false,
    city: false,
    name: false,
    state: false,
    zip: false,
    email: false,
    phone: false
  });

  const [newDropShipAddress, setNewDropShipAddress] = useState<DropShipAddress>(dropShipAddress);

  const { getUserInfo, userCatalogId, userLanguageCode } = useSession();
  const [present, dismiss] = useIonLoading();
  const history = useHistory();
  const { connected } = useConnection();
  
  const removeModal = useRef<HTMLIonModalElement>(null);
  const addressModal = useRef<HTMLIonModalElement>(null);
  const dropShipModal = useRef<HTMLIonModalElement>(null);
  const dropShipError = useRef<HTMLParagraphElement>(null);

  useEffect(() => {

    async function getData() {
      
      const brandConfig: any = await getBrandConfig(constants.CONFIG_TYPE, connected);

      const result: any = await getUserInfo(true);
      if(result.customer && result.customer.locations) {
        await setAvailibleLocations(result.customer.locations);
      }

      if(result.catalogs && result.catalogs[0]) {
        setMinMaxOrderAmount(result.catalogs[0], result.currency_code ?? "");
      }

      if(result.customer && result.customer.client_fields && result.customer.client_fields.drop_ship) {
        if(result.customer.client_fields.drop_ship == "Y" || result.customer.client_fields.drop_ship == "M") {
          setDropShipAllowed(true);
        }

        if(brandConfig.brandDynamic && brandConfig.brandDynamic.config && brandConfig.brandDynamic.config.flags 
          && brandConfig.brandDynamic.config.flags.dropShip) {
            if(brandConfig.brandDynamic.config.flags.dropShip.alwaysEnabled) {
              setDropShipAllowed(true);
            }

            if(brandConfig.brandDynamic.config.flags.dropShip.additionalFields) {
              brandConfig.brandDynamic.config.flags.dropShip.additionalFields.forEach((field: any) => {
                if(field.name == "email") {
                  setDropShipEmailField(true)
                }

                if(field.name == "phone") {
                  setDropShipPhoneField(true)
                }
              });
            }

            if(brandConfig.brandDynamic.config.flags.dropShip.fieldMaxLengths) {
              let newMaxLengths: DropShipFieldMaxLengths = brandConfig.brandDynamic.config.flags.dropShip.fieldMaxLengths;
              await setDropShipFieldMaxLengths(newMaxLengths)
            }

            if(brandConfig.brandDynamic.config.flags.dropShip.fieldRegExps) {
              let newRegExps: DropShipFieldRegExps = brandConfig.brandDynamic.config.flags.dropShip.fieldRegExps;
              await setDropShipFieldRegExps(newRegExps)
            }
        }
      }

      if(dropShipAddress && dropShipAddress.inUse || (result.customer.client_fields.drop_ship == "M")) {
        setSelectedLocation("drop_ship")
        setSelectedLocationDisplay(null);
        setDropShipOnly(true)
      } else {
        if(result.customer && result.customer.locations) {
          await setSelectedLocation(result.customer.locations[0].number)
        }
      }
    }

    getData();
  }, []);

  useEffect(() => {

    async function getLocationDisplayText() {
      if(newDropShipAddress && newDropShipAddress.inUse) {
        setSelectedLocationDisplay(newDropShipAddress)
        setSelectedLocationName(newDropShipAddress.name)
      } else {
        const result: any = await getUserInfo();
        if(result.customer && result.customer.locations) {
          result.customer.locations.forEach((location: any) => {
            if(location.number == selectedLocation){
              setSelectedLocationDisplay(location.address)
              setSelectedLocationName(location.name)
            }
          });
        }
      }
    }

    getLocationDisplayText();

  }, [selectedLocation]);

  useEffect(() => {
    setPrices();

    // if this order already has a drop ship address set, set it in the view
    if(currentOrder) {
      if(currentOrder.pages[0]) {
        if(currentOrder.pages[0].drop_ship_address && currentOrder.pages[0].drop_ship_address.address1) {
          let existingDropShipAddress = cloneDeep(currentOrder.pages[0].drop_ship_address);
          existingDropShipAddress.inUse = true;
          setNewDropShipAddress(existingDropShipAddress)
        }
      }
    }

  }, [cartItems, activeSalesProgram]);

  useEffect(() => {

    changeDropShipAddress(newDropShipAddress);

    if(newDropShipAddress.inUse) {
      setSelectedLocation("drop_ship")
      setSelectedLocationDisplay(newDropShipAddress);
      setSelectedLocationName(newDropShipAddress.name);
    }
  }, [newDropShipAddress]);

  useEffect(() => {

    if(!activeSalesProgram) {
      loadSalesPrograms();
    }
  }, [currentOrder]);

  async function loadSalesPrograms() {
    if(currentOrder && currentOrder._id) {
      let salesPrograms = await getSalesPrograms(currentOrder._id, connected)
      if(salesPrograms.salesProgramsDynamic && salesPrograms.salesProgramsDynamic.programs && salesPrograms.salesProgramsDynamic.programs[0]) {
        await setActiveSalesProgram(salesPrograms.salesProgramsDynamic.programs[0]);
      }
    }
  }

  function setMinMaxOrderAmount (catalog: any, currency: string) {
    let minValue = catalog.min_order_amount ?? 0;
    let maxValue = catalog.max_order_amount ?? 1000000000;
    let currencySpecificVMaxs = catalog.max_order_amount_for_currency ? JSON.parse(catalog.max_order_amount_for_currency) : {};
    let currencySpecificVMins = catalog.min_order_amount_for_currency ? JSON.parse(catalog.min_order_amount_for_currency) : {};
    if(currencySpecificVMaxs[currency]) {
      setMaxOrderAmount(currencySpecificVMaxs[currency])
    } else {
      setMaxOrderAmount(maxValue)
    }

    if(currencySpecificVMins[currency]) {
      setMinOrderAmount(currencySpecificVMins[currency])
    } else {
      setMinOrderAmount(minValue)
    }
  }

  async function saveCurrentOrder() {
    if(selectedLocationDisplay != null) {
      setOrderSaving(true)
      if(cartItems.length > 0) {
        await present({ message: "Saving order..." });
      }
      console.log("saving cart items", cartItems)
      let result = await createOrder(false, false);

      if(!result) {
        alert("Something went wrong, please try again.")
        return ""
      }
      await dismiss();

      setOrderSaving(false)

      history.replace('/explore');

      return result;
    } else {
      alert("Please set a drop shipment address.")
    }
  }

  async function setPrices() {
    let theTotalPrice = await getTotalOrderPrice(cartItems);
    setTotalPrice(theTotalPrice);
    if(activeSalesProgram && activeSalesProgram.rules_details[0]) {
      if(activeSalesProgram.rules_details[0].type == "Rule::TotalPriceAtLeast") {
        let discountAllowed = parseFloat(activeSalesProgram.rules_details[0].target)
        let totalDiscountRemaining = (theTotalPrice - discountAllowed) * -1;
        updateRemainingCredits(totalDiscountRemaining);
        let totalAmountDiscounted = 0;
        if(totalDiscountRemaining > 0) {
          totalAmountDiscounted = theTotalPrice;
          setTotalAmountDue(0.00);
        } else {
          totalAmountDiscounted = discountAllowed;
          setTotalAmountDue((theTotalPrice - discountAllowed));
        }
        setTotalDiscount(totalAmountDiscounted);
      } else {
        setTotalAmountDue(theTotalPrice);
      }
    } else {
      setTotalAmountDue(theTotalPrice);
    }
  }

  function changeAddress(locationNumber: string) {

    setNewDropShipAddress((oldAddress) => ({
    ...oldAddress,
    inUse: false})); 

    if(locationNumber != "drop_ship") {
      setSelectedLocation(locationNumber)
    } else {
      setDropShipOpen(true)
    }
  }

  async function doOrderSubmit(){
    if(selectedLocationDisplay != null) {
      setOrderSaving(true)
      let orderNum = await createOrder(false, true) 
      if(orderNum){
        history.push("/submitted/" + orderNum)
      }

      setOrderSaving(false)
    } else {
      alert("Please set a drop shipment address.")
    }
  }

  function allFDropShipieldsValid(): boolean {
    let valid = true;

    if(dropShipFieldRegExps.nameRegExp) {
      let regex = new RegExp(dropShipFieldRegExps.nameRegExp);
      let test = !regex.test(newDropShipAddress.name)
      if(test){
        valid = false;
      }
      setDropShipFieldErrors((oldErrors) => ({
        ...oldErrors,
        name: test})); 
    }

    if(dropShipFieldRegExps.address1RegExp) {
      let regex = new RegExp(dropShipFieldRegExps.address1RegExp);
      let test = !regex.test(newDropShipAddress.address1)
      if(test){
        valid = false;        
      }
      setDropShipFieldErrors((oldErrors) => ({
        ...oldErrors,
        address1: test})); 
    }

    if(dropShipFieldRegExps.address2RegExp) {
      let regex = new RegExp(dropShipFieldRegExps.address2RegExp);
      let test = !regex.test(newDropShipAddress.address2)
      if(test){
        valid = false;
      }
      setDropShipFieldErrors((oldErrors) => ({
        ...oldErrors,
        address2: test})); 
    }

    if(dropShipFieldRegExps.zipRegExp) {
      let regex = new RegExp(dropShipFieldRegExps.zipRegExp);
      let test = !regex.test(newDropShipAddress.zip)
      if(test){
        valid = false;
      }
      setDropShipFieldErrors((oldErrors) => ({
        ...oldErrors,
        zip: test})); 
    }

    if(dropShipFieldRegExps.cityRegExp) {
      let regex = new RegExp(dropShipFieldRegExps.cityRegExp);
      let test = !regex.test(newDropShipAddress.city)
      if(test){
        valid = false;
      }
      setDropShipFieldErrors((oldErrors) => ({
        ...oldErrors,
        city: test})); 
    }

    if(dropShipFieldRegExps.stateRegExp) {
      let regex = new RegExp(dropShipFieldRegExps.stateRegExp);
      let test = !regex.test(newDropShipAddress.name)
      if(test){
        valid = false;
      }
      setDropShipFieldErrors((oldErrors) => ({
        ...oldErrors,
        state: test})); 
    }

    return valid;
  }

  async function doChangeDropShipAddress(){
    let validAddress = await validateAddress(newDropShipAddress);

    if(allFDropShipieldsValid() && validAddress) {
      setDropShipAddressError(false)
      dropShipModal.current?.dismiss();
      setNewDropShipAddress((oldAddress) => ({
        ...oldAddress,
        inUse: true})); 
    } else {
      setDropShipAddressError(true)
      dropShipModal.current?.scrollIntoView({ block:'nearest' });
    }
  }

  return (
    <>
      {cartItems.length === 0 && 
        <IonContent>
          {showModifiedError && 
            <div className='modified-error empty'>
              <IonIcon class="icon" icon={alertCircleOutline} color="danger" />
              <p>{getTranslation('cartItemsModified', userLanguageCode)}</p>
            </div>
          }
          <EmptyList 
            icon={cartOutline} 
            title="Empty Cart" 
            firstLineSubext={'No item in your cart.'} 
            secondLineSubtext='Items added to cart will appear here.' 
            buttonText='Start Shopping' 
            buttonLink={"/explore/catalogs?catalogId=" + userCatalogId}
          />
        </IonContent>
      }
        {cartItems.length > 0 && 
          <IonContent className={activeSalesProgram ? "has-bar" : ""}>
            <div className='wrapper'>
              <Breadcrumbs />
              <h1>{getTranslation('shoppingCart', userLanguageCode)}</h1>
              {availibleLocations.length > 0 && 
                <>
                  <div className='custom-select'>
                    <IonLabel>{getTranslation('shipTo', userLanguageCode)}</IonLabel>
                    <div onClick={() => { setShippingAddressOpen(true) }} className='fake-select'>
                      {selectedLocation == "drop_ship" ? <> {getTranslation('dropShip', userLanguageCode)} </> : <> {getTranslation('pickUp', userLanguageCode)} {selectedLocationName} </> } 
                      <IonIcon class="icon" icon={chevronDownOutline} color="primary" />
                    </div>
                  </div>
                  {selectedLocationDisplay && 
                    <div className='address-seperator'>
                      <div className='selected-address'>
                        <h3>{getTranslation('shipmentAddress', userLanguageCode)}</h3>
                        <p>
                          {selectedLocationDisplay.name && <>{selectedLocationDisplay.name} <br /></> }
                          {selectedLocationDisplay.address1 ? selectedLocationDisplay.address1 : selectedLocationDisplay.street1 } <br />
                          {(selectedLocationDisplay.street2 || selectedLocationDisplay.address2) && <>{selectedLocationDisplay.address2 ? selectedLocationDisplay.address2 : selectedLocationDisplay.street2 } <br /></> }
                          {selectedLocationDisplay.city}, {selectedLocationDisplay.state} {selectedLocationDisplay.zip}
                        </p>
                      </div>
                    </div>
                  }            
                </>
              }
              {showModifiedError && 
                <div className='modified-error'>
                  <IonIcon class="icon" icon={alertCircleOutline} color="danger" />
                  <p>{getTranslation('cartItemsModified', userLanguageCode)}</p>
                </div>
              }
              {totalPrice > maxOrderAmount && 
                <div className='modified-error'>
                  <IonIcon class="icon" icon={alertCircleOutline} color="danger" />
                  <p>{getTranslation('cartOrderAmountTooLarge', userLanguageCode).replace("{amount}", maxOrderAmount)}</p>
                </div>
              }
              {totalPrice < minOrderAmount && 
                <div className='modified-error'>
                  <IonIcon class="icon" icon={alertCircleOutline} color="danger" />
                  <p>{getTranslation('cartOrderAmountTooSmall', userLanguageCode).replace("{amount}", minOrderAmount)}</p>
                </div>
              }
              <h2>{getTranslation('cartSummary', userLanguageCode)}</h2>
              <p className='total-items'>{totalCartItems} {getTranslation('items', userLanguageCode)}</p>
              
              {cartItems.map((product: CartItem, index: number) => {
                let totalQuantity = 0;
                product.page_items.forEach((variation: CartItemVariation) => {
                  totalQuantity += variation.quantity;
                });

                return(
                  <SummaryItem key={index} product={product} totalQuantity={totalQuantity} isEditable={true} setProductToDelete={setProductToDelete} />
                );
              })}

              <div className='save-for-later'>
                <IonButton disabled={orderSaving} color={"secondary"} onClick={async () => { await saveCurrentOrder(); }}>{getTranslation('saveForLater', userLanguageCode)}</IonButton>
              </div>
            </div>
            <div className='cart-cost-details'>
              <h2>{getTranslation('priceDetails', userLanguageCode)} ({totalCartItems} {getTranslation('itemsUpper', userLanguageCode)})</h2>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <p>{getTranslation('totalPrice', userLanguageCode)}</p>
                  </IonCol>
                  <IonCol>
                    <p>${totalPrice.toFixed(2)}</p>
                  </IonCol>
                </IonRow>
                {totalDiscount != null && 
                  <IonRow class='with-border'>
                    <IonCol>
                      <p>{getTranslation('discount', userLanguageCode)}</p>
                    </IonCol>
                    <IonCol>
                      <p>-${totalDiscount.toFixed(2)}</p>
                    </IonCol>
                  </IonRow>
                }
                {totalAmountDue != null && 
                  <IonRow class='amount-due'>
                    <IonCol>
                      <p>{getTranslation('totalAmountDue', userLanguageCode)}</p>
                    </IonCol>
                    <IonCol>
                      <p>${totalAmountDue.toFixed(2)}</p>
                    </IonCol>
                  </IonRow>
                }
              </IonGrid>
            </div>
            {currentOrder && 
              <div className='place-order'>
                <IonButton disabled={orderSaving || ((totalPrice > maxOrderAmount) || (totalPrice < minOrderAmount))} color={"secondary"} onClick={async () => doOrderSubmit() }>{getTranslation('placeOrder', userLanguageCode)}</IonButton>
              </div>
            }
            <RemoveProductModal modalRef={removeModal} productToDelete={productToDelete} setProductToDelete={setProductToDelete} />
            <ShipToModal dropShipOnly={dropShipOnly} modalRef={addressModal} setShippingAddressOpen={setShippingAddressOpen} shippingAddressOpen={shippingAddressOpen} 
                         availibleLocations={availibleLocations} changeAddress={changeAddress} selectedLocation={selectedLocation} dropshipAllowed={dropshipAllowed} />
            <DropShipModal modalRef={dropShipModal} dropShipOpen={dropShipOpen} setDropShipOpen={setDropShipOpen} dropShipErrorRef={dropShipError} 
                           dropShipAddressError={dropShipAddressError} dropShipFieldErrors={dropShipFieldErrors} dropShipFieldRegExps={dropShipFieldRegExps} 
                           dropShipEmailField={dropShipEmailField} dropShipPhoneField={dropShipPhoneField} dropShipFieldMaxLengths={dropShipFieldMaxLengths} 
                           dropshipAllowed={dropshipAllowed} doChangeDropShipAddress={doChangeDropShipAddress} newDropShipAddress={newDropShipAddress} setNewDropShipAddress={setNewDropShipAddress} />
          </IonContent>
        }
    </>
  );
};

export default CartDetails;
