import { PopUpNotification } from 'models';
import React, { createContext, useState } from 'react';

export const ToastContext = createContext<{
  notifications: PopUpNotification[],
  addNotification: (notification: PopUpNotification) => Promise<void>,
  popNotification: () => Promise<void>;
}>({
  notifications: [],
  addNotification: async (notification: PopUpNotification) => {
    throw new Error('Method not implemented');
  },
  popNotification: async () => {
    throw new Error('Method not implemented');
  }
});

export const ToastProvider: React.FC<any> = ({ children }) => {
  const [notifications, setNotifications] = useState<PopUpNotification[]>([]);

  const addNotification = async (notification: PopUpNotification): Promise<void> => {
    await setNotifications([...notifications, ...[notification]]);
  };

  const popNotification = async (): Promise<void> => {
    let newNotifications = notifications;
    newNotifications.shift();
    await setNotifications(newNotifications);
  };

  return (
    <ToastContext.Provider
      value={{
        notifications,
        addNotification,
        popNotification
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};