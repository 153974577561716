import {
  IonicSlides,
  IonTitle,
  IonButton,
  IonIcon,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonRouterLink,
} from "@ionic/react";
import {
  chevronBackOutline,
  chevronForwardOutline
} from "ionicons/icons";
import { useState } from "react";
import CachedImage from "components/cached-image/CachedImage";
import "./ExploreSlider.scss";
import ExploreSliderTimer from "./explore-slider-timer/ExploreSliderTimer"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperInterface, Autoplay } from 'swiper';
import { generateTagQueryParam } from "services/catalog-service";

interface ExploreSliderProps {
  slides: any[];
}

const ExploreSlider: React.FC<ExploreSliderProps> = (props) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();
  const [slideIndex, setSlideIndex] = useState(0);
  const timeBetweenSlides = 5000;

  const moveToNewSlide = async (direction: string) => {
    if (swiperInstance) {
      if (direction === "next") {
        swiperInstance.slideNext();
      } else if (direction === "prev") {
        swiperInstance.slidePrev();
      }
    }
  };

  const onSlideChange = async (event: any) => {
    setSlideIndex(event.realIndex);
  };

  function generatePreSearch(product_numbers: string[]){
    let queryString = "";
    if(product_numbers) {
      for (let index = 0; index < product_numbers.length; index++) {

        if(index == 0) {
          queryString += "&query="
        } else {
          queryString += ","
        }
        
        queryString += product_numbers[index]
      }
    }

    return queryString;
  }

  let removedSlides = 0;

  return (
    <div className={"explore-slider " + (props.slides.length == 1 ? "one-slide" : "")}>
      {props.slides.length > 0 && (
        <Swiper onSwiper={(swiper) => setSwiperInstance(swiper)} 
          modules={[IonicSlides, Autoplay]} 
          pagination={false} 
          autoplay={{
            delay: timeBetweenSlides,
            disableOnInteraction: false
          }}
          speed={400}
          slidesPerView={1}
          onSlideChange={(event: any) => onSlideChange(event)}
        >
          {props.slides.map((slide: any, index: number) => {
            if (!slide.video || slide.video == "") {
              let imageLink = ""
              let mainCTALink = "";
              let mainCTAText = "";
              let secondaryCTALink = "";
              let secondaryCTAText = "";

              // image link
              if(slide.product_filter_set && slide.product_filter_set.catalog_key) {
                imageLink = "/explore/catalogs?catalogId=" + slide.product_filter_set.catalog_key
              } else if(slide.external_url) {
                imageLink = slide.external_url;
              }

              // main CTA 
              if(slide.call_to_actions && slide.call_to_actions[0] && slide.call_to_actions[0].on_click_full){
                mainCTAText = slide.call_to_actions[0].display_text
                mainCTALink = "/explore/catalogs?catalogId=" + slide.call_to_actions[0].on_click_full.catalog_key + generateTagQueryParam(slide.call_to_actions[0].on_click_full.tags) + generatePreSearch(slide.call_to_actions[0].on_click_full.product_numbers);
              }

              // secondary CTA 
              if(slide.call_to_actions && slide.call_to_actions[1] && slide.call_to_actions[1].on_click_full){
                secondaryCTAText = slide.call_to_actions[1].display_text
                secondaryCTALink = "/explore/catalogs?catalogId=" + slide.call_to_actions[1].on_click_full.catalog_key + generateTagQueryParam(slide.call_to_actions[1].on_click_full.tags) + generatePreSearch(slide.call_to_actions[1].on_click_full.product_numbers);
              }

              return (
                <SwiperSlide key={index}>
                  <IonCard>
                    <IonCardHeader>
                    {imageLink == "" && <CachedImage noblankImage={false} src={slide.image} />}
                    {imageLink != "" && 
                      <IonRouterLink target={slide.external_url ? "_blank" : "_self"} href={imageLink}><CachedImage noblankImage={false} src={slide.image} /></IonRouterLink>
                    }
                    </IonCardHeader>
                    <IonCardContent>
                      {imageLink == "" && 
                        <IonTitle size={"large"} color={"secondary"}>
                          <div className="ion-text-wrap">{slide.headline}</div>
                        </IonTitle>
                      }
                      {imageLink != "" && 
                        <IonRouterLink target={slide.external_url ? "_blank" : "_self"} href={imageLink}>
                          <IonTitle size={"large"} color={"secondary"}>
                            <div className="ion-text-wrap">{slide.headline}</div>
                          </IonTitle>
                        </IonRouterLink>
                      }
                      <IonText color={"secondary"}>
                        {slide.sub_headline}
                      </IonText>
                      <div className="cta-container">
                      {mainCTALink != "" && 
                        <IonButton class="shop-button"
                          routerLink={mainCTALink}
                          color={"secondary"}
                        >
                          {mainCTAText}
                        </IonButton>
                      }
                      {secondaryCTALink != "" && (
                        <div className="text-link">
                        <IonRouterLink
                          color={"secondary"}
                          routerLink={secondaryCTALink}
                        >
                         {secondaryCTAText}
                        </IonRouterLink>
                        </div>
                      )}
                      </div>
                    </IonCardContent>
                  </IonCard>
                </SwiperSlide>
              );
            } else {
              removedSlides++;
            }
          })}
        </Swiper>
      )}
      {props.slides.length > 1 && 
        <div className="controls">
          <IonButton fill="clear" onClick={() => moveToNewSlide("prev")}>
            <IonIcon
              icon={chevronBackOutline}
              color="secondary"
            />
          </IonButton>
          <div className="slider-timer">
            <IonText color={"secondary"}>{slideIndex+1}</IonText>
            {/* for some reason the slidertimer seems to be quicker than it should be, so have to offset the styled timer */}
            <ExploreSliderTimer timeBetweenSlides={timeBetweenSlides} slideIndex={slideIndex} />
            <IonText color={"secondary"}>
              {props.slides.length - removedSlides}
            </IonText>
          </div>
          <IonButton className="next-button" fill="clear" onClick={() => moveToNewSlide("next")}>
            <IonIcon
              icon={chevronForwardOutline} 
              color="secondary"
            />
          </IonButton>
        </div>
      }
    </div>
  );
};

export default ExploreSlider;
