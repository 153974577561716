import { IonButton, IonIcon, IonicSlides, IonThumbnail } from '@ionic/react';
import { findItemImage } from "services/catalog-service";
import { chevronBack, chevronForward } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import CachedImage from 'components/cached-image/CachedImage';
import { CartItem, VariationImage } from 'models';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperInterface } from 'swiper';

interface ProductSlidesProps {
  product: any;
  innerRef: any;
  currentIndex: number;
  selectedColor: string;
}

const ProductSlides: React.FC<ProductSlidesProps> = (props) => {
  const [productImages, setProductImages] = useState<VariationImage[]>([]);
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();
  const [currentIndex, setCurrentIndex] = useState<number>(props.currentIndex);
  const [selectedColor, setSelectedColor] = useState<any>(props.selectedColor);
  const [height, setHeight] = useState<number | string>('auto');

  useEffect(() => {
    async function updateSlider() {
      await setProductImages(getAllProductImages(props.product.variations[currentIndex]))
    }

    updateSlider();
    // eslint-disable-next-line
  }, [selectedColor]);

  const imageLoaded = (e: any) => {

    //
    // This will find the tallest image and use it as the Swiper height 
    // (differing heights are usually janky on sliders)
    //

    setHeight(e.srcElement.offsetHeight + 60);
  }

  const moveToNewSlide = async (direction: string) => {
    if (swiperInstance) {
      if (direction === "next") {
        swiperInstance.slideNext();
      } else if (direction === "prev") {
        swiperInstance.slidePrev();
      }
    }
  };

  const changeColor = async (index: number) => {
    getSelectedColor(index);
    setCurrentIndex(index);
  };

  function getSelectedColor(index: number): string {
    if (props.product.variations[index]) {
      setSelectedColor(props.product.variations[index]);
    }
    return selectedColor;
  }

  function getAllProductImages(variation: CartItem) {
    let images: VariationImage[] = [];
    if (variation.images) {
      if (variation.images.P) {
        images = images.concat(variation.images.P);
      }

      if (variation.images.S) {
        images = images.concat(variation.images.S);
      }

      if (variation.images.A) {
        images = images.concat(variation.images.A);
      }
    }

    if(images.length > 0) {
      return images;
    } else {
      return [{
        detail: "assets/missing-image.svg",
        icon: "assets/missing-image.svg",
        large: "assets/missing-image.svg",
        original: "assets/missing-image.svg",
        print: "assets/missing-image.svg"
      }]
    }
  }

  useEffect(() => {
    if (swiperInstance && productImages.length > 1)
      swiperInstance.slideToLoop(0, 0)
  }, [productImages, swiperInstance]);

  useEffect(() => {
    getSelectedColor(0);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {

    //
    // Window Resize-friendly wrapper for imageLoaded
    //

    const handleResize = () =>
      setHeight('auto');

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  });

  useEffect(() => {
    setSelectedColor(props.selectedColor);
    setCurrentIndex(props.currentIndex);
  }, [props.selectedColor, props.currentIndex])

  return (
    <div className='product-image-slider' style={{ height }}>
      <Swiper onSwiper={setSwiperInstance} modules={[IonicSlides]} pagination={false} slidesPerView={1} loop={productImages.length > 1}>
        {productImages.map((image: any, index: number) =>
          <SwiperSlide key={`img${index}`}>
            <div className="image-container" style={{ "--height" : height } as any}>
              <CachedImage key={image.large} src={image.large} onLoad={imageLoaded} />
            </div>
          </SwiperSlide>)}
      </Swiper>
      <div className="display-product-colors">
        <div className="thumbnail-items thumbnail-wrap">
          <div className="thumbnail-padding"></div>
          {(props.product.variations && props.product.variations.length >1) &&
            props.product.variations.map((variation: any, index: any) => {
              const borderColor = currentIndex === index ? '#000' : '#bebebe';
              return (
                <IonButton fill="clear" class="image-btn" key={index} onClick={() => changeColor(index)}>
                  <IonThumbnail
                    key={index}
                    class="product-thumbnail"
                    style={{ borderColor }}
                  >
                    <div className="img-thumb">
                      <CachedImage className='product-image' src={findItemImage(variation, "icon")} />
                    </div>
                  </IonThumbnail>
                </IonButton>
              );
            })}
            <div className="thumbnail-padding"></div>
        </div>
        {selectedColor &&
          <>
            <div className="color-selection">
              {selectedColor.name !== "." ? selectedColor.name : ""}
              <div className="color-code">{selectedColor.code}</div>
            </div>

          </>}
      </div>
      {(productImages && productImages.length > 1) && (
        <>
          <IonButton
            expand="block"
            class="slide-button prev-button"
            fill="solid"
            color="dark"
            shape="round"
            onClick={() => moveToNewSlide("prev")}
          >
            <IonIcon class="icon" icon={chevronBack} color="secondary" />
          </IonButton>
          <IonButton
            expand="block"
            class="slide-button next-button"
            fill="solid"
            color="dark"
            shape="round"
            onClick={() => moveToNewSlide("next")}
          >
            <IonIcon class="icon" icon={chevronForward} color="secondary" />
          </IonButton>
        </>
      )}
    </div>
  );
};

export default ProductSlides;
