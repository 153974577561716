import {
  IonContent,
  IonPage
} from "@ionic/react";
import ProductDetailDisplay from "components/product-detail/ProductDetailDisplay";

const ProductDetail: React.FC = (props) => {

  const handleFocus = () =>
    document.querySelector('html')?.classList.add('pwa-focused')

  const handleBlur = () =>
    document.querySelector('html')?.classList.remove('pwa-focused')

  return (
    <IonPage className="page-with-menu product-page">
      <IonContent onFocus={handleFocus} onBlur={handleBlur}>

        <ProductDetailDisplay />

      </IonContent>
    </IonPage>
  );
};

export default ProductDetail;
