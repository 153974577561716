import { IonRouterLink } from "@ionic/react";
import "./RecommendedSearch.scss";
import { useEffect, useState } from "react";
import { useSession } from "services/useSession";
import { User } from "models/User";
import { generateTagQueryParam } from "services/catalog-service";

const RecommendedSearch: React.FC = () => {
  const [searches, setSearches] = useState([]);
  
  const { getUserInfo } = useSession();
  
  useEffect(() => {
    async function getData() {

        const result: User | undefined = await getUserInfo(true);

        console.log("user data", result)

        if(result && result.search_recommendations) {
          setSearches(result.search_recommendations);
        }
    }

    getData();
  }, []);

  function generatePreSearch(product_numbers: string[]){
    let queryString = "";
    if(product_numbers) {
      for (let index = 0; index < product_numbers.length; index++) {

        if(index == 0) {
          queryString += "&query="
        } else {
          queryString += ","
        }
        
        queryString += product_numbers[index]
      }
    }

    return queryString;
  }

  return (
    <div className="recommended-search">
      {searches.length > 0 && 
        <>
          <h2>Recommended Searches</h2>
          <ul>
            {searches.map((search: any, index: number) => {

              let link = "/explore/catalogs?catalogId=" + search.catalog_key + generateTagQueryParam(search.tags) + generatePreSearch(search.product_numbers);

              return (
                <li key={index}>
                  <IonRouterLink routerLink={link}>
                    {search.display_name}
                  </IonRouterLink>
                </li>
              )
            })}

          </ul>
        </>
      }
    </div>
  );
};

export default RecommendedSearch;
