const constants = {
    EXPLORE_SLIDES: "EXPLORE_SLIDES",
    CONFIG_TYPE: "scramble",
    CACHED_IMAGE: "IMAGE_STORE_",
    BRAND: "BRAND_",
    USER_LANGUAGE_CODE: "USER_LANGUAGE_CODE",
    CLIENT: "CLIENT_",
    CLIENT_NAME: "CLIENT_NAME",
    CATALOG: "CATALOG_",
    EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ORDERS: "ORDERS",
    CALLAWAY_IFRAME_URL: "CALLAWAY_IFRAME_URL",
    USER_INFO: "USER_INFO",
    BRAND_LIST: "BRAND_LIST",
    CLIENT_EXCHANGE_USER: "CLIENT_EXCHANGE_USER",
    CURRENT_ORDER_ID: "CURRENT_ORDER_ID",
    CLIENT_LOGO: "CLIENT_LOGO",
    API_COOKIE: "API_COOKIE",
    API_TOKEN: "API_TOKEN",
    APP_PRICE_TYPE: "APP_PRICE_TYPE",
    CURRENT_CATALOG_RESULTS: "CURRENT_CATALOG_RESULTS",
    CURRENT_CATALOG_SHIPMENTS: "CURRENT_CATALOG_SHIPMENTS",
    CURRENT_CLIENT_NAME: "CURRENT_CLIENT_NAME",
    DEFAULT_CLIENT_NAME: "callawaygolf",
    REFERESH_TOKEN: "REFRESH_TOKEN",
    COGNITO_POOL_ID: "COGNITO_POOL_ID",
    COGNITO_CLIENT_ID: "COGNITO_CLIENT_ID",
    PRODUCT_RESULTS_PER_PAGE: 20,
    STORED_USERNAME: "STORED_USERNAME",
    STORED_PASSWORD: "STORED_PASSWORD",
    CATALOG_SORT_OPTIONS: [
        {
            name: "Catalog",
            id: "catalog"
        },
        {
            name: "Price",
            id: "price"
        },
        {
            name: "Name",
            id: "name"
        },
        {
            name: "Product Number",
            id: "number"
        },
        {
            name: "Availible Inventory",
            id: "inventory"
        },
        {
            name: "Relevance",
            id: "relevance"
        }
    ],
    BRAND_SORT_OPTIONS: [
        {
            name: "A to Z",
            id: "ascending"
        },
        {
            name: "Z to A",
            id: "descending"
        }
    ]
}

export default constants