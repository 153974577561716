import { IonContent, IonPage } from '@ionic/react';
import EmptyList from 'components/empty-list/EmptyList';
import { notificationsOutline } from  'ionicons/icons';

const Notifications: React.FC = () => {
  const notificationItems = [];

  return (
    <IonPage className='page-with-menu'>

      <IonContent class="ion-padding">
        {notificationItems.length === 0 && 
          <EmptyList 
            icon={notificationsOutline} 
            title="No Notifications" 
            firstLineSubext='No notifications currently.' 
            secondLineSubtext='We will let you know when we have something.' 
            buttonText='Get Back to Exploring' 
            buttonLink='/explore'
          />
        }
      </IonContent>
    </IonPage>
  );
};

export default Notifications;
