import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonList, IonModal, IonRouterLink, IonToolbar, useIonLoading, useIonViewWillEnter } from '@ionic/react';
import { useState, useRef } from 'react';
import { getOrders } from "services/order-service"
import './OrderListing.scss';
import OrderItem from "./order-item/OrderItem"
import { ReadOnlyOrder } from 'models';
import { useHistory } from 'react-router';
import { useCart } from 'services/useCart';
import { closeOutline } from 'ionicons/icons';
import { useConnection } from 'services/useConnection';
import { useSession } from 'services/useSession';
import { getTranslation } from 'translations';
import Breadcrumbs from 'components/header/breadcrumbs/Breadcrumbs';

const OrderListing: React.FC = () => {
  const [draftOrders, setDraftOrders] = useState<ReadOnlyOrder[]>([]);
  const [submittedOrders, setSubmittedOrders] = useState<ReadOnlyOrder[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [orderClicked, setOrderClicked] = useState<string>("");

  const [present, dismiss] = useIonLoading();
  const history = useHistory();
  const { connected } = useConnection();
  const { userLanguageCode } = useSession();
  
  const { cartItems, loadExistingOrder, currentOrder } = useCart();

  const warningModal = useRef<HTMLIonModalElement>(null);

  useIonViewWillEnter(() => {
    getOrderList(true);
  })

  async function loadOrder(orderId: string) {
    await present({ message: "Loading order..." });
    let loaded = await loadExistingOrder(orderId);
    if(loaded) {
      history.replace("/cart");
    } else {
      alert("Please try again.")
    }
    await dismiss();
  }

  async function openOrder(order: ReadOnlyOrder) {
    await setOrderClicked(order._id);
    if(order.state == "final_submission") {
      history.replace("/manage/orders/order-detail/" + order._id);
    } else {
      if(currentOrder && order._id == currentOrder._id) {
        history.replace("/cart");
      } else {
        if(cartItems.length > 0) {
          await setModalOpen(true);
        } else {
          await loadOrder(order._id);
        }
      }
    }
  }

  async function getOrderList(showLoader: boolean) {
    if(showLoader) {
      await present({ message: "Loading orders..." });
    }
    let orderData = await getOrders("0-100", connected);
    if(orderData && orderData.ordersDynamic) {
      let draftOrderList: ReadOnlyOrder[] = [];
      let submittedOrderList: ReadOnlyOrder[] = [];

      orderData.ordersDynamic.forEach((order: ReadOnlyOrder) => {
        if(order.state == "draft") {
          draftOrderList.push(order);
        }

        if(order.state == "final_submission") {
          submittedOrderList.push(order);
        }
      });
      setDraftOrders(draftOrderList)
      setSubmittedOrders(submittedOrderList)
    }

    await dismiss();
  }

  function displayList(list: ReadOnlyOrder[]) {
    return list && list.map((order: ReadOnlyOrder, index: number) => {
      return (
        <div key={index} >
          <OrderItem order={order} openOrder={openOrder}/>
        </div>
      );
    })
  }

  return (
    <div className='order-list'>
      <Breadcrumbs />
      <h1>{getTranslation('orders', userLanguageCode)} </h1> 
      <IonList>
        <>
          {displayList(draftOrders)}
          {displayList(submittedOrders)}
        </>
      </IonList>
      <IonModal className='pwa-modal warning' onIonModalDidDismiss={() => { setModalOpen(false) }} isOpen={modalOpen} ref={warningModal} initialBreakpoint={0.35}>
        <IonHeader className="no-border-toolbar">
          <IonToolbar>
            <h2>{getTranslation('warning', userLanguageCode)}</h2>
            <IonButtons slot="end">
              <IonButton onClick={() => { warningModal.current?.dismiss(); }}><IonIcon class="icon" size="large" icon={closeOutline} color="primary" /></IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className='text'>
            <p>
              {getTranslation('draftOrderWarning', userLanguageCode)} 
            </p>
            <p>
              {getTranslation('areYouSure', userLanguageCode)} 
            </p>
          </div>
          <div className='choices'>
            <IonRouterLink onClick={() => { loadOrder(orderClicked); warningModal.current?.dismiss(); return false; }}>{getTranslation('yes', userLanguageCode)} </IonRouterLink>
            <IonRouterLink onClick={() => { warningModal.current?.dismiss(); }}>{getTranslation('no', userLanguageCode)} </IonRouterLink>
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default OrderListing;
