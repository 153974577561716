import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonIcon,
  IonButton,
  IonToolbar,
  IonSegment,
  IonSegmentButton
} from '@ionic/react';
import React, { useEffect, useRef } from 'react';
import { closeOutline } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import './SideMenu.scss';
import { useSession } from '../../services/useSession';
import { useCart } from 'services/useCart';
import { getTranslation } from 'translations';
import { getStoredKey } from 'services/storage-service';
import constants from '../../constants';
import { User } from 'models/User';
import { getUserDashboard } from 'services/dashboard-service';
import LinkSection from './link-section/LinkSection';

const SideMenu: React.FC = () => {
  const [directoryMode, setDirectoryMode] = React.useState<boolean>(false)
  const [toolbar, setToolbar] = React.useState<string | null>("")
  const [primaryLinks, setPrimaryLinks] = React.useState<any[]>([])
  const [secondaryLinks, setSecondaryLinks] = React.useState<any[]>([])
  const [exploreLinks, setExploreLinks] = React.useState<any[]>([])
  const [manageLinks, setManageLinks] = React.useState<any[]>([])

  const { logout, userCatalogId, userLanguageCode, getUserInfo, isAuthenticated } = useSession();
  const { clearOrder } = useCart();
  const history = useHistory(); 

  const myMenu: any = useRef(null);

  useEffect(() => {
    async function getData() {
        const clientExchangeUser: boolean = await getStoredKey(constants.CLIENT_EXCHANGE_USER);
        setDirectoryMode(clientExchangeUser);

        const userData: User | undefined = await getUserInfo();

        try{
        if (userData && userData.customer) {
          let dashboardData = await getUserDashboard(userData.customer.number!, true);

          if(dashboardData.data.exploreDynamic.dashboard.navigation_settings) {
            console.log("user menu", dashboardData.data.exploreDynamic.dashboard.navigation_settings)
            if(dashboardData.data.exploreDynamic.dashboard.navigation_settings.primary_items) {
              setPrimaryLinks(dashboardData.data.exploreDynamic.dashboard.navigation_settings.primary_items);
            }

            if(dashboardData.data.exploreDynamic.dashboard.navigation_settings.secondary_items) {
              setSecondaryLinks(dashboardData.data.exploreDynamic.dashboard.navigation_settings.secondary_items);
            }

            if(dashboardData.data.exploreDynamic.dashboard.navigation_settings.explore_links) {
              setExploreLinks(dashboardData.data.exploreDynamic.dashboard.navigation_settings.explore_links);
            }

            if(dashboardData.data.exploreDynamic.dashboard.navigation_settings.manage_links) {
              setManageLinks(dashboardData.data.exploreDynamic.dashboard.navigation_settings.manage_links);
            }
          }
        }
      } catch {

      }
    }

    getData();
  }, [history, isAuthenticated]);

  const closeMenu = () => {
    myMenu.current.toggle();
  }

  const handleToolbar = (e: any) => {
    if(e.detail.value == "explore" && exploreLinks.length == 0) {
      history.push("/explore/")
      myMenu.current.toggle();
    } else {
      setToolbar(e.detail.value)
    }
  }

  return (
    <IonMenu ref={myMenu} contentId="main" type="overlay" style={{ "--width": "100%" }} swipeGesture={false}>
      <IonContent className='side-menu'>
        <div className='close-icon ion-padding'>
          <IonIcon onClick={closeMenu} icon={closeOutline} color="primary" />
        </div>
        <div className="page-header">
          {userCatalogId &&
          <IonMenuToggle key={'orders'} autoHide={false}>
            <IonButton class="menu-button" shape="round" routerLink={"/explore/catalogs?catalogId=" + userCatalogId}>{directoryMode ? getTranslation('viewProducts', userLanguageCode) :  getTranslation('shopNow', userLanguageCode)}</IonButton>
          </IonMenuToggle>}
          {!directoryMode && 
            <IonToolbar>
              <IonSegment value={toolbar} onIonChange={handleToolbar}>
                {userCatalogId && exploreLinks.length > 0 &&
                  <IonSegmentButton value="explore">Explore</IonSegmentButton>
                }
                {userCatalogId && manageLinks.length > 0 &&
                  <IonSegmentButton value="manage">Manage</IonSegmentButton>}
              </IonSegment>
            </IonToolbar>
          }
        </div>
        {toolbar === '' && 
          <div className="list-container explore">
            <IonList>
              <IonMenuToggle key={'default'} autoHide={false}>
                <LinkSection links={primaryLinks} large={true} />
              </IonMenuToggle>
              <IonMenuToggle key={'logout'} autoHide={false}>
                <LinkSection links={secondaryLinks} large={false} />
                <IonItem lines="none" detail={false} class="small-item" onClick={() => { clearOrder(); logout(); }}>
                  <IonLabel>{getTranslation('logout', userLanguageCode)}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </IonList>
          </div>
        }
        {toolbar === 'explore' && !directoryMode &&
          <div className="list-container explore">
            <IonList>
              <IonMenuToggle key={'orders'} autoHide={false}>
                <LinkSection links={exploreLinks} large={true} />
              </IonMenuToggle>
            </IonList>
          </div>}
        {toolbar === 'manage' && !directoryMode && manageLinks.length > 0 &&
          <div className="list-container">
            <IonList>
              <IonMenuToggle key={'orders'} autoHide={false}>
                <LinkSection links={manageLinks} large={true} />
              </IonMenuToggle>
            </IonList>
          </div>}
      </IonContent>
    </IonMenu>
  );
};

export default SideMenu;
