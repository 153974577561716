import {
  IonPage
} from "@ionic/react";
import CartDetails from "components/cart-details/CartDetails";

const Cart: React.FC = (props) => {

  return (
    <IonPage className="page-with-menu cart-page">
      <CartDetails />
    </IonPage>
  );
};

export default Cart;
