import React, { useEffect, useRef, useState } from 'react';
import Breadcrumbs from 'components/header/breadcrumbs/Breadcrumbs';
import CachedImage from 'components/cached-image/CachedImage';
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonModal, IonPage, IonRouterLink, IonRow, IonTextarea, IonToolbar } from '@ionic/react';
import { getTranslation } from 'translations';
import { useSession } from 'services/useSession';
import { useParams } from 'react-router-dom';
import { useConnection } from 'services/useConnection';
import { getBrand } from 'services/brand-detail-service';
import { getCatalog, getProduct } from 'services/catalog-service';
import './BrandDetail.scss';
import { closeOutline } from 'ionicons/icons';
import { contactBrand } from 'services/brand-service';
import { BrandContactObject, ContactModalErrors } from 'models/BrandTypes';
import { User } from 'models/User';
import EmptyList from 'components/empty-list/EmptyList';

const BrandProductTile = (props: any) => {
  const { id, index } = props
  const [item, setItem] = React.useState<any>(null);

  const fetchData = async () => {
    setItem(await getProduct(id))
  }

  React.useEffect(() => {
    fetchData()
  }, [id])

  if (!item || !item.name) return <></>

  return (
    <IonRouterLink className={`product-tile ${index === 0 ? 'product-tile-first' : ''}`} routerLink={`/product-detail/${item.number}`}>
      <CachedImage src={item.imageToShow} />
      <span>{item.name}</span>
      <p>{item.number}</p>
    </IonRouterLink>
  )
}

const BrandDetail: React.FC = () => {
  const [openContactModal, setOpenContactModal] = React.useState<boolean>(false);
  const [contactAvailible, setContactAvailible] = React.useState<boolean>(false);
  const [contactModalInputs, setContactModalInputs] = React.useState<BrandContactObject>({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    phone_number: "",
    about: "",
    interested_products: "",
    brand_name: "",
    street1: "",
    street2: "",
    street3: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });
  const [contactModalErrors, setContactModalErrors] = useState<ContactModalErrors>({
    street1: false,
    country: false,
    city: false,
    name: false,
    state: false,
    zip: false,
    email: false,
    firstName: false,
    lastName: false,
    companyName: false
  });
  const { connected } = useConnection();
  const { userCatalogId, userLanguageCode, getUserInfo } = useSession();
  
  const [item, setItem] = React.useState<any>(null);
  const [hasProductsToSHow, setHasProductsToShow] = React.useState<any>(false);
  const [hasFullProductList, setHasFullProductList] = React.useState<any>(false);
  const { id } = useParams<any>();

  const contactModal = useRef<HTMLIonModalElement>(null);
  const successModal = useRef<HTMLIonModalElement>(null);

  // this sets the hiehgt of the modal properly....
  let breakpoint = (410 / window.innerHeight)

  if(breakpoint > 1) {
    breakpoint = 1;
  }

  useEffect(() => {      
    fetchData();
  }, [id])

  const generateProductCommaList = (productsList: any[]) => {
    let list = ""
    productsList.forEach((product: any) => {
      list += "," + product.productId;
    });

    return list;
  }

  const fetchData = async () => {
    setItem(null);
    const userResult: User | undefined = await getUserInfo();
    let hasProducts = false;
    if(userResult && userResult.catalogs && userResult.catalogs.length > 0 && userResult.customer) {
      
      const result = await getBrand(connected, id);

      let fullBrandCatalogResults = await getCatalog(
        userResult.catalogs[0].key,
        userResult?.customer?.number!,
        "price",
        "tags[Brand][]="+result.name,
        false,
        true,
        true,
        1,
        "",
        connected
      );

      hasProducts = fullBrandCatalogResults.results.length > 0;
      setHasFullProductList(hasProducts)

      let productsList: any[] = [];

      try {
        productsList = result.contentBlocksCollection?.items?.find((block: any) => block.__typename === "FeaturedProducts")?.productsCollection?.items;
      } catch {
        console.log("no products defined")
      }

      if(productsList && Array.isArray(productsList) && productsList.length > 0 && hasProducts) {
        // load brand catalog data into memory
        let catalogResults = await getCatalog(
          userResult.catalogs[0].key,
          userResult?.customer?.number!,
          "price",
          "",
          false,
          true,
          true,
          1,
          generateProductCommaList(productsList),
          connected
        );

        setHasProductsToShow((catalogResults.results.length > 0))
      }
      
      setContactAvailible(checkIfContactAvailible(result));
      setItem(result);

      setContactModalInputs((oldInputs: any) => ({
        ...oldInputs,
        brand_name: id, company_name: id}))

      setContactModalInputs((oldInputs: any) => ({
        ...oldInputs,
        first_name: userResult.first_name, last_name: userResult.last_name, email: userResult.email}))
    }
  }

  const checkIfContactAvailible = (brand: any) => {
    let canContact = false;

    try{
      brand.contentBlocksCollection.items.forEach((block: any) => {
        if(block.__typename === "BrandContactDetails") {
          if(block.email && block.email != "") {
            canContact = true;
            setContactModalInputs((oldInputs: any) => ({
              ...oldInputs,
              brand_to_emails: [block.email]}));
          }
        }
      })      
    } catch {

    }

    return canContact;
  }

  function allContactModalFieldsValidate(): boolean {
    let valid = true;

    setContactModalErrors((oldErrors) => ({
      ...oldErrors,
      firstName: (contactModalInputs.first_name == null || contactModalInputs.first_name == ""), 
      lastName: (contactModalInputs.last_name == null || contactModalInputs.last_name == ""),
      email: (contactModalInputs.email == null || contactModalInputs.email == ""),
      country: (contactModalInputs.country == null || contactModalInputs.country == ""),
      street1: (contactModalInputs.street1 == null || contactModalInputs.street1 == ""),
      city: (contactModalInputs.city == null || contactModalInputs.city == ""),
      zip: (contactModalInputs.zip == null || contactModalInputs.zip == ""),
      state: (contactModalInputs.state == null || contactModalInputs.state == ""),
      companyName: (contactModalInputs.company_name == null || contactModalInputs.company_name == "")}));

    if(contactModalInputs.first_name == "" || contactModalInputs.last_name == "" || contactModalInputs.email == "" || contactModalInputs.country == "" || contactModalInputs.street1 == "" ||  
       contactModalInputs.city == "" || contactModalInputs.zip == "" || contactModalInputs.state == "" || contactModalInputs.company_name == "") {
        valid = false;
      }

    return valid;
  }

  const sendContactEmail = async () => {
    console.log(contactModalInputs);
    console.log(allContactModalFieldsValidate());
    if(allContactModalFieldsValidate()) {
      await contactBrand(contactModalInputs, connected)
      contactModal.current?.dismiss();
      successModal.current?.present();
    }
  }

  const initials = (name: string) =>
    name?.split(" ").map((n) => n[0])

  if (!item)
    return <></>

  const marquee = item.contentBlocksCollection?.items.find((block: any) => block.__typename === "Marquee");
  const intro = item.contentBlocksCollection?.items.find((block: any) => block.__typename === "BrandIntro");
  const contact = item.contentBlocksCollection?.items.find((block: any) => block.__typename === "BrandContactDetails");
  const products = item.contentBlocksCollection?.items?.find((block: any) => block.__typename === "FeaturedProducts")?.productsCollection?.items;
  const columns = item.contentBlocksCollection?.items?.find((block: any) => block.__typename === "ColumnContainer")?.columnsCollection?.items;
  const team = item.teamMembersCollection?.items;

  let videoId = ""
  if(marquee && marquee.videoUrl && marquee.videoUrl.indexOf("youtube.com") !== -1) {
    videoId = marquee.videoUrl.split('v=')[1];
    var ampersandPosition = videoId.indexOf('&');
    if(ampersandPosition != -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }
  }

  if(marquee && marquee.videoUrl && marquee.videoUrl.indexOf("vimeo.com") !== -1) {
    videoId = marquee.videoUrl.split('vimeo.com/')[1];
    var ampersandPosition = videoId.indexOf('&');
    if(ampersandPosition != -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }
  }

  return (
    <IonPage className='page-with-menu'>
      <IonContent>
        <div className='page-content brands-detail-page'>
          <Breadcrumbs />
          <div className='padding-area'>
            {marquee &&
              <div className="banner">
                {marquee.image && <CachedImage src={marquee.image.url} />}
                {marquee.videoUrl && marquee.videoUrl.indexOf("youtube.com") !== -1 && 
                  <div className='video-container'>
                    <iframe src={"https://www.youtube.com/embed/" + videoId} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                }
                {marquee.videoUrl && marquee.videoUrl.indexOf("vimeo.com") !== -1 && 
                  <div className='video-container'>
                    <iframe src={"https://player.vimeo.com/video/" + videoId} allow="autoplay; fullscreen; picture-in-picture"></iframe>
                  </div>
                }
                {marquee.videoUrl && marquee.videoUrl.indexOf("youtube.com") === -1 && marquee.videoUrl.indexOf("vimeo.com") === -1 && <video src={marquee.videoUrl} style={{ height: '56.25vw', width: '100%' }} />}
              </div>}
            <div className="intro">
              <h1 className="title">{item.name}</h1>
              {intro &&
                <div>
                  {intro.description &&
                    <p dangerouslySetInnerHTML={{ __html: intro.description }}></p>}
                </div>}
              {hasFullProductList && 
                <div className="cta">
                  <IonButton routerLink={`/explore/catalogs?catalogId=${userCatalogId}&tags=Brand:${encodeURIComponent(item?.name)}`} color={"primary"} shape="round">
                    {getTranslation('viewAllProducts', userLanguageCode) || 'View All Products'}
                  </IonButton>
                </div>
              }
            </div>
            {products && products.length > 0 && hasProductsToSHow && 
              <div className="products">
                <h2>{getTranslation('ourProducts', userLanguageCode)}</h2>
                <IonGrid style={{ "--ion-grid-padding": 0 }}>
                  {products
                    .filter((product: any, index: number) => index === 0)
                    .map((product: any, index: number) =>
                      <IonRow key={index}>
                        <IonCol size={index === 0 ? '12' : '6'} key={index}>
                          <BrandProductTile key={product.productId} id={product.productId} index={0} />
                        </IonCol>
                      </IonRow>)}
                  <IonRow>
                    {products
                      .filter((product: any, index: number) => index > 0)
                      .map((product: any, index: number) =>
                        <IonCol size={'6'} key={index}>
                          <BrandProductTile key={product.productId} id={product.productId} index={index + 1} />
                        </IonCol>)}
                  </IonRow>
                </IonGrid>
                <div className="cta">
                  <IonButton routerLink={`/explore/catalogs?catalogId=${userCatalogId}&tags=Brand:${encodeURIComponent(item?.name)}`} color={"primary"} shape="round">
                    {getTranslation('viewAllProducts', userLanguageCode) || 'View All Products'}
                  </IonButton>
                </div>
              </div>}
            {columns && columns.map((column: any, index: number) =>
              <div className="section" key={index}>
                <div className="image">
                  {column.image && <CachedImage src={column.image.url} />}
                </div>
                <h3 className="title">{column.heading}</h3>
                <div>
                  {column.description &&
                    <p dangerouslySetInnerHTML={{ __html: column.description }}></p>}
                </div>
              </div>)}
            {team &&
              <div className="team">
                <h4>{getTranslation('team', userLanguageCode)}</h4>
                <IonGrid style={{ "--ion-grid-padding": 0 }}>
                  {team.map((member: any) =>
                    <IonRow key={`${member.email || member.name}`} className="member">
                      <IonCol size="3" className="member-avatar">
                        {member.photo
                          ? <CachedImage src={member.photo.url} />
                          : <div className="initials">{initials(member.name)}</div>}
                      </IonCol>
                      <IonCol size="9" className="member-info">
                        {member.name && <strong>{member.name}</strong>}
                        {true && <span>{member.title || 'Account Representative'}</span>}
                      </IonCol>
                    </IonRow>)}
                </IonGrid>
              </div>}
            {contact &&
              <div className="contact">
                <h4>{getTranslation('contact', userLanguageCode)} {item.name}</h4>
                <IonGrid style={{ "--ion-grid-padding": 0, "--ion-grid-column-padding": 0, "--ion-grid-column-padding-xs": 0 }}>
                  {contact.businessWebSite &&
                    <IonRow>
                      <IonCol size="3">
                        {getTranslation('website', userLanguageCode)}
                      </IonCol>
                      <IonCol size="9">
                        <a href={contact.businessWebSite} target="_blank" rel="noreferrer">{contact.businessWebSite}</a>
                      </IonCol>
                    </IonRow>}
                  {contact.email &&
                    <IonRow>
                      <IonCol size="3">
                        {getTranslation('email', userLanguageCode)}
                      </IonCol>
                      <IonCol size="9">
                        <a href={`mailto://${contact.email}`} target="_blank" rel="noreferrer">{contact.email}</a>
                      </IonCol>
                    </IonRow>}
                  {contact.phone &&
                    <IonRow>
                      <IonCol size="3">
                        {getTranslation('phone', userLanguageCode)}
                      </IonCol>
                      <IonCol size="9">
                        <a href={`tel://${contact.phone}`}>{contact.phone}</a>
                      </IonCol>
                    </IonRow>}
                  {item.companyAddress &&
                    <IonRow>
                      <IonCol size="3">
                        {getTranslation('address', userLanguageCode)}
                      </IonCol>
                      <IonCol size="9">
                        <a href={`https://maps.google.com/?q=${item.companyAddress}`} target="_blank" rel="noreferrer">{item.companyAddress}</a>
                      </IonCol>
                    </IonRow>}
                  {contact.socialLinksCollection?.items &&
                    <IonRow>
                      <IonCol size="3">
                        {getTranslation('social', userLanguageCode)}
                      </IonCol>
                      <IonCol size="9">
                        {contact.socialLinksCollection?.items?.map((link: any) =>
                          <a key={link.type} href={link.uri} target="_blank" rel="noreferrer" className="social">{link.type}</a>)}
                      </IonCol>
                    </IonRow>}
                </IonGrid>
              </div>}
            {contactAvailible && 
              <div className="contact-button">
                <IonButton onClick={() => { setOpenContactModal(true) }}>{getTranslation('contact', userLanguageCode)} {item.name}</IonButton>
              </div>
            }
          </div>
        </div>
        <IonModal ref={successModal} className='pwa-modal' mode="ios" initialBreakpoint={breakpoint}>
          <IonHeader className="no-border-toolbar">
            <IonToolbar mode='ios'>
              <h2>Contact {item.name}</h2>
              <IonButtons slot="end">
                <IonButton onClick={() => { successModal.current?.dismiss(); }} ><IonIcon class="icon" size="large" icon={closeOutline} color="primary" /></IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className='success-wrapper'>
              <EmptyList 
                title="Thank you for your interest!" 
                firstLineSubext={'Your message has been sent!'}
              />
              <div className='modal-actions'>
                <IonButton
                  type="button"
                  expand="block"
                  onClick={() => { successModal.current?.dismiss(); }}
                >
                  {getTranslation('close', userLanguageCode)}
                </IonButton>
              </div>
            </div>
          </IonContent>
        </IonModal>
        <IonModal onIonModalDidDismiss={() => { setOpenContactModal(false) }} ref={contactModal} className='pwa-modal form-modal' mode="ios" isOpen={openContactModal}>
          <IonHeader className="no-border-toolbar">
            <IonToolbar mode='ios'>
              <h2>Contact {item.name}</h2>
              <IonButtons slot="end">
                <IonButton onClick={() => { setOpenContactModal(false) }} ><IonIcon class="icon" size="large" icon={closeOutline} color="primary" /></IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className='address-form'>
              <p className='intro'>{getTranslation('contactFormIntro', userLanguageCode)}</p>
              <div className='input-wrapper'>
                <div className="label-container">
                  <IonLabel class="input-label" position="stacked">
                    {getTranslation('firstName', userLanguageCode)}
                  </IonLabel>
                  <IonLabel class="input-label required-label" position="stacked">
                    {getTranslation('required', userLanguageCode)}
                  </IonLabel>
                </div>
                <IonInput value={contactModalInputs.first_name} onIonBlur={(event: any) => { 
                  setContactModalInputs((oldInputs: any) => ({
                    ...oldInputs,
                    first_name: event.detail.target.value}));
                }}></IonInput>
                {contactModalErrors.firstName && 
                  <div className='error-text'>
                    <p>{getTranslation('fieldRequired', userLanguageCode)}</p>
                  </div>
                }
              </div>
              <div className='input-wrapper'>
                <div className="label-container">
                  <IonLabel class="input-label" position="stacked">
                    {getTranslation('lastName', userLanguageCode)}
                  </IonLabel>
                  <IonLabel class="input-label required-label" position="stacked">
                    {getTranslation('required', userLanguageCode)}
                  </IonLabel>
                </div>
                <IonInput value={contactModalInputs.last_name} onIonBlur={(event: any) => { 
                  setContactModalInputs((oldInputs: any) => ({
                    ...oldInputs,
                    last_name: event.detail.target.value}));
                }}></IonInput>
                {contactModalErrors.lastName && 
                  <div className='error-text'>
                    <p>{getTranslation('fieldRequired', userLanguageCode)}</p>
                  </div>
                }
              </div>
              <div className='input-wrapper'>
                <div className="label-container">
                  <IonLabel class="input-label" position="stacked">
                    {getTranslation('email', userLanguageCode)}
                  </IonLabel>
                  <IonLabel class="input-label required-label" position="stacked">
                    {getTranslation('required', userLanguageCode)}
                  </IonLabel>
                </div>
                <IonInput value={contactModalInputs.email} onIonBlur={(event: any) => { 
                  setContactModalInputs((oldInputs: any) => ({
                    ...oldInputs,
                    email: event.detail.target.value}));
                }}></IonInput>
                {contactModalErrors.email && 
                  <div className='error-text'>
                    <p>{getTranslation('fieldRequired', userLanguageCode)}</p>
                  </div>
                }
              </div>
              <div className='input-wrapper'>
                <IonLabel position="stacked">{getTranslation('phone', userLanguageCode)}</IonLabel>
                <IonInput onIonBlur={(event: any) => { 
                  setContactModalInputs((oldInputs: any) => ({
                    ...oldInputs,
                    phone_number: event.detail.target.value}));
                }}></IonInput>
              </div>
              <div className='section'>
                <h2>{getTranslation('yourCompany', userLanguageCode)}</h2>
              </div>
              <div className='input-wrapper'>
                <div className="label-container">
                  <IonLabel class="input-label" position="stacked">
                    {getTranslation('companyName', userLanguageCode)}
                  </IonLabel>
                  <IonLabel class="input-label required-label" position="stacked">
                    {getTranslation('required', userLanguageCode)}
                  </IonLabel>
                </div>
                <IonInput value={contactModalInputs.company_name} onIonBlur={(event: any) => { 
                  setContactModalInputs((oldInputs: any) => ({
                    ...oldInputs,
                    company_name: event.detail.target.value}));
                }}></IonInput>
                {contactModalErrors.companyName && 
                  <div className='error-text'>
                    <p>{getTranslation('fieldRequired', userLanguageCode)}</p>
                  </div>
                }
              </div>
              <div className='input-wrapper'>
                <div className="label-container">
                  <IonLabel class="input-label" position="stacked">
                    {getTranslation('street1', userLanguageCode)}
                  </IonLabel>
                  <IonLabel class="input-label required-label" position="stacked">
                    {getTranslation('required', userLanguageCode)}
                  </IonLabel>
                </div>
                <IonInput onIonBlur={(event: any) => { 
                  setContactModalInputs((oldInputs: any) => ({
                    ...oldInputs,
                    street1: event.detail.target.value
                    }));
                }}></IonInput>
                {contactModalErrors.street1 && 
                  <div className='error-text'>
                    <p>{getTranslation('fieldRequired', userLanguageCode)}</p>
                  </div>
                }
              </div>
              <div className='input-wrapper'>
                <IonLabel position="stacked">{getTranslation('street2', userLanguageCode)}</IonLabel>
                <IonInput onIonBlur={(event: any) => { 
                  setContactModalInputs((oldInputs: any) => ({
                    ...oldInputs,
                      street2: event.detail.target.value
                    }));
                }}></IonInput>
              </div>
              <div className='input-wrapper'>
                <div className="label-container">
                  <IonLabel class="input-label" position="stacked">
                    {getTranslation('city', userLanguageCode)}
                  </IonLabel>
                  <IonLabel class="input-label required-label" position="stacked">
                    {getTranslation('required', userLanguageCode)}
                  </IonLabel>
                </div>
                <IonInput onIonBlur={(event: any) => { 
                  setContactModalInputs((oldInputs: any) => ({
                    ...oldInputs,
                      city: event.detail.target.value
                    }));
                }}></IonInput>
                {contactModalErrors.city && 
                  <div className='error-text'>
                    <p>{getTranslation('fieldRequired', userLanguageCode)}</p>
                  </div>
                }
              </div>
              <div className='split-inputs'>
                <div className='input-wrapper state'>
                  <div className="label-container">
                    <IonLabel class="input-label" position="stacked">
                      {getTranslation('stateProvince', userLanguageCode)}
                    </IonLabel>
                    <IonLabel class="input-label required-label" position="stacked">
                      {getTranslation('required', userLanguageCode)}
                    </IonLabel>
                  </div>
                  <IonInput onIonBlur={(event: any) => { 
                  setContactModalInputs((oldInputs: any) => ({
                    ...oldInputs,
                      state: event.detail.target.value
                    }));
                }}></IonInput>
                {contactModalErrors.state && 
                  <div className='error-text'>
                    <p>{getTranslation('fieldRequired', userLanguageCode)}</p>
                  </div>
                }
                </div>
                <div className='input-wrapper zip'>
                  <div className="label-container">
                    <IonLabel class="input-label" position="stacked">
                      {getTranslation('zip', userLanguageCode)}
                    </IonLabel>
                    <IonLabel class="input-label required-label" position="stacked">
                      {getTranslation('required', userLanguageCode)}
                    </IonLabel>
                  </div>
                  <IonInput onIonBlur={(event: any) => { 
                  setContactModalInputs((oldInputs: any) => ({
                    ...oldInputs,
                      zip: event.detail.target.value
                    }));
                }}></IonInput>
                {contactModalErrors.zip && 
                  <div className='error-text'>
                    <p>{getTranslation('fieldRequired', userLanguageCode)}</p>
                  </div>
                }
                </div>
              </div>
              <div className='input-wrapper'>
                <div className="label-container">
                  <IonLabel class="input-label" position="stacked">
                    {getTranslation('country', userLanguageCode)}
                  </IonLabel>
                  <IonLabel class="input-label required-label" position="stacked">
                    {getTranslation('required', userLanguageCode)}
                  </IonLabel>
                </div>
                <IonInput onIonBlur={(event: any) => { 
                  setContactModalInputs((oldInputs: any) => ({
                    ...oldInputs,
                      country: event.detail.target.value
                    }));
                }}></IonInput>
                {contactModalErrors.country && 
                  <div className='error-text'>
                    <p>{getTranslation('fieldRequired', userLanguageCode)}</p>
                  </div>
                }
              </div>
              <div className='input-wrapper'>
                <IonLabel position="stacked">{getTranslation('aboutYou', userLanguageCode)}</IonLabel>
                <IonTextarea onIonBlur={(event: any) => { 
                  setContactModalInputs((oldInputs: any) => ({
                    ...oldInputs,
                    about: event.detail.target.value}));
                }}></IonTextarea>
              </div>
              <div className='input-wrapper'>
                <IonLabel position="stacked">{getTranslation('productInterest', userLanguageCode)}</IonLabel>
                <IonTextarea onIonBlur={(event: any) => { 
                  setContactModalInputs((oldInputs: any) => ({
                    ...oldInputs,
                    interested_products: event.detail.target.value}));
                }}></IonTextarea>
              </div>
            </div>
            <div className='modal-actions'>
              <IonButton
                type="button"
                expand="block"
                color={"secondary"}
                onClick={() => { contactModal.current?.dismiss(); }}
              >
                {getTranslation('cancel', userLanguageCode)}
              </IonButton>
              <IonButton
                type="button"
                expand="block"
                onClick={() => { sendContactEmail(); }}
              >
                {getTranslation('send', userLanguageCode)}
              </IonButton>
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default BrandDetail;
