import { IonContent, IonPage } from '@ionic/react';
import EmptyList from 'components/empty-list/EmptyList'
import { checkmarkCircleOutline } from  'ionicons/icons';
import { useEffect, useState } from 'react'
import { useSession } from 'services/useSession';
import { getTranslation } from 'translations';

const Submitted: React.FC = () => {
  const [orderNum, setOrderNum] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const { getUserInfo, userLanguageCode } = useSession();

  useEffect(() => {

    async function getData() {
      const result: any = await getUserInfo();
      if(result.email) {
        setEmail(result.email);
      }
      const pathnames = window.location.pathname.split("/");
      let id = pathnames[pathnames.length - 1];
      setOrderNum(id)
    }

    getData()
  }, []);

  return (
    <IonPage className={'page-with-menu'}>
      <IonContent>
        {true && 
          <EmptyList 
            icon={checkmarkCircleOutline} 
            title={getTranslation('thanksForOrder', userLanguageCode)} 
            firstLineSubext={getTranslation('yourOrder', userLanguageCode) + ' <strong>#' + orderNum + '</strong> ' + getTranslation('confirmationEmailSentTo', userLanguageCode) +' <strong>' + email + '</strong> ' + getTranslation('shortly', userLanguageCode) + '.'} 
            buttonText={getTranslation('backToExploring', userLanguageCode)}
            buttonLink='/explore'
          />
        }
      </IonContent>
    </IonPage>
  );
};

export default Submitted;
