import { IonCheckbox, IonIcon } from '@ionic/react';
import { removeOutline, addOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import FacetCheckbox from "./facet-checkbox/FacetCheckbox"

interface FacetGroupProps {
  facet: any;
  toggleFacet: Function;
  selectedFacets: any[];
  setSelectedFacets: Function;
}

const FacetGroup: React.FC<FacetGroupProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const values = props.facet.options.map((term: any) => `${props.facet.title}|${term.title}`)
  const [all, setAll] = useState<boolean>(values.every((v: any) => props.selectedFacets.includes(v)));
  const handleToggle = () => {
    if (!all)
      props.setSelectedFacets(Array.from(new Set([...props.selectedFacets, ...values])))
    else
      props.setSelectedFacets(props.selectedFacets.filter((value: any) => !values.includes(value)))
    setAll(!all)
  };

  useEffect(() => {
    setAll(values.every((v: any) => props.selectedFacets.includes(v)))
  }, [props.selectedFacets])

  return (
    <div className='facet-group'>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '19px 0' }}>
        <IonCheckbox mode='md' color="secondary" onClick={handleToggle} checked={all} />
        <h3 onClick={() => setOpen(!open)}>
          {props.facet.title}
          {open ? <IonIcon icon={removeOutline} color="primary" /> : <IonIcon icon={addOutline} color="primary" />}
        </h3>
      </div>
      <div className={'options' + (open ? "expanded" : "")}>
        {props.facet.options && props.facet.options.map((term: any, i: number) => {
          return (
            <FacetCheckbox key={i} option={props.facet} term={term} toggleFacet={props.toggleFacet} selectedFacets={props.selectedFacets} />
          );
        })}
      </div>
    </div>
  );
};

export default FacetGroup;