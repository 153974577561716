import React from 'react';
import Breadcrumbs from 'components/header/breadcrumbs/Breadcrumbs';
import CachedImage from 'components/cached-image/CachedImage';
import { IonCol, IonContent, IonGrid, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonPage, IonRouterLink, IonRow, IonText, useIonLoading } from '@ionic/react';
import { getBrands } from 'services/brand-service';
import { useConnection } from 'services/useConnection';
import './Brands.scss';
import { getTranslation } from 'translations';
import { useSession } from 'services/useSession';
import FacetFilters from 'components/product-listing/facet-filters/FacetFilters';
import { optionsOutline } from "ionicons/icons";
import constants from "../../constants";

const Brands: React.FC = () => {
  const { connected } = useConnection();
  const { userLanguageCode } = useSession();
  const [page, setPage] = React.useState<number>(0);
  const [brandTotal, setBrandTotal] = React.useState<number>(0);
  const [brands, setBrands] = React.useState<any[]>([]);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = React.useState<boolean>(false);

  //facets
  const [facets, setFacets] = React.useState<any[]>([]);
  const filterRef = React.useRef<HTMLIonModalElement>(null);
  const [selectedFacets, setSelectedFacets] = React.useState<any[]>([]);
  const [searchCriteria, setSearchCriteria] = React.useState<any>({
    facets: [],
    searchTerm: "",
    sortOption: constants.BRAND_SORT_OPTIONS[0]
  });
  const [present, dismiss] = useIonLoading();

  const fetchData = async (e?: any) => {

    await present({ message: "Loading brands..." });
    const result = (await getBrands(connected, page, searchCriteria));

    setBrandTotal(result.nbHits);
    setPage(result.page + 1)
    
    try {
      let facets = result.renderingContent.facetOrdering.facets.order.filter((title: any) => result.facets[title]).map((title: any) => ({
        title,
        options: Object.keys(result.facets[title])?.map((facet: any) => ({
          title: facet,
          amount: result.facets[title][facet]
        }))
      }))

      setFacets(facets);
    } catch {
      
    }

    if (e) {
      e.target.complete();
    }

    setDisableInfiniteScroll(page === (result.nbPages - 1));
    setBrands(!e ? result.hits : [...brands, ...result.hits]);

    await dismiss();
    handleFilterClose();
  }

  function facetToggleEvent(term: any) {
    let currentFacets = selectedFacets;
    if (term.checked) {
      var index = currentFacets.indexOf(term.value);
      if (index !== -1) {
        currentFacets.splice(index, 1);
      }
      setSelectedFacets([...currentFacets]);
    } else {
      setSelectedFacets([...selectedFacets, ...[term.value]]);
    }
  }

  async function applyFacetFilters(searchTerm: string) {
    setPage(0);
    if (selectedFacets.length > 0 || searchTerm !== "") {
      setSearchCriteria({ ...searchCriteria, searchTerm: searchTerm, facets: selectedFacets });
    }
  }

  async function clearFilters() {
    setPage(0);
    await setSelectedFacets([]);
    setSearchCriteria({ ...searchCriteria, searchTerm: "", facets: [] });
  }

  function totalFacetDisplayCount(): string {
    let total = 0
    total += searchCriteria.facets.length;
    if (searchCriteria.searchTerm !== "") {
      total++;
    }
    return total !== 0 ? "(" + total + ")" : "";
  }

  const handleFilterOpen = () => {
    filterRef.current?.present();
  }

  const handleFilterClose = () => {
    if (filterRef && filterRef.current) {
      filterRef.current?.dismiss();
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [searchCriteria])

  return (
    <IonPage className='page-with-menu'>
      <IonContent>
        <div className="padding-area">
          <Breadcrumbs />
          <div className='page-content brands-page'>
            <h1>{getTranslation('brands', userLanguageCode)}</h1>
            <div className='subtitle'>{getTranslation('resultsFound', userLanguageCode)} ({brandTotal})</div>
            <IonGrid style={{ "--ion-grid-padding": 0 }}>
              <IonRow className="filters">
                <IonCol size="6">
                  <button className={"custom-button " + (searchCriteria.facets.length > 0 || searchCriteria.searchTerm !== "" ? "has-filters" : "")} onClick={handleFilterOpen}>
                    <IonText slot="start">
                      {searchCriteria.facets.length === 0 && getTranslation('viewFilters', userLanguageCode)}
                      {searchCriteria.facets.length > 0 && getTranslation('activeFilters', userLanguageCode)}
                      {totalFacetDisplayCount()}
                    </IonText>
                    <IonIcon icon={optionsOutline} color={searchCriteria.facets.length > 0 || searchCriteria.searchTerm !== "" ? "secondary" : "primary"} />
                  </button>
                </IonCol>
              </IonRow>
              <IonRow>
                {brands.map((brand: any, index: number) =>
                  <IonCol size="12" key={`${brand.entryTitle}_${index}`} className="item">
                    <IonRouterLink routerLink={`/explore/brands/${encodeURIComponent(brand.name)}`} className="card">
                      <CachedImage className="image" src={!brand?.brandTile?.message ? brand.brandTile : brand.image} />
                      <h3>{brand.name}</h3>
                    </IonRouterLink>
                  </IonCol>)}
              </IonRow>
              <IonInfiniteScroll onIonInfinite={fetchData} threshold="100px" disabled={disableInfiniteScroll}>
                <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Loading more data..." />
              </IonInfiniteScroll>
            </IonGrid>

            <FacetFilters
              modalRef={filterRef}
              handleFilterClose={handleFilterClose}
              availibleFacets={facets}
              toggleFacet={facetToggleEvent}
              applyFacets={applyFacetFilters}
              selectedFacets={selectedFacets}
              clearFilters={clearFilters}
              searchTerm={searchCriteria.searchTerm}
              setSearchKeyword={(term: string) => {
                setSearchCriteria({ ...searchCriteria, searchTerm: term })
              }}
              setSelectedFacets={setSelectedFacets}
              placeholder={getTranslation('searchBrands', userLanguageCode)}
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Brands;
