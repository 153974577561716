import {
  IonTitle,
  IonIcon,
  IonItem,
} from "@ionic/react";
import CachedImage from "components/cached-image/CachedImage";
import { linkOutline, playCircleOutline, sendOutline } from "ionicons/icons";

import "./ExploreLinks.scss";

interface ExploreLinksProps {
  links: any[];
  clientName: string;
}

const getLinkIcon = (type: any) => {
  let icon = linkOutline;
  if (type == "video") {
    icon = playCircleOutline;
  } else if (type == "email") {
    icon = sendOutline;
  }
  return icon;
};

const getLinkURL = (type: any, url: any) => {
  let newURL = url;
  if (type == "email") {
    newURL = "mailto:" + url;
  }
  return newURL;
};

const ExploreLinks: React.FC<ExploreLinksProps> = (props) => {

  return (
    <div className="explore-links">
      <IonItem lines="none">
        <div className="links-section">
          <IonTitle class="link-text" size={"small"} color={"primary"}>
            <div className="ion-text-wrap">{props.clientName} Links</div>
          </IonTitle>

          {props.links.map((link: any, index: number) => {
            return (
              <div key={index}>
                {link.image_url != null ? (
                  <div className="link-image">
                    <a href={getLinkURL(link.type, link.url)} target="_blank">
                      <CachedImage src={link.image_url} />
                    </a>
                    <div className="image-link">
                      <a href={getLinkURL(link.type, link.url)} target="_blank">
                        {link.title}
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="icon-link">
                    <a href={getLinkURL(link.type, link.url)} target="_blank">
                      <IonIcon icon={getLinkIcon(link.type)}></IonIcon>{" "}
                    </a>
                    <a href={getLinkURL(link.type, link.url)} target="_blank">
                      {link.title}
                    </a>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </IonItem>
    </div>
  );
};

export default ExploreLinks;
