import React, { useEffect, useState } from 'react';
import { IonButton, IonLabel, IonInput, IonItem, IonToggle, IonRouterLink, useIonLoading, useIonAlert  } from '@ionic/react';
import { useHistory } from 'react-router';
import { useSession } from '../../../services/useSession';
import { getBrandConfig, getClientConfig, getRegularUserPool } from '../../../services/auth-service'
import ClientImages from 'components/login/client-images/ClientImages';
import constants from '../../../constants';
import { setStoredKey, getStoredKey } from '../../../services/storage-service'
import './LoginForm.scss';
import { useConnection } from '../../../services/useConnection';
import { getTranslation } from 'translations';

const LoginForm: React.FC = () => {
  const [loggingIn, setLoggingIn] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [supportUrl, setSupportUrl] = useState(process.env.REACT_APP_DEFAULT_SUPPORT_LINK);

  const { login, isAuthenticated, getUserInfo, userLanguageCode, changeExchangeStatus } = useSession();
  const [present, dismiss] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const { connected } = useConnection();

  useEffect(() => {

    async function getData() {
      if(isAuthenticated){
        redirect();
        return;
      }

      const brandConfig: any = await getBrandConfig(constants.CONFIG_TYPE, true);
      const clientConfig: any = await getClientConfig(brandConfig.brandDynamic.client);

      console.log("login info", brandConfig)
      
      try {
        if(brandConfig.brandDynamic.config.flags.supportUrl) {
          setSupportUrl(brandConfig.brandDynamic.config.flags.supportUrl);
        }

        if(brandConfig.brandDynamic.config.flags.directoryMode){
          await setStoredKey(constants.CLIENT_EXCHANGE_USER, true);
          changeExchangeStatus(true);
        }

        if(clientConfig.clientDynamic){
          await setStoredKey(constants.CLIENT_NAME, clientConfig.clientDynamic.name);
        }

        const cognitoInfo: any = await getRegularUserPool();

        if(cognitoInfo) {
          await setStoredKey(constants.COGNITO_POOL_ID, cognitoInfo.userPoolId);
          await setStoredKey(constants.COGNITO_CLIENT_ID, cognitoInfo.clientId);
        }
      } catch {
        console.log("something was undefined")
      }

      try {
        if(brandConfig.brandDynamic.config.flags.embellishmentStrategies[0].iframeUrl) {
          await setStoredKey(constants.CALLAWAY_IFRAME_URL, brandConfig.brandDynamic.config.flags.embellishmentStrategies[0].iframeUrl);
        }
      } catch {
        console.log("something was undefined")
      }

      setDataLoaded(true)
    }

    async function checkQueryParams () {
      // force a hard refresh after logout
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.private != null) {
        const win: Window = window;
        win.location = window.location.pathname;
      }

      let currentClient = "";
      let dnsSplit = window.location.hostname.split("-");
      if(dnsSplit.length > 1 && dnsSplit[0].indexOf("pwa") === -1 ) {
        currentClient = dnsSplit[0];
        currentClient = currentClient.replace("www.", "").replace("www", "")
        console.log("CURRENT CLIENT", currentClient)
        await setStoredKey(constants.CURRENT_CLIENT_NAME, currentClient)
      } else {
        currentClient = await getStoredKey(constants.CURRENT_CLIENT_NAME);
      }

      if (params.client != null) {
        await setStoredKey(constants.CURRENT_CLIENT_NAME, params.client)
      } else if(!currentClient && currentClient != "") {
        await setStoredKey(constants.CURRENT_CLIENT_NAME, constants.DEFAULT_CLIENT_NAME);
      }

      await getData();
    }

    setSavedCredentials();
    checkQueryParams();
    // eslint-disable-next-line
  }, [history, isAuthenticated]);

  const redirect = async () => {
    const result: any = await getUserInfo();
    const brandConfig: any = await getBrandConfig(constants.CONFIG_TYPE, connected);
    let clientExchangeUser: boolean = false;

    try {
      if(brandConfig.brandDynamic.config.flags.directoryMode) {
        clientExchangeUser = brandConfig.brandDynamic.config.flags.directoryMode
      }
    } catch {

    }


    if((result && result.customer && result.customer.b2c) || clientExchangeUser){
      history.replace('/explore');
    } else {
      history.replace('/no-access');
    } 
  }

  const setSavedCredentials = async () => {
    let savedUsername = await getStoredKey(constants.STORED_USERNAME);
    let savedPassword = await getStoredKey(constants.STORED_PASSWORD);
    if(savedUsername && savedPassword) {
      setPassword(savedPassword)
      setUsername(savedUsername);
      setRememberMe(true)
    }
  }

  const handleLogin = async () => {
    await present({ message: "Logging in..." });
    await setLoggingIn(true);
    let attempt = await login(username, password, rememberMe);
    await dismiss();
    if(!attempt) {
      await setLoggingIn(false)
      presentAlert({
        header: 'Alert',
        message: "Wrong username or password.",
        buttons: [
          {
            text: 'OK',
            role: 'confirm'
          },
        ]
      })
    } else {
      await setLoggingIn(false)

      if(rememberMe) {
        setStoredKey(constants.STORED_PASSWORD, password)
        setStoredKey(constants.STORED_USERNAME, username)
      }
      setTimeout(async () => {
        redirect();
      }, 1000);
    }
  };

  return (
    <>
      {dataLoaded && !isAuthenticated && 
      <>
        <ClientImages />
        <form className="ion-padding">
          <h1>{getTranslation('login', userLanguageCode)}</h1>
          <div className="input-container">
            <div className="label-container">
              <IonLabel class="input-label" position="floating">
                {getTranslation('username', userLanguageCode)}
              </IonLabel>
              <IonLabel class="input-label required-label" position="floating">
               {getTranslation('required', userLanguageCode)}
              </IonLabel>
            </div>
            <IonInput value={username} onIonChange={(e) => setUsername(e.detail.value!)} />
          </div>
          <div className="input-container">
            <div className="label-container">
              <IonLabel class="input-label" position="floating">
                {getTranslation('password', userLanguageCode)}
              </IonLabel>
              <IonLabel class="input-label required-label" position="floating">
                {getTranslation('required', userLanguageCode)}
              </IonLabel>
            </div>
            <IonInput value={password} type="password" onIonChange={(e) => setPassword(e.detail.value!)} onKeyPress={(event) => { if(event.key === 'Enter') { handleLogin(); } }}/>
          </div>

          <IonItem lines="none">
            <div className="toggle-container">
              <IonLabel className="toggle-label">{getTranslation('rememberMe', userLanguageCode)} </IonLabel>
              <IonToggle
                checked={rememberMe}
                onIonChange={(e) => setRememberMe(e.detail.checked)}
              />
            </div>
          </IonItem>

          <IonButton
            className="ion-margin-top"
            type="button"
            expand="block"
            disabled={loggingIn || username === "" || password === ""}
            border-radius="15px"
            onClick={() => {handleLogin(); return false}}
          >
            {getTranslation('login', userLanguageCode)}
          </IonButton>
        </form>
        <div className='support-link'><IonRouterLink routerLink='/forgot-password'>{getTranslation('forgotUsernamePassowrd', userLanguageCode)}</IonRouterLink></div>
        <div className='support-link'>{getTranslation('needHelp', userLanguageCode)} <a href={supportUrl} rel="noreferrer" target={"_blank"}>{getTranslation('getSupport', userLanguageCode)}</a></div>
      </>}
    </>
  );
};

export default LoginForm;