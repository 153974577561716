import { API, graphqlOperation } from 'aws-amplify';
import { catalogDynamicNew } from '../graphql/queries';
import { getStoredKey, setStoredKey, shouldUseCachedValue } from './storage-service';
import constants from '../constants';
import { getJwt } from "../services/auth-service";

export async function getCatalog(catalogId: string, customer: string, sort: string, tagsQueryString: string, dropped: boolean, tag_facets: boolean, hoist_quantities: boolean, page: number, keyword: string, online: boolean, dontStore: boolean = false) {
    let rangeString  = "items=" + ((page-1)*constants.PRODUCT_RESULTS_PER_PAGE) + "-" + ((constants.PRODUCT_RESULTS_PER_PAGE*page)-1);
    const storageName = constants.CATALOG + catalogId + "_" + sort + "_" + page + "_" + tagsQueryString + "_" + keyword;
    const storedValue = await getStoredKey(storageName);
    
    if(shouldUseCachedValue(storedValue, online, true)){
        await storeCurrentCatalog(storedValue)
        return storedValue;
    } else {
        const clientName = await getStoredKey(constants.CURRENT_CLIENT_NAME) || "callawaygolf";
        const jwt = await getJwt();
        const result: any = await API.graphql(graphqlOperation(catalogDynamicNew, {client: clientName, jwt: jwt, keyword: keyword, catalog: catalogId, customer: customer, sort: sort, tags: tagsQueryString, dropped: dropped, Range: rangeString, tag_facets: tag_facets, hoist_quantities: hoist_quantities}))
        if(result.data.catalogDynamicNew.results){
            for (const item of result.data.catalogDynamicNew.results) {
                item.imageToShow = findItemImage(firstUnfilteredVariation(item.variations), "detail");
                item.priceRangeToShow = await findPriceRange(item);
                item.customizable = isCustomizable(item.variations[0]); 
            }

            result.data.catalogDynamicNew.facets = JSON.parse(result.data.catalogDynamicNew.facets)

            result.data.catalogDynamicNew.totalResults = getTotalResultCount(result.data.catalogDynamicNew.facets)

            await setStoredKey(storageName, result.data.catalogDynamicNew)        

            if(!dontStore) {
                // store so we can retrieve product detail information
                await storeCurrentCatalog(result.data.catalogDynamicNew)
            }
            return result.data.catalogDynamicNew;
        } else {
            return {results: [], facets: {}, totalResults: 0};
        }
    }
}

export async function getFullCatalog(catalogId: string, customer: string, sort: string, tagsQueryString: string, dropped: boolean, tag_facets: boolean, hoist_quantities: boolean, keyword: string, online: boolean) {
    let foundCatalog: any = {results: [], facets: {}, stock_shipments: [], totalResults: 0}

    if(online) {
        let page = 0;
        while(foundCatalog.results.length === constants.PRODUCT_RESULTS_PER_PAGE * page) {
            page++;
            let newResults: any = await getCatalog(catalogId, customer, sort, tagsQueryString, dropped, tag_facets, hoist_quantities, page, keyword, online, true);
            if(newResults.results) {
                foundCatalog.results = foundCatalog.results.concat(newResults.results);
            }

            if(newResults.stock_shipments) {
                foundCatalog.stock_shipments = foundCatalog.stock_shipments.concat(newResults.stock_shipments);
            }

            if(page === 1 && newResults.facets) {
                foundCatalog.facets = newResults.facets;
            }
        }

        await storeCurrentCatalog(foundCatalog)
    }

    return foundCatalog;
}

export async function getProduct(number: string){
    let foundProduct = {};
    let currentResults = await getStoredKey(constants.CURRENT_CATALOG_RESULTS);
    if(currentResults && currentResults.length > 0){
        currentResults.forEach((product: any) => {
            if(product.number === number){
                foundProduct = product;
            }
        });
    }

    return foundProduct;
}

export async function getProductFresh(catalogId: string, customer: string, sort: string, tagsQueryString: string, dropped: boolean, tag_facets: boolean, hoist_quantities: boolean, page: number, product_number: string, online: boolean) {
    return getCatalog(catalogId, customer, sort, tagsQueryString, dropped, tag_facets, hoist_quantities, page, product_number, online);
}

async function storeCurrentCatalog(foundCatalog: any){
    if(foundCatalog.results) {
        // must have been a full catalog store
        if(foundCatalog.results.length > constants.PRODUCT_RESULTS_PER_PAGE){
            await setStoredKey(constants.CURRENT_CATALOG_RESULTS, foundCatalog.results)
            await setStoredKey(constants.CURRENT_CATALOG_SHIPMENTS, foundCatalog.stock_shipments)
        } else {
            let currentResults = await getStoredKey(constants.CURRENT_CATALOG_RESULTS);
            let currentShipments = await getStoredKey(constants.CURRENT_CATALOG_SHIPMENTS);

            if(currentResults && Array.isArray(currentResults)) {
                currentResults = currentResults.concat(foundCatalog.results)
            } else {
                currentResults = foundCatalog.results;
            }

            currentResults = currentResults.concat(foundCatalog.results)
            if(currentShipments && Array.isArray(currentShipments)) {
                currentShipments = combineShipments(foundCatalog.stock_shipments, currentShipments);
            } else {
                currentShipments = foundCatalog.stock_shipments
            }

            await setStoredKey(constants.CURRENT_CATALOG_RESULTS, currentResults);
            await setStoredKey(constants.CURRENT_CATALOG_SHIPMENTS, currentShipments)
        }
    }
}

function shipmentExists(key: string, warehouse_id: string, shipments: any[]) {
    let exists = null;
    shipments.every((shipment: any) => {
        if(key == shipment.key && warehouse_id == shipment.warehouse_id) {
            exists = shipment;
            return false;
        }

        return true;
    });

    return exists;
}

function combineShipments(newShipments: any[], oldShipments: any[]) {
    let combinedShipments: any[] = [];
    oldShipments.forEach((shipment: any) => {
        let exists = shipmentExists(shipment.key, shipment.warehouse_id, newShipments);

        // replace old shipment with fresh data
        if(exists) {
            combinedShipments.push(exists)
        } else {
            combinedShipments.push(shipment)
        }
    });

    newShipments.forEach((shipment: any) => {
        let exists = shipmentExists(shipment.key, shipment.warehouse_id, combinedShipments);

        // add completely new shipment to list (didnt exist in old data)
        if(!exists) {
            combinedShipments.push(shipment)
        }
    });

    return combinedShipments;
}

function getTotalResultCount(facets: any): number {
    let totalResults = 0;

    for (var key in facets) {
        if (key === "Region" && facets[key] && facets[key].terms && facets[key].terms.length > 0) {
            // eslint-disable-next-line
          facets[key].terms.forEach((term: string[]) => {
            if(term[0] === "ALL" || term[0] === "US") {
                totalResults = parseInt(term[1], 10);
            }
          });
        }
    }

    return totalResults;
}

function isCustomizable(variation: any): boolean {
    return variation.can_embellish && variation.embellishment_strategy === "CallawayGolf";
}

function firstUnfilteredVariation(variations: any[]) {
    let theVariation = {};
    variations.every((variation: any) => {
      if(!variation.filtered) {
        theVariation = variation;
        return false;
      }
      return true;
    });

    return theVariation;
  }

export function findItemImage(variation: any, imageType: string): string {
    if (variation.images) {
        if (variation.images.P && variation.images.P.length > 0) {
            return variation.images.P[0][imageType];
        }

        if (variation.images.S && variation.images.S.length > 0) {
            return variation.images.S[0][imageType];
        }
        
        if (variation.images.A && variation.images.A.length > 0) {
            return variation.images.A[0][imageType]; 
        }
    }

    return "assets/missing-image.svg";
}

export async function totalStockAvailble(key: string, warehouse_id: string): Promise<number> {
    let currentShipments = await getStoredKey(constants.CURRENT_CATALOG_SHIPMENTS)
    let currentAvailbile = 0;
    if(currentShipments && currentShipments.length > 0) {
        currentShipments.every((stock_shipment: any) => {
            if(stock_shipment.key == key && stock_shipment.warehouse_id == warehouse_id) {
                currentAvailbile = stock_shipment.quantity;
                return false;
            }

            return true;
        });
    }

    return currentAvailbile;
}

export function formatCustomSpecs(activeEmbellishments: any[]){
    let displaySpecs: any[] = []

    if(activeEmbellishments.length > 0){
        let specs = activeEmbellishments[activeEmbellishments.length - 1].Value.split("***")
        
        specs.forEach((spec: string) => {
        if(spec !== "") {
            let unstring = spec.split(":");
            displaySpecs.push({key: unstring[0].trim(), value: unstring[1].trim()})
        }
        });      
    }

    return displaySpecs;
}

export function generateTagQueryParam(tags: string[]): string {
    let query = "";
    for (let index = 0; index < tags.length; index++) {
      let splitTag = tags[index].split(",")

      if(index == 0) {
        query += "&tags="
      } else {
        query += "|"
      }
      
      query += splitTag[0].replace("[", "").trim() + ":" + splitTag[1].replace("]", "").trim();
    }

    return query;
}

function findPriceTypePriceRange(item: any, priceType: string) {
    if (item.price_range.length === 2) {
        if (item.price_range[0][priceType] !== item.price_range[1][priceType]) {
            let minPrice = "$" + Number(item.price_range[0][priceType]).toFixed(2);
            let maxPrice = "$" + Number(item.price_range[1][priceType]).toFixed(2);

            return minPrice + " - " + maxPrice;
        } else {
            return "$" + Number(item.price_range[0][priceType]).toFixed(2);
        }
    } else if (item.price_range.length === 1) {
        return "$" + Number(item.price_range[0][priceType]).toFixed(2);
    }

    return "";
}

export async function findPriceRange(item: any): Promise<string> {
    let appPriceType = await getStoredKey(constants.APP_PRICE_TYPE);
    if(appPriceType && appPriceType != "" && appPriceType != "none") {
        let priceType = "";
        if(appPriceType == "both") {
            priceType = "elastic_wholesale";
        } else {
            priceType = appPriceType;
        }

        let priceRange = findPriceTypePriceRange(item, priceType);
        if(appPriceType == "both") {
            priceRange += " / " + findPriceTypePriceRange(item, "elastic_retail");
        }

        return priceRange;
    }

    return "";
}