import { IonCol, IonLabel, IonRow } from '@ionic/react';
import { useEffect, useState } from "react";
import { formatCustomSpecs } from "services/catalog-service";

interface ProductCustomTableProps {
  embellishments: any[];
}

const ProductCustomTable: React.FC<ProductCustomTableProps> = (props) => {
  const [displayEmbellishments, setDisplayEmbellishments] = useState<any>([]);

  useEffect(() => {
    setDisplayEmbellishments(formatCustomSpecs(props.embellishments))
  }, [props.embellishments])
  
  return (
    <>
      {displayEmbellishments.map((spec: any, index: any) => {

        return(
          <IonRow key={index} className="ion-padding-end ion-padding-start">
            <IonCol size="6">
              <IonLabel key={index}>
                {spec.key}:
              </IonLabel>
            </IonCol>
            <IonCol size="6">
              <IonLabel key={index}>
                <strong>{spec.value}</strong>
              </IonLabel>
            </IonCol>
          </IonRow>
        );
      })}
    </>
  );
};

export default ProductCustomTable;
