import React, { useEffect, useState } from 'react';
import { getBrandConfig } from '../../../services/auth-service'
import CachedImage from 'components/cached-image/CachedImage';
import constants from '../../../constants';
import { setStoredKey } from '../../../services/storage-service'
import { useConnection } from '../../../services/useConnection';

const ClientImages: React.FC = () => {
  const [brandLogo, setBrandLogo] = useState("");
  const [brandHero, setBrandHero] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const { connected } = useConnection();

  useEffect(() => {

    async function getData() {

        const result: any = await getBrandConfig(constants.CONFIG_TYPE, connected);
        if(result.brandDynamic && result.brandDynamic.config && result.brandDynamic.config.images){
          setBrandLogo(result.brandDynamic.config.images.logo)
          setStoredKey(constants.CLIENT_LOGO, result.brandDynamic.config.images.logo);
          setBrandHero(result.brandDynamic.config.images.mainBackground)
          setDataLoaded(true)
        }
    }

    getData();
  }, []);

  return (
    <>
      {dataLoaded && 
      <>
        <CachedImage className='brand-image' src={brandHero} />
        <CachedImage className="brand-logo" src={brandLogo} />
      </>}
    </>
  );
};

export default ClientImages;