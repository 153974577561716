import { IonContent, IonPage } from "@ionic/react";
import SearchBox from "components/search-box/SearchBox";
import RecommendedSearch from "components/recommended-search/RecommendedSearch";
import { useEffect, useState } from "react";
import { getStoredKey } from "services/storage-service";
import constants from '../../constants';

const Search: React.FC = () => {
  const [showRecommended, setShowRecommended] = useState(false);


  useEffect(() => {
    async function getData() {
      const clientExchangeUser: boolean = await getStoredKey(constants.CLIENT_EXCHANGE_USER);
      setShowRecommended(clientExchangeUser);
    }

    getData();
  }, []);

  return (
    <IonPage className="page-with-menu search-page">
      <IonContent>

        <SearchBox />
        {showRecommended && <RecommendedSearch />}

      </IonContent>
    </IonPage>
  );
};

export default Search;
