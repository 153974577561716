import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonIcon,
  IonInput,
  IonLabel,
  useIonLoading
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { getBrandConfig, sendForgotPasswordEmail, changePassword } from '../../../services/auth-service'
import ClientImages from 'components/login/client-images/ClientImages';
import constants from '../../../constants';
import { useHistory } from 'react-router';
import { useConnection } from '../../../services/useConnection';
import { getTranslation } from 'translations';
import { useSession } from 'services/useSession';

const ForgotPasswordForm: React.FC = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [codeSuccess, setCodeSuccess] = useState(false);
  const [hasCode, setHasCode] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetting, setResetting] = useState(Boolean);
  const [supportUrl, setSupportUrl] = useState(process.env.REACT_APP_DEFAULT_SUPPORT_LINK);

  const [present, dismiss] = useIonLoading();

  const history = useHistory();
  const { connected } = useConnection();
  const { userLanguageCode } = useSession();

  useEffect(() => {

    async function getData() {

        const result: any = await getBrandConfig(constants.CONFIG_TYPE, connected);
        if(result.brandDynamic && result.brandDynamic.config && result.brandDynamic.config.images){
          if(result.brandDynamic.config.flags && result.brandDynamic.config.flags.supportUrl) {
            setSupportUrl(result.brandDynamic.config.flags.supportUrl);
          }
          setDataLoaded(true)
        }
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.code != null) {
      setHasCode(true)
      setCode(params.code)
    }

    getData();
  }, []);

  async function getCode(){
    if(validateEmail()) {
      setError("");
      setResetting(true)
      await present({ message: "Sending code..." });
      let forgotResult: any = await sendForgotPasswordEmail(email);

      if(forgotResult.data && forgotResult.data.forgotPasswordDynamic.content && forgotResult.data.forgotPasswordDynamic.content != "USER_NOT_FOUND")  {
        setCodeSuccess(true)
        setResetting(false)
        await dismiss();
      } else {
        await dismiss();
        setResetting(false)
        alert("That email does not exist, please try again.")
      }
    }
  }

  async function doChangePassword(){
    if(password.length >= 8) {
      setValidPassword(true)
      setError("");
      setResetting(true)
      await present({ message: "Changing password..." });

      let resetResult: any = await changePassword(code, password);

      if(resetResult.data && resetResult.data.resetPasswordDynamic.ok && resetResult.data.resetPasswordDynamic.ok == true) {
        setResetSuccess(true)
        dismiss();
      } else {
        await dismiss();
        setResetting(false)
        if(resetResult.data.resetPasswordDynamic.reset_password_token && resetResult.data.resetPasswordDynamic.reset_password_token.includes("is invalid")){
          alert("Forgot password token is expired, please submit your email address again.")
        } else {
          alert("Something went wrong, please try again.")
        }
      }
    } else {
      setValidPassword(false)
    }
  }

  function validateEmail() {
    let regExp = constants.EMAIL_REGEX;
    let result = regExp.test(email);

    setValidEmail(result);

    return result
  }

  return (
    <>
      {dataLoaded && 
        <>
          <ClientImages />
          <div className="ion-padding">
            <h1 onClick={() => { if(!resetSuccess) { history.goBack()} }}>
            {!codeSuccess && !resetSuccess && !hasCode && <><IonIcon slot="end" icon={chevronBackOutline} color="medium" /> <span>{getTranslation('forgotPassword', userLanguageCode)}</span></>}
            {codeSuccess && !resetSuccess && <> <span>{getTranslation('checkEmail', userLanguageCode)}</span></>}
            {hasCode && !resetSuccess && <> <span>{getTranslation('enterNewPassword', userLanguageCode)}</span></>}
            {resetSuccess && <span>{getTranslation('passwordReset', userLanguageCode)}</span>}
            </h1>
            {!hasCode && !codeSuccess && !resetSuccess && 
              <>
                <div className="input-container">
                  <div className="label-container">
                    <IonLabel class="input-label" position="floating">
                     {getTranslation('email', userLanguageCode)}
                    </IonLabel>
                    <IonLabel class="input-label required-label" position="floating">
                      {getTranslation('required', userLanguageCode)}
                    </IonLabel>
                  </div>
                  <IonInput onIonChange={(e) => setEmail(e.detail.value!)} onKeyPress={(event) => { if(event.key === 'Enter') { getCode(); } }} />
                </div>
                {!validEmail && <p className='error'>{getTranslation('enterVaildEmail', userLanguageCode)}</p>}
                <IonButton
                  className="ion-margin-top"
                  type="button"
                  expand="block"
                  disabled={resetting || email == ""}
                  border-radius="15px"
                  onClick={() => {getCode(); return false}}
                >
                  {getTranslation('nextStep', userLanguageCode)}
                </IonButton>
              </>
            }
            {codeSuccess && !resetSuccess && <div className="inputs">
              <p className='verify-help'>{getTranslation('sentEmailTo', userLanguageCode)} <strong>{email}</strong> {getTranslation('withEmailInstructions', userLanguageCode)}</p>
              <IonButton
                className="ion-margin-top"
                type="button"
                expand="block"
                border-radius="15px"
                onClick={() => {history.replace('/login'); return false}}
              >
                {getTranslation('backToLogin', userLanguageCode)}
              </IonButton>
            </div> 
            }
            {hasCode && !resetSuccess && <div className="inputs">
              <div className="input-container">
                <div className="label-container">
                  <IonLabel class="input-label" position="floating">
                  {getTranslation('newPassword', userLanguageCode)}
                  </IonLabel>
                  <IonLabel class="input-label required-label" position="floating">
                  {getTranslation('required', userLanguageCode)}
                  </IonLabel>
                </div>
                <IonInput type="password" onIonChange={(e) => setPassword(e.detail.value!)} />
              </div>
              <div className="input-container">
                <div className="label-container">
                  <IonLabel class="input-label" position="floating">
                    {getTranslation('repeatPassword', userLanguageCode)}
                  </IonLabel>
                  <IonLabel class="input-label required-label" position="floating">
                    {getTranslation('required', userLanguageCode)}
                  </IonLabel>
                </div>
                <IonInput type="password" onIonChange={(e) => setConfirmPassword(e.detail.value!)} />
              </div>
              {!validPassword && <p className='error'>{getTranslation('passwordReqs', userLanguageCode)}</p>}
              <IonButton
                className="ion-margin-top"
                type="button"
                expand="block"
                disabled={resetting || code == "" || password == "" || password != confirmPassword}
                border-radius="15px"
                onClick={() => {doChangePassword(); return false}}
              >
                {getTranslation('savePassword', userLanguageCode)}
              </IonButton>
            </div> 
            }
            {resetSuccess && 
            <>
              <p className='verify-help'>{getTranslation('tryLoggingIn', userLanguageCode)}</p>
              <IonButton
                className="ion-margin-top"
                type="button"
                expand="block"
                border-radius="15px"
                onClick={() => {history.replace('/login'); return false}}
              >
                {getTranslation('login', userLanguageCode)}
              </IonButton>
            </>}
            {error != "" && <p>{error}</p>}
          </div>          
          <div className='support-link'>{getTranslation('needHelp', userLanguageCode)} <a href={supportUrl} target={"_blank"}>{getTranslation('getSupport', userLanguageCode)}</a></div>
        </>
      }
    </>
  );
};

export default ForgotPasswordForm;
