import { IonContent, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import { getBrandConfig, } from '../../services/auth-service'
import storageNames from '../../constants';
import CachedImage from 'components/cached-image/CachedImage';
import './NoAccess.scss';
import { useConnection } from '../../services/useConnection';
import { getTranslation } from 'translations';
import { useSession } from 'services/useSession';

const NoAccess: React.FC = () => {
  const [brandLogo, setBrandLogo] = useState("");
  const [brandHero, setBrandHero] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);

  const { connected } = useConnection();
  const { userLanguageCode } = useSession();

  useEffect(() => {

    async function getData() {

        const result: any = await getBrandConfig(storageNames.CONFIG_TYPE, connected);
        if(result.brandDynamic && result.brandDynamic.config && result.brandDynamic.config.images){
          setBrandLogo(result.brandDynamic.config.images.logo)
          setBrandHero(result.brandDynamic.config.images.mainBackground)
          setDataLoaded(true)
        }
    }

    getData();
  }, []);

  return (
    <>
    {dataLoaded && 
      <IonPage className='no-access'>
        <IonContent>
          <CachedImage className='brand-image' src={brandHero} />
          <CachedImage className="brand-logo" src={brandLogo} />
          <IonContent class="ion-padding">
            <p>
              {getTranslation('cannotAccess', userLanguageCode)}
              <br /><br />
              {getTranslation('loginDifferentDevice', userLanguageCode)}
            </p>
          </IonContent>
        </IonContent>
      </IonPage> }
    </>
  );
};

export default NoAccess;
