import { getStoredKey, setStoredKey, shouldUseCachedValue } from './storage-service';
import constants from '../constants';
import { getBrandConfig, getJwt } from './auth-service';
import { contactBrandDynamic } from '../graphql/mutations'
import { BrandContactObject } from 'models/BrandTypes';
import { API, graphqlOperation } from 'aws-amplify';
const algoliasearch = require('algoliasearch');

export async function getBrands(online: boolean, page: number = 0, searchCriteria?: any) {
    const key = `${constants.BRAND_LIST}_${page}_${searchCriteria.searchTerm}_${searchCriteria.facets?.join("_")}`
    const storedValue = await getStoredKey(key);
    if (shouldUseCachedValue(storedValue, online, true)) {
        return storedValue;
    } else {
        const brandConfig: any = await getBrandConfig(constants.CONFIG_TYPE, true);
        try {
            if (brandConfig.brandDynamic.config.flags.algoliaConfig) {
                const algoliaCOnfig = brandConfig.brandDynamic.config.flags.algoliaConfig;
                const client = algoliasearch(algoliaCOnfig.applicationId, algoliaCOnfig.searchApiKey);
                const index = client.initIndex(algoliaCOnfig.brandsIndex)

                let result = await index
                    .search(searchCriteria.searchTerm, {
                        page,
                        facets: ['*'],
                        facetFilters: [searchCriteria.facets.map((f: any) => f.replace('|', ':'))]
                    })

                await setStoredKey(key, result)
                return result;
            } else {
                return null;
            }
        } catch {
            return null;
        }
    }
}

export async function getAllBrands(online: boolean, searchQuery: string = "") {
    const storedValue = await getStoredKey(constants.BRAND_LIST + "_" + searchQuery);

    if (shouldUseCachedValue(storedValue, online, true)) {
        return storedValue;
    } else {
        const brandConfig: any = await getBrandConfig(constants.CONFIG_TYPE, true);
        if (brandConfig.brandDynamic.config.flags.algoliaConfig) {
            const algoliaCOnfig = brandConfig.brandDynamic.config.flags.algoliaConfig;
            const client = algoliasearch(algoliaCOnfig.applicationId, algoliaCOnfig.searchApiKey);
            const index = client.initIndex(algoliaCOnfig.brandsIndex);
            let result = await index.search(searchQuery, { page: 0, hitsPerPage: 1000 })

            await setStoredKey(constants.BRAND_LIST + "_" + searchQuery, result)
            return result;
        } else {
            return null;
        }
    }
}

export async function contactBrand(contactFormData: BrandContactObject, online: boolean) {
    let submitBody = {
        first_name: contactFormData.first_name,
        last_name: contactFormData.last_name,
        email: contactFormData.email,
        company_name: contactFormData.company_name,
        phone_number: contactFormData.phone_number,
        about: contactFormData.about,
        interested_products: contactFormData.interested_products,
        brand_name: contactFormData.brand_name,
        brand_to_emails: contactFormData.brand_to_emails,
        address: {
            street1: contactFormData.street1,
            street2: contactFormData.street2,
            city: contactFormData.city,
            state: contactFormData.state,
            zip: contactFormData.zip,
            country: contactFormData.country
        }
    }
    
    if(online) {
        console.log(contactFormData)
        const clientName = await getStoredKey(constants.CURRENT_CLIENT_NAME) || "callawaygolf";
        const jwt = await getJwt();
        const result: any = await API.graphql(graphqlOperation(contactBrandDynamic, {body: submitBody, jwt: jwt, client: clientName}));
        return result.data;
    } else {
        alert("You must have an internet connection to perform this action.")
        return false;
    }
}