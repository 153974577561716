import React from "react";
import {
  IonContent,
  IonPage
} from "@ionic/react";
import ForgotPasswordForm from "components/login/forgot-password-form/ForgotPasswordForm";
const ForgotPassword: React.FC = () => {

  return (
    <IonPage className="login-page">
      <IonContent className="main-content">

        <ForgotPasswordForm />

      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
