import React, { useEffect, useState } from 'react';
import { getStoredKey, setStoredKey, getBase64image } from '../../services/storage-service'
import storageNames from '../../constants';
import { IonImg } from '@ionic/react';

interface CachedImageProps {
  src: string;
  className?: string;
  onClick?: Function;
  onLoad?: (event: any) => void; 
  noblankImage?: boolean
  useImgTag?: boolean
}

const CachedImage: React.FC<CachedImageProps> = (props) => {
  const [imageSrc, setImageSrc] = useState(props.noblankImage ? "" : "assets/blank.png");

  useEffect(() => {
    async function getBase64String() {
      try {
        const storedImage = await getStoredKey(storageNames.CACHED_IMAGE + props.src)
        if (storedImage) {
          setImageSrc(storedImage)
        } else {
          if (await cacheAvailible()) {
            const base64ImageString = await getBase64image(props.src);
            await setStoredKey(storageNames.CACHED_IMAGE + props.src, base64ImageString)
            setImageSrc(base64ImageString as string)
          } else {
            setImageSrc(props.src);
          }
        }
      } catch {
        setImageSrc(props.src);
      }
    }

    getBase64String();
    // eslint-disable-next-line
  }, []);

  // only allow storing of images if there is storage room
  async function cacheAvailible(): Promise<boolean> {
    if (navigator && navigator.storage && navigator.storage.estimate) {
      let quotas: any = await navigator.storage.estimate();
      let reducedQuota = 0;
      let usage = 1;
      if (quotas) {
        reducedQuota = quotas.quota * .8;
        usage = quotas.usage;
        return reducedQuota > usage;
      }
    }

    // if its safari for now just allow it, according to research we will likely not hit a limit here
    // it is around 500-1000MB
    // should probably revisit this though
    return true;
  }

  return (
    <>
    {!props.useImgTag && <IonImg
      className={props.className}
      src={imageSrc}
      onClick={props.onClick as any}
      onIonImgDidLoad={props.onLoad} />
    }
    {props.useImgTag && <img
      className={props.className}
      src={imageSrc}
      onClick={props.onClick as any} />
    }
    </>
  );
};

export default CachedImage;
