import { IonItem, IonCheckbox, IonLabel } from '@ionic/react';
import { useEffect, useState } from 'react';

interface FacetCheckboxProps {
  term: any;
  option: any;
  toggleFacet: Function;
  selectedFacets: any[];
}

const FacetCheckbox: React.FC<FacetCheckboxProps> = (props) => {
  const [selected, setSelected] = useState<boolean>(false);

  function checkIfSelected(){
    let isSelected = false;
    props.selectedFacets.forEach(facet => {
      let splitString = facet.split("|")
      let facetName = splitString[0];
      let term = splitString[1];

      if(props.option.title == facetName && props.term.title == term){
        isSelected = true;
      }
    });

    return isSelected;
  }

  useEffect(() => {
    setSelected(checkIfSelected());
  },[props.selectedFacets]);

  return (
    <IonItem lines="none">
      <IonCheckbox mode='md' onClick={() => {props.toggleFacet({value: props.option.title + "|" + props.term.title, checked: selected})}} color="secondary" checked={selected} value={props.option.title + "|" + props.term.title} />
      <IonLabel className="facet-checkbox">{props.term.title} ({props.term.amount})</IonLabel>
    </IonItem>
  );
};

export default FacetCheckbox;