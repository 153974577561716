import { IonContent, IonPage, useIonAlert, useIonLoading } from '@ionic/react';
import { useState, useEffect } from 'react';
import ExploreSlider from 'components/explore-slider/ExploreSlider';
import ExploreAnnouncements from 'components/explore-announcements/ExploreAnnouncements';
import ExploreLinks from  "components/explore-links/ExploreLinks";
import ExploreBrands from 'components/explore-brands/ExploreBrands';
import { getUserDashboard } from '../../services/dashboard-service'
import { getStoredKey } from '../../services/storage-service'
import constants from '../../constants';
import { useSession } from '../../services/useSession';
import { User } from 'models';
import { getTranslation } from 'translations';
import { getAllBrands } from 'services/brand-service';
import { getBrandConfig } from 'services/auth-service';


const Explore: React.FC = () => {
  const [slides, setSlides] = useState<any[]>([]);
  const [foundUpdate, setFoundUpdate] = useState(false);
  const [showBrands, setShowBrands] = useState(false);
  const [clientName, setClientName] = useState("");
  const [exploreLinks, setExploreLinks] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [exploreBrands, setExploreBrands] = useState([]);
  const [presentAlert] = useIonAlert();
  const [present, dismiss] = useIonLoading();
  const { getUserInfo, userCatalogId, userLanguageCode } = useSession();

  const defaultSlides = [
    {
      image: "/assets/scenic.jpg",
      headline: 'Welcome to the B2B',
      call_to_actions: []
    }
  ]

  // taken from https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
  function shuffleArray(array: any[]) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
  }

  useEffect(() => {
    async function getSlides() {
      const userData: User | undefined = await getUserInfo();
      const brandConfig: any = await getBrandConfig(constants.CONFIG_TYPE, true);
      console.log("brand confing", brandConfig)
      try {
        if(brandConfig.brandDynamic.config.flags.showBrands) {
          setShowBrands(brandConfig.brandDynamic.config.flags.showBrands)
          let brands = await getAllBrands(true);
          brands.hits = brands.hits.filter((hit: any) => { return (hit.name && hit.name != "") && (hit.image || hit.brandTile) });
          if(brands) {
            shuffleArray(brands.hits);
            setExploreBrands(brands.hits)
          }
        }
      } catch {
        
      }
      if (userData && userData.customer) {
        let dashboardData = await getUserDashboard(userData.customer.number!, true);
        console.log("dashboard data", dashboardData); 
        if(dashboardData.data && dashboardData.data.exploreDynamic && dashboardData.data.exploreDynamic.dashboard) {
          if(dashboardData.data.exploreDynamic.dashboard.marquee) {
            if(dashboardData.data.exploreDynamic.dashboard.marquee.items.length > 0) {
              await setSlides(dashboardData.data.exploreDynamic.dashboard.marquee.items);
            } else {
              await setSlides(defaultSlides);
            }
          } else {
            await setSlides(defaultSlides);
          }

          if(dashboardData.data.exploreDynamic.dashboard.links_list) {
            await setExploreLinks(dashboardData.data.exploreDynamic.dashboard.links_list.links);
          }

          if(dashboardData.data.exploreDynamic.notifications) {
            await setAnnouncements(dashboardData.data.exploreDynamic.notifications);
          }
        } else {
          await setSlides(defaultSlides);
        }
      }

      let theClientName = await getStoredKey(constants.CLIENT_NAME);

      if(theClientName){
        await setClientName(theClientName);
      }
    }

    getSlides();

    // check for app update every 5 minutes, unless its installing
    let check = localStorage.getItem('APP_UPDATE');
    setInterval(checkForAppUpdate, check == "installing" ? 3000 : 300000);

    // also fire it immeditatly on page load once
    checkForAppUpdate();
  }, []);

  async function checkForAppUpdate(){
    let check = localStorage.getItem('APP_UPDATE');
    if(!foundUpdate) {
      if(check && check == 'ready') {
        await setFoundUpdate(true);
        presentAlert({
          header: getTranslation('updateAvailible', userLanguageCode),
          message: getTranslation('updateAvailibleText', userLanguageCode),
          buttons: [
            {
              text: getTranslation('cancel', userLanguageCode),
              role: 'cancel'
            },
            {
              text: getTranslation('ok', userLanguageCode),
              role: 'confirm',
              handler: () => { 
                localStorage.setItem('APP_UPDATE', 'installing');
                setTimeout(() => {
                  window.location.reload(); 
                }, 1000);
              }
            }
          ]
        })
      } else if(check && check == "installing"){
        await dismiss();
        present({ message: getTranslation('installing', userLanguageCode)})
        await setFoundUpdate(false);
      } else if(check && check == "installed") {
        await setFoundUpdate(true);
        localStorage.setItem('APP_UPDATE', 'fully_installed');
        window.location.reload(); 
      } else if(check && check == "fully_installed") {
        localStorage.removeItem('APP_UPDATE');
        presentAlert({
          header: getTranslation('installed', userLanguageCode),
          message: getTranslation('latestInstall', userLanguageCode),
          buttons: ['OK']
        })
      }
    }
  }

  return (
    <IonPage className='page-with-menu'>
      <IonContent>
        {slides.length > 0 && <ExploreSlider slides={slides} /> }
        {announcements.length > 0 && <ExploreAnnouncements announcements={announcements} /> }
        {showBrands && exploreBrands.length > 0 && <ExploreBrands brandList={exploreBrands} />}
        {exploreLinks.length > 0 && <ExploreLinks links={exploreLinks} clientName={clientName} /> }
      </IonContent>
    </IonPage>
  );
};

export default Explore;
