import { IonPage, useIonViewWillEnter } from '@ionic/react';
import ProductList from 'components/product-listing/ProductListing';
import { useEffect, useState } from 'react';
import { getBrandConfig } from 'services/auth-service';
import { useConnection } from 'services/useConnection';
import constants from "../../constants";

const ProductListing: React.FC = () => {
  const [sortOptions, setSortOptions] = useState<any[]>(constants.CATALOG_SORT_OPTIONS);
  const [initialSortOption, setInitialSortOption] = useState<any>(null);
  const [preFilters, setPreFilters] = useState<any | null>(null);

  const { connected } = useConnection();  

  useEffect(() => {
    console.log("running data")
    async function getData() {
      if(!initialSortOption) {
        const brandConfig: any = await getBrandConfig(constants.CONFIG_TYPE, connected);
        if(brandConfig.brandDynamic && brandConfig.brandDynamic.config && brandConfig.brandDynamic.config.flags) {
          if(brandConfig.brandDynamic.config.flags.hideCatalogPositionOption) {
            setSortOptions(constants.CATALOG_SORT_OPTIONS.slice(1, constants.CATALOG_SORT_OPTIONS.length));
            setInitialSortOption({
              name: "Price",
              id: "price"
            })
          } else {
            setInitialSortOption({
              name: "Catalog",
              id: "catalog"
            })
          }
        }
      }
    }

    getData();
  },[preFilters]);

  // check each time we enter this view for a pre-search
  useIonViewWillEnter(async () => {
    setInitialSortOption(null)
    let preSearch = "";
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.query != null) {
      preSearch = params.query;
    }
    if(params.tags != null) {
      let rawTags = params.tags.split("|");
      let tagArray: string[] = []

      rawTags.forEach((tag: string) => {
        let splitTag = tag.replace(":", "|")
        tagArray.push(splitTag);
      });

      setPreFilters({filters: tagArray, search: preSearch});
    } else {
      setPreFilters({filters: [], search: preSearch})
    }
  });

  return (
    <IonPage className='page-with-menu'>
      {initialSortOption && preFilters && <ProductList preFilters={preFilters} initialSort={initialSortOption} sortOptions={sortOptions} /> }
    </IonPage>
  );
};

export default ProductListing;
