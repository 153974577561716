import { useState, useEffect } from "react";
import "./ExploreSliderTimer.scss";

interface ExploreSliderTimerProps {
  slideIndex: number;
  timeBetweenSlides: number;
}

const ExploreSliderTimer: React.FC<ExploreSliderTimerProps> = (props) => {
  const [slideTimerWidth, setSlideTimerWidth] = useState(0);
  const [slideTimerValue, setSliderTimerValue] = useState(0);
  const timeInterval = 50;

  useEffect(() => {
    const timer = setTimeout(() => {
      setSliderTimerValue(slideTimerValue+timeInterval);
      setSlideTimerWidth(calculateTimerWIdth());
    }, timeInterval);
    
    return () => clearTimeout(timer);
  }, [slideTimerValue]);

  useEffect(() => {
    setSlideTimerWidth(0);
    setSliderTimerValue(0);
  }, [props.slideIndex]);

  function calculateTimerWIdth(){
    let value = ((slideTimerValue/props.timeBetweenSlides) * 100);
    return (value < 100 ? value : 100);
  }

  return (
    <div className="line">
      <div className="inner">
        <div
          className="over-inner"
          style={{ width: slideTimerWidth + "%" }}
        ></div>
      </div>
    </div>
  );
};

export default ExploreSliderTimer;
