import { IonCol, IonGrid, IonItem, IonRow } from '@ionic/react';
import { useCart } from 'services/useCart';
import { useSession } from 'services/useSession';
import { getTranslation } from "translations";

interface ProductTitleProps {
  product: any;
}

const ProductTitle: React.FC<ProductTitleProps> = (props) => {
  const { appPriceType } = useCart();
  const { userLanguageCode } = useSession();
  console.log("price title", appPriceType)
  const prices = props.product.priceRangeToShow ? props.product.priceRangeToShow.split("/") : [""];
  const hasTwoPrices = prices.length > 1;
  const firstPrice = prices[0]
  const secondPrice = hasTwoPrices ? prices[1] : "";

  return (
    <div className="product-title-area">
      <IonItem lines="none">
      <div className="product-title">
          {" "}
          <div className="ion-text-wrap">{props.product.name} </div>
        </div>
      </IonItem>
      <div className="item-details">
        <IonGrid>
          <IonRow>
            <IonCol size='4'>
             <p className='product-number'>{props.product.number}</p>
            </IonCol>
            {appPriceType != "none" && 
              <IonCol size='8'>
              <p className='product-price'><span>{getTranslation('price', userLanguageCode)}</span><strong>{firstPrice}</strong>{secondPrice != "" && <strong>{getTranslation('msrp', userLanguageCode)} {secondPrice}</strong>}</p>
              </IonCol>
            }
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default ProductTitle;
