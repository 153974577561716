import { IonItem, IonLabel, IonText, IonIcon, IonBadge } from '@ionic/react';
import { chevronForwardOutline } from  'ionicons/icons';
import { ReadOnlyOrder } from 'models';
import { getBadgeType, getBadgeText } from 'services/order-service';
import './OrderItem.scss';
import moment from "moment"
import { getTranslation } from 'translations';
import { useSession } from 'services/useSession';
import { useCart } from 'services/useCart';

interface OrderItemProps {
  order: ReadOnlyOrder;
  openOrder: Function;
}

const OrderItem: React.FC<OrderItemProps> = (props) => {
  const { userLanguageCode } = useSession();
  const { appPriceType } = useCart();
  let priceToShow = appPriceType == "elastic_retail" ? parseFloat(props.order.total_prices.elastic_retail).toFixed(2).toString() : parseFloat(props.order.total_prices.elastic_wholesale).toFixed(2).toString();
  if(appPriceType == "both") {
    priceToShow += " / $" + parseFloat(props.order.total_prices.elastic_retail).toFixed(2).toString();
  }

  return (
    <>
        {props.order.state == "final_submission" && 
          <div className='order-item'>
            <IonItem lines='none' routerLink={"/manage/orders/order-detail/" + props.order._id} className={'order'} >
              <IonLabel>
                <h3>{props.order.name}</h3>
                <p>{getTranslation('order', userLanguageCode)} #{props.order.number}</p>
              </IonLabel>
              <IonText slot="end">
                <p>{props.order.total_quantity} {getTranslation('items', userLanguageCode)}</p>
                <p className='price'>${priceToShow}</p>
              </IonText>
              <IonIcon slot="end" icon={chevronForwardOutline} color="medium" />
            </IonItem>
            <IonText><IonBadge color={getBadgeType(props.order.state)}>{getBadgeText(props.order.state)}</IonBadge> <span>{getTranslation('lasSaved', userLanguageCode)} { moment(props.order.updated_at).format('MM/DD/YYYY')}</span></IonText>
          </div>  
        }
        {props.order.state != "final_submission" && 
        <div className='order-item'>
          <IonItem lines='none' className={'order'} onClick={() => { props.openOrder(props.order) }} >
            <IonLabel>
              <h3>{props.order.name}</h3>
              <p>{getTranslation('order', userLanguageCode)} #{props.order.number}</p>
            </IonLabel>
            <IonText slot="end">
              <p>{props.order.total_quantity} {getTranslation('items', userLanguageCode)}</p>
              <p className='price'>${priceToShow}</p>
            </IonText>
            <IonIcon slot="end" icon={chevronForwardOutline} color="medium" />            
          </IonItem>

          <IonText><IonBadge color={getBadgeType(props.order.state)}>{getBadgeText(props.order.state)}</IonBadge> <span>{getTranslation('lasSaved', userLanguageCode)} { moment(props.order.updated_at).format('MM/DD/YYYY')}</span></IonText>
        </div>
        }
    </>
  );
};

export default OrderItem;
