import {
  IonContent,
  IonButton,
  IonIcon,
  useIonLoading,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  useIonViewWillEnter,
  useIonViewWillLeave
} from "@ionic/react";
import { useState, useEffect, useRef } from "react";
import constants from "../../constants";
import { getStoredKey } from "../../services/storage-service";
import { findPriceRange, getProduct, getProductFresh, totalStockAvailble } from "services/catalog-service";
import ProductTitle from "./product-title/ProductTitle";
import ProductSlides from "./product-slides/ProductSlides";
import ProductColorMenu from "./product-color-menu/ProductColorMenu";
import ProductCustomizeMenu from "./product-customize-menu/ProductCustomizeMenu";
import ProductSizeMenu from "./product-size-menu/ProductSizeMenu";
import { arrowForwardOutline, closeOutline } from "ionicons/icons";
import "./ProductDetailDisplay.scss";
import { cloneDeep } from "lodash";
import { useCart } from "services/useCart";
import { useToaster } from "services/useToast";
import { CartNotification, UpdateNotification } from "../header/notification/NotificationTypes"
import Breadcrumbs from 'components/header/breadcrumbs/Breadcrumbs';
import { useSession } from "services/useSession";
import { getTranslation } from "translations";
import useMessage from "useMessage";

const ProductDetailDisplay: React.FC = () => {
  const [product, setProduct] = useState<any>({});
  const [iframeUrl, setIframeUrl] = useState<string>("");
  const [cacheBust, setCacheBust] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [selectedColor, setSelectedColor] = useState<any>({});
  const [selectedGroup, setSelectedGroup] = useState<any>("");
  const [showIframe, setShowIframe]= useState<boolean>(false);
  const [totalSelectedItemCost, setTotalSelectedItemCost] = useState<string>("$0.00");
  const [totalSelectedItems, setTotalSelectedItems] = useState<number>(0);
  const [isACartUpdate, setIsACartUpdate] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [hasPendingItems, setHasPendingItems] = useState<boolean>(false);
  const [pendingQuantities, setPendingQuantities] = useState<any>([]);
  const [groups, setGroups] = useState<any>([]);
  const [activeEmbellishments, setActiveEmbellishments] = useState<any>([]);

  const viewRef = useRef(null)
  const orderSectionRef = useRef<null | HTMLDivElement>(null);
  const customizedItemDetail = useRef<null | HTMLDivElement>(null);
  const modal = useRef<HTMLIonModalElement>(null);
  const addToCartButton = useRef<HTMLIonButtonElement>(null)
  const productSlider: any = useRef(null);

  const { userCatalogId, userLanguageCode, getUserInfo, isExchangeUser } = useSession();

  const { addByProductIDAndCode, stockQuantityAlreadyInCart, anyCurrentEmbellishments, appPriceType } = useCart();
  const { addNotification } = useToaster();
  const [present, dismiss] = useIonLoading();

  useIonViewWillEnter(() => {
    setCacheBust((oldNum) => {
      return (oldNum + 1)
    })
  })

  useIonViewWillLeave(() => {
    setLoadingData(true);
  })

  useEffect(() => {
    const pathnames = window.location.pathname.split("/");
    let number = pathnames[pathnames.length - 1];
    let variationCode = "";
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.variationCode != null) {
      variationCode = params.variationCode;
    }

    getProductData(number, variationCode);
  }, [cacheBust])

  useEffect(() => {
    async function getGroupsData() {
      let tempGroups: any = [];
      if(selectedColor.stock_items) {
        for (const stock of selectedColor.stock_items) {
          let groupExists = false;
          for (const group of tempGroups) {
            if(group.name == stock.name) {
              groupExists = true;
            }
          }
          if(!groupExists && stock.alt_name && stock.alt_name != "" && stock.alt_name != ".") {
            tempGroups.push({name: stock.name, hide: false});
          }
        }
      }
  
      tempGroups = await filterOutNoStockGroups(tempGroups, selectedColor);
      
      if(tempGroups.length > 1) {
        setGroups(tempGroups);
        setSelectedGroup("")
      } else if(tempGroups.length <= 1) {
        setSelectedGroup("ALL")
      }
  
      setGroups(tempGroups)
    }

    getGroupsData();
  }, [selectedColor])

  useEffect(() => {
    let price = calculateTotalSelectedStockPrice(pendingQuantities);
    setTotalSelectedItemCost("$" + price);
    setHasPendingItems((price != 0))

    setTotalSelectedItems(calculateTotalSelectedItems(pendingQuantities))

    // for custom items
    if(!loadingData && pendingQuantities.length > 0 && pendingQuantities[0].embellishments && pendingQuantities[0].stock_items[0].quantity == 1) {
      addToCart();
    }
  }, [pendingQuantities]);

  useMessage('message', (send: any, payload: any) => {

    async function addCustomProductToCart(jsonString: string) {
      await setShowIframe(false)
      await addCustomProduct(JSON.parse(jsonString));
      await setActiveEmbellishments(() => { 
        return JSON.parse(jsonString)
      });
    }

    if (typeof payload == "string" && (payload as string).startsWith("[{")) {
      addCustomProductToCart(payload);
    }
  })

  async function filterOutNoStockGroups (currentGroups: any[], variation: any) {
    let correctedGroups: any[] = [];
    for (const group of currentGroups) {
      let hasStockItems = false;
      for (const stock_item of variation.stock_items) {
        if(stock_item.name == group.name) {
          let quantity_availible = await totalStockAvailble(stock_item.key, variation.primary_warehouse)
          if(quantity_availible > 0) {
            hasStockItems = true;
          }
        }
      }

      correctedGroups.push({name: group.name, hide: !hasStockItems})
    }

    return correctedGroups;
  }

  async function getProductData(number: string, variationCode: string) {
    await setIsACartUpdate(false);
    await setCurrentIndex(0);
    await setLoadingData(true);
    await present({ message: "Loading product..." });
    await setGroups([]);
    await setSelectedGroup("")
    await setActiveEmbellishments([]);
    let productData: any = await getProduct(number);
    let userData: any = await getUserInfo();
    if(productData.number) {
      let freshProduct: any = await getProductFresh(productData.catalog_key, userData.customer_number, "price", "", false, true, true, 1, number, true);
      if(freshProduct.results && freshProduct.results.length === 1) {
        productData = freshProduct.results[0];
      }
      let allowedVariations: any[] = [];
      productData.variations.forEach((variation: any) => {
        if(!variation.filtered) {
          allowedVariations.push(variation)
        }
      });
      productData.variations = allowedVariations;
      productData.priceRangeToShow = await findPriceRange(productData);
      await setProduct(productData);
      console.log("PRODUCT DETAIL", productData) 

      let tempPendingQuantities: any[] = [];
      let tempActiveEmbellishments: any[] = [];
      for (const variation of productData.variations) {
        let variationStock: any[] = [];
        let anyActiveEmbellishments = anyCurrentEmbellishments(productData.number, variation.code);
        for (const stock of variation.stock_items) {
          variationStock.push({quantity_availible: await totalStockAvailble(stock.key, variation.primary_warehouse), key: stock.key, warehouseCode: variation.primary_warehouse, price: stock.prices[appPriceType == "both" ? "elastic_wholesale" : appPriceType], quantity: stockQuantityAlreadyInCart(productData.number, variation.code, stock.key)})
        }

        tempPendingQuantities.push({variationCode: variation.code, stock_items: variationStock, embellishments: anyActiveEmbellishments})

        if(anyActiveEmbellishments && anyActiveEmbellishments.data){
          tempActiveEmbellishments = anyActiveEmbellishments.data;        
        }
      }

      if(productData.variations && productData.variations.length > 0) {
        let variationIndex = 0;
        if(variationCode != "") {
          variationIndex = getVariationIndex(productData.variations, variationCode)
        }
        await setSelectedColor(productData.variations[variationIndex])
        await setCurrentIndex(variationIndex)
      }

      await setPendingQuantities([...tempPendingQuantities])
      if(calculateTotalSelectedStockPrice(tempPendingQuantities) > 0) {
        await setIsACartUpdate(true);
      }

      if(tempActiveEmbellishments.length > 0){
        await setActiveEmbellishments(tempActiveEmbellishments)
      }

      let callawayIframeUrl = await getStoredKey(constants.CALLAWAY_IFRAME_URL);
      if (callawayIframeUrl && callawayIframeUrl != "" && productData.customizable) {
        let fullUrl = callawayIframeUrl.replace("%{productNumber}", productData.number);
        await setIframeUrl(fullUrl);
      }

    }   
    setLoadingData(false);
    await dismiss();
    if(variationCode != "") {
      setTimeout(() => {
        if(!productData.customizable) {
          if(orderSectionRef && orderSectionRef.current) {
            orderSectionRef.current?.scrollIntoView({behavior: 'smooth'});
          }
        } else {
          if(customizedItemDetail && customizedItemDetail.current) {
            customizedItemDetail.current?.scrollIntoView({behavior: 'smooth'});
          }
        }
      }, 200);
    }
  }

  function getVariationIndex(variations: any, code: string): number {
    let variationIndex = 0;
    variations.forEach((variation: any, index: number) => {
      if(variation.code == code) {
        variationIndex = index;
      }
    });

    return variationIndex;
  }

  async function updateStockQuantity(event: any, stockIndex: any) {
    let value = event.detail.value?.replace(/[^0-9]/g, '');
    
    if (parseInt(value, 10) < 0) {
      value = 0;
    }

    await setPendingQuantities((previousQuantities: any[]) => {
      previousQuantities[currentIndex].stock_items[stockIndex].quantity = value;
      return [...previousQuantities];
    });
  }

  async function addCustomProduct(data: any) {
    let tempPendingQuantities = cloneDeep(pendingQuantities);

    tempPendingQuantities[0].embellishments = {
      data: data,
      type: "CallawayGolf"
    }
    tempPendingQuantities[0].stock_items[0].quantity = 1;
    await setPendingQuantities(tempPendingQuantities);
  }

  function calculateTotalSelectedStockPrice(currentPendingQuantities: any) {
    let totalPrice: any = 0;
    currentPendingQuantities.forEach((variation: any) => {
      variation.stock_items.forEach((stock: any) => {
        let quantity = parseInt(stock.quantity, 10);
        if (quantity > 0) {
          totalPrice += quantity * parseFloat(stock.price);
        }
      });
    });

    totalPrice = totalPrice.toFixed(2);

    return totalPrice;
  }

  function calculateTotalSelectedItems(currentPendingQuantities: any) {
    let totalItems: number = 0;
    currentPendingQuantities.forEach((variation: any) => {
      variation.stock_items.forEach((stock: any) => {
        let quantity = parseInt(stock.quantity, 10);
        if (quantity > 0) {
          totalItems += quantity
        }
      });
    });

    return totalItems;
  }

  async function showCustomizer(){
    await setShowIframe(true); 
    await present({ message: "Loading configurator..." });
  }

  async function addToCart() {
    let totalAdded = 0;
    for (let i = 0; i < pendingQuantities.length; i++) {
      let pendingVariation = pendingQuantities[i];
      let variation = product.variations[i];
      for (let j = 0; j < variation.stock_items.length; j++) {
        let pendingStock = pendingVariation.stock_items[j];
        let stock = variation.stock_items[j];
        pendingStock.prices = stock.prices;
        let stockQuantity = parseInt(pendingStock.quantity, 10);
        if (stockQuantity >= 1) {
          await addByProductIDAndCode(product, variation, pendingStock, stockQuantity, (pendingVariation.embellishments ? pendingVariation.embellishments : null));
          totalAdded += stockQuantity;
        } else if(stockQuantity < 0) {
          await addByProductIDAndCode(product, variation, pendingStock, 0, (pendingVariation.embellishments ? pendingVariation.embellishments : null));
        }
      }
    }

    if (totalAdded > 0) {
      if(!isACartUpdate) {
        await addNotification(CartNotification(totalAdded));
        await setIsACartUpdate(true);
      } else {
        await addNotification(UpdateNotification(userCatalogId));
      }
    }
  }

  // SQ made me do this
  function customizeButtonText() {
    return isACartUpdate ? (product.customizable ? getTranslation('editCustomization', userLanguageCode) :  getTranslation('updateCart', userLanguageCode)) :  getTranslation('addToCart', userLanguageCode)
  }

  // SQ made me do this
  function customizeButtonTextOther () {
    return isACartUpdate ? getTranslation('editCustomization', userLanguageCode) : getTranslation('customizeClub', userLanguageCode)
  }

  return (
    <>
      <div className={"product-detail " + (!loadingData ? "reveal" : "")}>
        {product.name && !loadingData  && <Breadcrumbs pageName={product.name} skipLast={true} /> }
        <ProductTitle product={product} />
          {!loadingData && product.variations && pendingQuantities.length > 0 && 
            <ProductSlides innerRef={productSlider} product={product} {...{ selectedColor, currentIndex}} />
          }
          
          {product.customizable && iframeUrl != "" && (
            <div ref={customizedItemDetail} >
              <ProductCustomizeMenu embellishments={activeEmbellishments} showCustomizer={showCustomizer} />
            </div>
          )}
          {!product.customizable && !loadingData && product.variations &&
            <>
              {!isExchangeUser && 
                <div className="order-title">
                  <h3>{getTranslation('order', userLanguageCode)}</h3>
                </div>
              }
              {product.variations.length > 1 &&
                <ProductColorMenu innerRef={productSlider} product={product} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} selectedColor={selectedColor} setSelectedColor={setSelectedColor} />
              }
              {pendingQuantities.length > 0 && !isExchangeUser &&
                <div ref={orderSectionRef} className="input-menu">
                  <ProductSizeMenu updateStockQuantity={updateStockQuantity} product={product} currentIndex={currentIndex} pendingQuantities={pendingQuantities} groups={groups} selectedGroup={selectedGroup} selectGroup={setSelectedGroup} /> 
                </div>
              }
            </>
          }
          {product.description && 
            <div className="product-description">
              <div className="product-description-title">{getTranslation('productDetails', userLanguageCode)}</div> 
              {product.description}
            </div>
          }
          {!isExchangeUser && 
            <div className="purchase-container">
              {totalSelectedItems > 0 && 
                <p className="total-price">
                  <span>{getTranslation('total', userLanguageCode)}</span> 
                  <strong>{totalSelectedItemCost}</strong>
                </p>
              }
              {totalSelectedItems == 0 && 
                <p className="total-price">
                  <span>{getTranslation('price', userLanguageCode)}</span> 
                  <strong>{product.priceRangeToShow && product.priceRangeToShow.split("/")[0]}</strong>
                </p>
              }
              {totalSelectedItems != 0 && 
                <p className="total-price">
                  <span>{getTranslation('qty', userLanguageCode)}</span> 
                  <strong>{totalSelectedItems}</strong>
                </p>
              }

                {/* // this is needed to be hidden as a hack for the iFrame stuff when on an iFrame product */}
                <IonButton disabled={!hasPendingItems && !isACartUpdate} ref={addToCartButton} onClick={() => addToCart()} fill="solid" color="secondary" class="add-cart-btn" className={product.customizable ? "hidden" : ""}>
                  {customizeButtonText()}
                </IonButton>
                {product.customizable && 
                  <IonButton onClick={() => showCustomizer()} fill="solid" color="secondary" class="add-cart-btn">
                    <>{customizeButtonTextOther()} <IonIcon color="medium" slot="end" icon={arrowForwardOutline}></IonIcon></>
                  </IonButton>
                }
            </div>
          }
      </div>
      <IonModal isOpen={showIframe} ref={modal}>
        <IonHeader className="no-border-toolbar">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => { modal.current?.dismiss(); setShowIframe(false); }}><IonIcon class="icon" size="large" icon={closeOutline} color="primary" /></IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {showIframe && 
            <div className='iframe-wrapper' ref={viewRef} id="iframe-id">
              <iframe src={iframeUrl} onLoad={() => { dismiss(); }}></iframe>
            </div>
          }
        </IonContent>
      </IonModal>
    </>
  );
};

export default ProductDetailDisplay;
