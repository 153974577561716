import { IonContent, IonPage, IonTitle} from '@ionic/react';

const UserPreferences: React.FC = () => {

  return (
    <IonPage className='page-with-menu'>
      <IonContent>
        <IonTitle size="large">UserPreferences</IonTitle>
      </IonContent>
    </IonPage>
  );
};

export default UserPreferences;

