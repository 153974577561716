import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonModal, IonRouterLink, IonToolbar } from '@ionic/react';
import { checkmarkOutline, closeOutline } from  'ionicons/icons';
import { MouseEventHandler, RefObject } from 'react';
import { useSession } from 'services/useSession';
import { getTranslation } from 'translations';

interface SortFiltersProps {
  modalRef: RefObject<HTMLIonModalElement>;
  handleSortClose: MouseEventHandler;
  sortOptions: any[];
  selectSort: Function;
  selectedSortOption: any;
}

const SortFilters: React.FC<SortFiltersProps> = (props) => {
  const { userLanguageCode } = useSession();

  // this sets the hiehgt of the modal properly....
  let multiplier = (47.2 / window.innerHeight)
  let breakpoint = multiplier * (props.sortOptions.length + 1.5);

  if(breakpoint > 1) {
    breakpoint = 1;
  }

  return (
    <IonModal className='pwa-modal sort' ref={props.modalRef} initialBreakpoint={breakpoint} mode="ios">
      <IonHeader className="no-border-toolbar">
        <IonToolbar mode='ios'>
          <h2>{getTranslation('sortBy', userLanguageCode)}</h2>
          <IonButtons slot="end">
            <IonButton onClick={props.handleSortClose} ><IonIcon class="icon" size="large" icon={closeOutline} color="primary" /></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='choices'>
          {props.sortOptions && props.sortOptions.map((option: any, index: number) => {
            return (
              <IonItem lines='none' key={index} onClick={() => {props.selectSort(option)}}>
                <IonRouterLink slot='start' className={option.id == props.selectedSortOption.id ? "selected" : ""}>{option.name}</IonRouterLink>
                {option.id == props.selectedSortOption.id && <IonIcon class="icon" size='large' icon={checkmarkOutline} color="primary" slot='end' />}
              </IonItem>
            );
          })}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default SortFilters;