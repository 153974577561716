/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const saveOrderDynamic = /* GraphQL */ `
  mutation SaveOrderDynamic(
    $cookie: String
    $body: ElasticOrderInput
    $jwt: String
    $client: String
  ) {
    saveOrderDynamic(cookie: $cookie, body: $body, jwt: $jwt, client: $client) {
      _id
      number
      state
      net_price
      products {
        number
        name
        _id
        position
        description
        minimum_purchase
        region
        catalog_key
        primary_warehouse
        price_range {
          wBASEUSD
          rBASEUSD
          elastic_wholesale
          elastic_retail
        }
        variations {
          _id
          name
          code
          permissions
          embellishment_strategy
          can_embellish
          is_dropped
          stock_items {
            number
            name
            key
            upc
            sku
            position
            sold_in_multiples_of
            prices {
              wBASEUSD
              rBASEUSD
              elastic_wholesale
              elastic_retail
            }
            alt_name
          }
          images {
            A {
              icon
              detail
              large
              print
              original
            }
            P {
              icon
              detail
              large
              print
              original
            }
            S {
              icon
              detail
              large
              print
              original
            }
          }
          primary_warehouse
          filtered
        }
      }
    }
  }
`;
export const updateOrderDynamic = /* GraphQL */ `
  mutation UpdateOrderDynamic(
    $cookie: String
    $body: ElasticOrderInput
    $id: String
    $jwt: String
    $client: String
  ) {
    updateOrderDynamic(
      cookie: $cookie
      body: $body
      id: $id
      jwt: $jwt
      client: $client
    ) {
      _id
      number
      state
      net_price
      products {
        number
        name
        _id
        position
        description
        minimum_purchase
        region
        catalog_key
        primary_warehouse
        price_range {
          wBASEUSD
          rBASEUSD
          elastic_wholesale
          elastic_retail
        }
        variations {
          _id
          name
          code
          permissions
          embellishment_strategy
          can_embellish
          is_dropped
          stock_items {
            number
            name
            key
            upc
            sku
            position
            sold_in_multiples_of
            prices {
              wBASEUSD
              rBASEUSD
              elastic_wholesale
              elastic_retail
            }
            alt_name
          }
          images {
            A {
              icon
              detail
              large
              print
              original
            }
            P {
              icon
              detail
              large
              print
              original
            }
            S {
              icon
              detail
              large
              print
              original
            }
          }
          primary_warehouse
          filtered
        }
      }
    }
  }
`;
export const contactBrandDynamic = /* GraphQL */ `
  mutation ContactBrandDynamic(
    $body: ContactBrandInput
    $jwt: String
    $client: String
  ) {
    contactBrandDynamic(body: $body, jwt: $jwt, client: $client) {
      first_name
      last_name
      email
      company_name
      phone_number
      about
      interested_products
      brand_name
      brand_to_emails
      address {
        city
        country
        state
        street1
        street2
        street3
        zip
      }
    }
  }
`;
