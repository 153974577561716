import { IonIcon, IonItem, IonInput } from "@ionic/react";
import { useRef} from "react";
import { search } from "ionicons/icons";
import { useHistory } from "react-router";
import { useSession } from "services/useSession";
import "./SearchBox.scss";
import { getTranslation } from "translations";

const SearchBox: React.FC = () => {

  const history = useHistory();
  const searchText = useRef("");
  
  const { userCatalogId, userLanguageCode } = useSession();

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      redirectUrl(searchText.current);
    }

    return false;
  };
  
  const redirectUrl = (searchTerm: any) => {
    history.replace("/explore/catalogs?catalogId=" + userCatalogId + "&query=" + searchTerm);
  };

  return (
    <div className="search-field">
      <IonItem>
        <IonInput placeholder={getTranslation('search', userLanguageCode)} onKeyUp={(event) => handleKeyPress(event)} onIonChange={(e) => (searchText.current = e.detail.value!)}></IonInput>
      </IonItem>
      <IonIcon onClick={() => redirectUrl(searchText.current)} icon={search} />
    </div>
  );
};

export default SearchBox;
