import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonText,
  IonTitle,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  useIonLoading,
  IonRouterLink,
} from "@ionic/react";
import { useRef, useState, useEffect } from "react";
import { chevronDownOutline, optionsOutline } from "ionicons/icons";
import { useSession } from "../../services/useSession";
import { getCatalog } from "../../services/catalog-service";
import ProductItem from "./product-item/ProductItem";
import Breadcrumbs from 'components/header/breadcrumbs/Breadcrumbs';
import "./ProductListing.scss";
import constants from "../../constants";
import SortFilters from "./sort-filters/SortFilters";
import FacetFilters from "./facet-filters/FacetFilters";
import { useConnection } from "../../services/useConnection";
import { useHistory } from "react-router";
import { getTranslation } from "translations";
import EmptyList from "components/empty-list/EmptyList";

interface ProductListingProps {
  sortOptions: any[];
  initialSort: any;
  preFilters: any;
}

const ProductListing: React.FC<ProductListingProps> = (props) => {
  const [catalogPage, setCatalogPage] = useState<number>(1);
  const [totalResultCount, setTotalResultCount] = useState<number>(0);
  const [catalogName, setCatalogName] = useState<string>("");
  const [resultList, setResultList] = useState<any[]>([]);
  const [previousSearch, setPreviousSearch] = useState<string>("");
  const [selectedFacets, setSelectedFacets] = useState<any[]>(props.preFilters.filters);
  const [availibleFacets, setAvailibleFacets] = useState<any>([]);
  const [searchCriteria, setSearchCriteria] = useState<any>({
    facets: props.preFilters.filters,
    searchTerm: props.preFilters.search,
    sortOption: props.initialSort
  });
  const [isInfiniteDisabled, setInfiniteDisabled] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const { connected } = useConnection();
  const history = useHistory();
  const { getUserInfo, userLanguageCode } = useSession();
  const [present, dismiss] = useIonLoading();

  const sortRef = useRef<HTMLIonModalElement>(null);
  const filterRef = useRef<HTMLIonModalElement>(null);
  const isFirstRenderPresearch = useRef(true);

  useEffect(() => {    
    console.log("from use effect filter")
    setPreviousSearch(searchCriteria.searchTerm);
    loadNextCatalogPage(true, true, previousSearch !== searchCriteria.searchTerm);
  }, [searchCriteria]);

  useEffect(() => {    
    if(isFirstRenderPresearch.current){
      console.log("first render filters")
      isFirstRenderPresearch.current = false;
    } else {
      let searchTerm = ""
      let facets = [];
      console.log("from use effect pre search")
      setCatalogPage(1);
      setPreviousSearch(searchCriteria.searchTerm);  

      if(props.preFilters.filters.length > 0) {
        setSelectedFacets(props.preFilters.filters);
        facets = props.preFilters.filters;
      }

      if(props.preFilters.search != "") {
        searchTerm = props.preFilters.search;
      }

      setSearchCriteria({...searchCriteria, searchTerm: searchTerm, facets: facets})
    }
  }, [props.preFilters]);

  const handleSortOpen = () => {
    sortRef.current?.present();
  };

  const handleSortClose = () => {
    sortRef.current?.dismiss();
  };

  const handleFilterOpen = () => {
    filterRef.current?.present();
  }

  const handleFilterClose = () => {
    if(filterRef && filterRef.current){
      filterRef.current?.dismiss();
    }
  };

  const selectSort = async (sortOption: string) => {
    sortRef.current?.dismiss();
    await setCatalogPage(1);
    setSearchCriteria({...searchCriteria, sortOption: sortOption})
  };


  function generateFacets(facets: any) {
    let usableFacets = [];

    for (var key in facets) {
      if (facets[key] && facets[key].terms && facets[key].terms.length > 0) {
        let tempFacets: any = {
          title: convertToTitleCase(key).trim().replace(/\/ /g, "/"),
          options: [],
        };

        facets[key].terms.forEach((term: any) => {
          tempFacets.options.push({ title: term[0].replace(/\/ /g, "/"), amount: term[1] });
        });

        usableFacets.push(tempFacets);
      }
    }

    setAvailibleFacets(usableFacets);
  }

  function facetToggleEvent(term: any){
    let currentFacets = selectedFacets;
    if (term.checked) {
      var index = currentFacets.indexOf(term.value);
      if (index !== -1) {
        currentFacets.splice(index, 1);
      }

      setSelectedFacets([...currentFacets]);
    } else {
      setSelectedFacets([...selectedFacets, ...[term.value]]);
    }
  }

  function generateFacetQueryString() {
    let queryString = "";
    searchCriteria.facets.forEach((facet: any) => {
      let splitString = facet.split("|");
      let facetName = splitString[0];
      let term = splitString[1];
      let facetNameFixed = facetName.replace(/  /g, " ").replace(/ /g, "+");
      facetNameFixed = facetNameFixed.replace(/\/\+/g, "/")
      queryString += "&tags" + "[" + facetNameFixed + "][]=" + term.replace(/  /g, " ").replace(/ /g, "+");
    });

    return queryString.substring(1);
  }

  function generateFacetPWAQueryString() {
    let queryString = "";
    searchCriteria.facets.forEach((facet: any) => {
      facet = facet.replace("|", ":");
      queryString += "|" + facet.replace(/  /g, " ")
    });

    return queryString.substring(1);
  }

  async function applyFacetFilters(searchTerm: string) {
    if (selectedFacets.length > 0 || searchTerm !== "") {
      await setCatalogPage(1);
      setSearchCriteria({...searchCriteria, searchTerm: searchTerm, facets: selectedFacets})
    }
  }

  async function clearFilters() {
    await setCatalogPage(1);
    await setSelectedFacets([]);
    setSearchCriteria({...searchCriteria, searchTerm: "", facets: []})
  }

  function convertToTitleCase(text: string) {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  const loadNextCatalogPage = async (reset: boolean, showLoading: boolean, isSearchTermChange: boolean, preSearch?: string) => {
    let catalogId  = ""
    let catalogPageNum = catalogPage;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.catalogId !== null) {
      catalogId = params.catalogId;
      let urlParams: any = {catalogId: catalogId}
      if(searchCriteria.facets.length > 0) {
        urlParams.tags = generateFacetPWAQueryString();
      }

      if(searchCriteria.searchTerm != "") {
        urlParams.query = searchCriteria.searchTerm;
      }

      history.push({
        pathname: '/explore/catalogs',
        search: "?" + new URLSearchParams(urlParams).toString()
      })
    } else {
      history.replace('/explore');
    }

    if(catalogId !== "") {
      if(showLoading) {
        await present({ message: "Loading products..." });
      }
      let userData: any = await getUserInfo();
      if (userData.customer) {
        await setCatalogName(userData.customer.name);
      }

      let sortId = searchCriteria.sortOption.id;

      // we must use this sort option whenever a search term is used https://jira4emerald.atlassian.net/browse/CMP-264
      // changing the sort order will re-run the search and this condition should not be hit again
      // if search term is cleared, reset sort order back to default
      if(isSearchTermChange) {
        if(sortId !== "relevance" && (preSearch || searchCriteria.searchTerm)) {
          setSearchCriteria({...searchCriteria, sortOption: {
            name: "Relevance",
            id: "relevance"
          }})

          sortId = "relevance";
          catalogPageNum = 1;
        } else {
          if(sortId === "relevance" && !preSearch && (!searchCriteria.searchTerm || searchCriteria.searchTerm === "")) {
            setSearchCriteria({...searchCriteria, sortOption: props.sortOptions[0]})

            sortId = props.sortOptions[0].id;
            catalogPageNum = 1;
          }
        }
      }

      if (userData.catalogs.length > 0 && userData.customer_number !== "") {
        let catalogResults = await getCatalog(
          catalogId,
          userData.customer_number,
          sortId,
          generateFacetQueryString(),
          false,
          true,
          true,
          catalogPageNum,
          preSearch ? preSearch : searchCriteria.searchTerm,
          connected
        );
        console.log("catalog data", catalogResults);

        if (catalogResults.facets && catalogResults.facets.Region && catalogPageNum === 1) {
          generateFacets(catalogResults.facets);
        }

        let resultItems: any[] = [];
        for (const result of catalogResults.results) {
          if (result.variations.length > 0) {
            resultItems.push(result);
          }
        }

        if(catalogResults.totalResults || catalogResults.totalResults == 0) {
          setTotalResultCount(catalogResults.totalResults)
        }
        setCatalogPage(catalogPage + 1);

        if (!reset) {
          await setResultList([...resultList, ...resultItems]);
        } else {
          await setResultList(resultItems);
          await setInfiniteDisabled(false);
        }
      }

      await dismiss();
      handleFilterClose();
    }

    setIsFirstLoad(false)
  };

  const loadResults = async (ev: any) => {
    console.log("from infinite")
    await loadNextCatalogPage(false, false, false);

    ev.target.complete();

    if (resultList.length < (catalogPage-1) * constants.PRODUCT_RESULTS_PER_PAGE) {
      setInfiniteDisabled(true);
    }
  };

  function totalFacetDisplayCount(): string  {
    let total = 0
    total += searchCriteria.facets.length;
    if(searchCriteria.searchTerm !== "") {
      total++;
    }
    return total !== 0 ? "(" + total + ")" : "";
  }

  return (
    <IonContent className="product-list">
      <div className="padding-area">
        {catalogName !== "" && <Breadcrumbs pageName={catalogName} />}
        <IonTitle size="large" class="catalog-name">
          <div className="ion-text-wrap">{catalogName}</div>
        </IonTitle>
        <IonText color="primary" class="result-text">
          <div className="ion-text-wrap">{"Results Found (" + totalResultCount + ")"}</div>
        </IonText>
      </div>
      <IonGrid style={{ "--ion-grid-padding": 0 }}>
        <IonRow className="filters">
          <IonCol size="6">
            <button className={"custom-button"} onClick={handleSortOpen}>
              <IonText slot="start">
                <span>{getTranslation('sortBy', userLanguageCode)}: </span>
                  <strong
                    style={{
                      maxWidth: "70px",
                      display: "inline-block",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      verticalAlign: "top"
                    }}
                  >
                  {searchCriteria.sortOption.name}
                </strong>
              </IonText>
              <IonIcon icon={chevronDownOutline} color="primary" />
            </button>
          </IonCol>
          <IonCol size="6">
            <button className={"custom-button " + (searchCriteria.facets.length > 0 || searchCriteria.searchTerm !== "" ? "has-filters" : "")} onClick={handleFilterOpen}>
              <IonText slot="start">
                {searchCriteria.facets.length == 0 && getTranslation('viewFilters', userLanguageCode)} 
                {searchCriteria.facets.length > 0 && getTranslation('activeFilters', userLanguageCode)} 
                {totalFacetDisplayCount()}
              </IonText>
              <IonIcon icon={optionsOutline} color={searchCriteria.facets.length > 0 || searchCriteria.searchTerm !== "" ? "secondary" : "primary"} />
            </button>
          </IonCol>
        </IonRow>
        {resultList.length == 0 && !isFirstLoad &&
          <div className="no-results"> 
            <EmptyList  
              title="No Results Found" 
              firstLineSubext={'Please adjust your search or filter options and try again'}
            />
            <IonRouterLink onClick={() => { clearFilters() }}>Clear filters</IonRouterLink>
          </div>
        }
        <IonRow>
          {resultList.map((product: any, index: number) => {
            return <ProductItem key={product._id} index={index} {...product} />;
          })}
        </IonRow>
        <IonInfiniteScroll onIonInfinite={loadResults} threshold="100px" disabled={isInfiniteDisabled}>
          <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Loading more data..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonGrid>
      <SortFilters selectedSortOption={searchCriteria.sortOption} modalRef={sortRef} handleSortClose={handleSortClose} selectSort={selectSort} sortOptions={props.sortOptions} />
      <FacetFilters
        modalRef={filterRef}
        handleFilterClose={handleFilterClose}
        availibleFacets={availibleFacets}
        toggleFacet={facetToggleEvent}
        applyFacets={applyFacetFilters}
        selectedFacets={selectedFacets}
        clearFilters={clearFilters}
        searchTerm={searchCriteria.searchTerm}
        setSearchKeyword={(term: string) => {
          setSearchCriteria({...searchCriteria, searchTerm: term})
        }}
        setSelectedFacets={setSelectedFacets}
        placeholder={getTranslation('searchProducts', userLanguageCode)}
      />
    </IonContent>
  );
};

export default ProductListing;
