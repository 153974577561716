import { IonIcon, IonButton } from '@ionic/react';
import './EmptyList.scss';

interface EmptyListProps {
  title: string;
  firstLineSubext: string;
  secondLineSubtext?: string;
  buttonText?: string;
  buttonLink?: string;
  icon?: any;
}

const EmptyList: React.FC<EmptyListProps> = (props) => {

  return (
    <div className="empty-list">
      {props.icon && 
        <div className='ion-text-center'>
          <IonIcon size='large' icon={props.icon} />
        </div>
      }
      <h2>
        {props.title}
      </h2>
      <div className='text'>
        <p className='ion-text-center' dangerouslySetInnerHTML={{ __html: props.firstLineSubext }}></p>
        {props.secondLineSubtext && <p className='ion-text-center'>{props.secondLineSubtext}</p>}
      </div>
      {props.buttonLink && props.buttonText && <IonButton routerLink={props.buttonLink} expand='full' shape='round'>{props.buttonText}</IonButton>}
    </div>
  );
};

export default EmptyList;
