import React, { createContext, useState, useEffect } from 'react';

export const ConnectionContext = createContext<{
  connected: boolean
}>({
  connected: false
});

export const ConnectionProvider: React.FC<any> = ({ children }) => {
  const [connected, setConnected] = useState<boolean>(false);

  useEffect(() => {
    // initial internet connection check 
    handleConnection();

    window.addEventListener('online', handleConnection);
    window.addEventListener('offline', handleConnection);
  }, []);

  function isReachable(url: string) {
    /**
     * Note: fetch() still "succeeds" for 404s on subdirectories,
     * which is ok when only testing for domain reachability.
     *
     * Example:
     *   https://google.com/noexist does not throw
     *   https://noexist.com/noexist does throw
     */
    return fetch(url, { method: 'HEAD', mode: 'no-cors' })
      .then(function(resp) {
        return resp && (resp.ok || resp.type === 'opaque');
      })
      .catch(function(err) {
        console.warn('[conn test failure]:', err);
      });
  }

  function handleConnection() {
    if (navigator.onLine) {
      isReachable("https://www.google.com").then(function(online) {
        if (online) {
          // handle online status
          console.log('online');
          setConnected(true)
        } else {
          console.log('no connectivity');
          setConnected(false)
        }
      });
    } else {
      // handle offline status
      console.log('offline');
    }
  }

  return (
    <ConnectionContext.Provider
      value={{
        connected
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
};