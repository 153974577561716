import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import Amplify from 'aws-amplify'
import aws_exports from './AppSyncConfig'

Amplify.configure(aws_exports)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: function (){
    let check = localStorage.getItem('APP_UPDATE');
    if(!check){
      localStorage.setItem('APP_UPDATE', 'ready');
    }
  }
});

// pwa elements https://capacitorjs.com/docs/web/pwa-elements
// at the moment we do not need this
// defineCustomElements(window);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
