import { API, graphqlOperation } from 'aws-amplify';
import { validateDynamic } from '../graphql/queries';
import { getStoredKey, setStoredKey, shouldUseCachedValue } from './storage-service';
import constants from '../constants';

export async function getUser(id_token: string, online: boolean) {
    const storedValue = await getStoredKey(constants.USER_INFO);
    
    if(shouldUseCachedValue(storedValue, online, true)){
        return storedValue;
    } else {
        const clientName = await getStoredKey(constants.CURRENT_CLIENT_NAME) || "callawaygolf";
        const result: any = await API.graphql(graphqlOperation(validateDynamic, {client: clientName, ct: id_token}));
        await setStoredKey(constants.USER_INFO, result.data)
        return result.data;
    }
}