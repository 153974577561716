import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router';
import { useSession } from './useSession';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const { isAuthenticated, authValidated } = useSession(); 

  return (
    <>
      {authValidated && <Route {...rest} render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/login?private=true" /> )} /> }
    </>
  );
};