import React from "react";
import {
  IonContent,
  IonPage
} from "@ionic/react";
import LoginForm from "components/login/login-form/LoginForm";
const Login: React.FC = () => {

  return (
    <IonPage className="login-page">
      <IonContent className="main-content">

        <LoginForm />

      </IonContent>
    </IonPage>
  );
};

export default Login;
