import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import SideMenu from './components/side-menu/SideMenu';
import Header from './components/header/Header';
import Explore from './pages/explore/Explore';
import Brands from './pages/explore/Brands';
import BrandDetail from './pages/explore/BrandDetail';
import Cart from './pages/cart/Cart';
import Submitted from './pages/submitted/Submitted';
import Checkout from './pages/checkout/Checkout';
import Search from './pages/search/Search';
import Notifications from './pages/notifications/Notifications';
import OrderDetail from './pages/order-detail/OrderDetail';
import OrderListing from './pages/order-listing/OrderListing';
import ProductListing from './pages/product-listing/ProductListing';
import ProductDetail from './pages/product-detail/ProductDetail';
import UserPreferences from './pages/user-preferences/UserPreferences';
import Login from './pages/login/Login';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import NoAccess from './pages/no-access/NoAccess';
import { SessionProvider } from './services/SessionContext';
import { PrivateRoute} from './services/PrivateRoute';
import { CartProvider } from './services/CartContext';
import { ToastProvider } from './services/ToastContext';
import { ConnectionProvider } from './services/ConnectionContext';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '/node_modules/swiper/swiper.scss';
import '@ionic/react/css/ionic-swiper.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Project specific themes */
import './theme/fonts.scss';
import './theme/variables.scss';
import './theme/global.scss';
import './theme/modal.scss';

setupIonicReact({
  swipeBackEnabled: false
});

const App: React.FC = () => {

  return (
    <IonApp>
      <ConnectionProvider>
        <SessionProvider>
          <ToastProvider>
            <IonReactRouter>
              <CartProvider>

                <SideMenu />
                <Header />

               {/* use iOS mode since the animations match more closely to what was designed */}
                <IonRouterOutlet id="main" mode={"ios"}>
                  <Route path="/" exact={true}>
                    <Redirect to="/explore" />
                  </Route>
                  <Route path="/no-access" exact={true}>
                    <NoAccess />
                  </Route>
                  <Route path="/login" exact={true}>
                    <Login />
                  </Route>
                  <Route path="/forgot-password" exact={true} >
                    <ForgotPassword />
                  </Route>
                  <PrivateRoute path="/explore" component={Explore} exact={true}/>
                  <PrivateRoute path="/search" component={Search} />
                  <PrivateRoute path="/cart" component={Cart} />
                  <PrivateRoute path="/submitted/:id" component={Submitted} />
                  <PrivateRoute path="/product-detail/:id" component={ProductDetail} />
                  <PrivateRoute path="/edit-product/:id" component={ProductDetail} />
                  <PrivateRoute path="/explore/brands/:id" component={BrandDetail} exact={true}/>
                  <PrivateRoute path="/explore/brands" component={Brands} exact={true}/>
                  <PrivateRoute path="/explore/catalogs" component={ProductListing} />
                  <PrivateRoute path="/manage/orders/order-detail/:id" component={OrderDetail} />
                  <PrivateRoute path="/manage/orders" component={OrderListing} exact={true} />
                  <PrivateRoute path="/manage" component={OrderListing} exact={true} />
                  <PrivateRoute path="/checkout" component={Checkout} />
                  <PrivateRoute path="/notifications" component={Notifications} />
                  <PrivateRoute path="/user-preferences" component={UserPreferences} />
                  <Route>
                    <Redirect to="/explore" />
                  </Route>
                </IonRouterOutlet>
              </CartProvider>
            </IonReactRouter>
          </ToastProvider>
        </SessionProvider>
      </ConnectionProvider>
    </IonApp>
  );
};

export default App;
