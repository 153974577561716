import { IonButton, IonThumbnail} from '@ionic/react';
import { findItemImage } from "services/catalog-service";
import { getTranslation } from 'translations';
import { useSession } from 'services/useSession';
import CachedImage from 'components/cached-image/CachedImage';

interface ProductColorMenuProps {
  product: any;
  setCurrentIndex: Function;
  setSelectedColor: Function;
  selectedColor: any;
  currentIndex: number;
  innerRef: any;
}

const ProductColorMenu: React.FC<ProductColorMenuProps> = (props) => {
  const { userLanguageCode } = useSession();

  async function changeColor (index: number) {
    getSelectedColor(index);
    props.setCurrentIndex(index);
  }

  function getSelectedColor(index: number): string {
    if (props.product.variations[index]) {
      props.setSelectedColor(props.product.variations[index]);
    }
    return props.selectedColor;
  }
  
  return (
    <div className="color-menu">
      <div className="title">{getTranslation('color', userLanguageCode)}</div>
      <div className="thumbnail-items">
        {props.product.variations.map((variation: any, index: any) => {
            const selectedColor = props.currentIndex === index ? '#000' : '#bebebe';
            return (
              <IonButton fill="clear" expand="block" class="image-btn" key={index} onClick={() => changeColor(index)}>
                  <IonThumbnail key={index} class="product-thumbnail" style={{ borderColor:  selectedColor} as React.CSSProperties}>
                    <div className="img-thumb">
                      <CachedImage className='product-image' src={findItemImage(variation, "icon")} />
                    </div>
                  </IonThumbnail>
                </IonButton>
              );
          })}
          <div className="thumbnail-padding"></div>
        </div>
      <div className="color-selection light">{props.selectedColor.name != "." ? props.selectedColor.name : props.selectedColor.code}</div>
    </div>
  );
};

export default ProductColorMenu;
