/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const cognitoInfo = /* GraphQL */ `
  query CognitoInfo($client: String) {
    cognitoInfo(client: $client) {
      tenantId
      clientId
      name
      userPoolId
    }
  }
`;
export const clientDynamic = /* GraphQL */ `
  query ClientDynamic($client: String) {
    clientDynamic(client: $client) {
      name
      flags {
        whiteboard {
          logoMid
        }
      }
    }
  }
`;
export const brandDynamic = /* GraphQL */ `
  query BrandDynamic($type: String, $client: String) {
    brandDynamic(type: $type, client: $client) {
      client
      config {
        images {
          logo
          mainBackground
        }
        flags {
          embellishmentStrategies {
            provider
            iframeUrl
          }
          dropShip {
            ivendixRequireLocation
            requireLocation
            limitToCustomerCountry
            alwaysEnabled
            defaultCountry
            fieldMaxLengths {
              nameMaxLength
              cityMaxLength
              stateMaxLength
              zipMaxLength
              address1MaxLength
              address2MaxLength
            }
            availableCountries
            additionalFields {
              name
              label
              type
              required
            }
            fieldRegExps {
              nameRegExp
              address1RegExp
              address2RegExp
              cityRegExp
              stateRegExp
              zipRegExp
            }
          }
          hideCatalogPositionOption
          hideNotOrderable
          viewProducts
          showBrands
          noProducts
          directoryMode
          algoliaConfig {
            applicationId
            brandsIndex
            searchApiKey
          }
          initialPriceType
          supportUrl
        }
      }
    }
  }
`;
export const exploreDynamic = /* GraphQL */ `
  query ExploreDynamic(
    $client: String
    $customer_number: String
    $jwt: String
  ) {
    exploreDynamic(
      client: $client
      customer_number: $customer_number
      jwt: $jwt
    ) {
      dashboard {
        name
        carousel {
          _id
          priority
          items {
            position
            image
            video
            external_url
            headline
            sub_headline
            product_filter_set {
              display_name
              catalog_key
              custom_catalog_id
            }
            call_to_actions {
              display_name
              display_text
              type
              on_click_full {
                display_name
                catalog_key
                custom_catalog_id
                tags
                product_numbers
              }
            }
          }
        }
        marquee {
          _id
          priority
          items {
            position
            image
            video
            external_url
            headline
            sub_headline
            product_filter_set {
              display_name
              catalog_key
              custom_catalog_id
            }
            call_to_actions {
              display_name
              display_text
              type
              on_click_full {
                display_name
                catalog_key
                custom_catalog_id
                tags
                product_numbers
              }
            }
          }
        }
        links_list {
          _id
          priority
          links {
            _id
            image_content_type
            image_file_name
            image_file_size
            position
            title
            type
            url
            image_url
          }
        }
        navigation_settings {
          _id
          primary_items
          secondary_items
          explore_links
          manage_links
        }
      }
      notifications {
        _id
        created_at
        expiration_date
        published
        text
        type
        user_id
      }
    }
  }
`;
export const validateDynamic = /* GraphQL */ `
  query ValidateDynamic($ct: String, $client: String) {
    validateDynamic(ct: $ct, client: $client) {
      user {
        _id
        origin
        email
        currency_code
        type
        associated_customers
        is_manager
        is_disabled
        custom_catalogs_only
        first_name
        last_name
        username
        account_number
        customer_number
        name
        customer_name
        billing_address_lines
        inventory_source
        contribution_ids
        primary_user_id
        catalogs {
          _id
          allow_dropship
          archived
          classification
          created_at
          default_product_sort
          description
          inventory_mode
          key
          max_order_amount
          min_order_amount
          max_order_units
          min_order_units
          max_order_amount_for_currency
          min_order_amount_for_currency
        }
        customer {
          _id
          about
          access_key
          address {
            _id
            city
            country
            state
            street1
            street2
            street3
            zip
          }
          b2c
          country
          created_at
          name
          locations {
            _id
            account_notice
            address {
              _id
              city
              country
              state
              street1
              street2
              street3
              zip
            }
            address_lines
            name
            number
          }
          client_fields {
            ship_via
            drop_ship
          }
          number
          wholesale_price_group
          retail_price_group
        }
        search_recommendations {
          catalog_key
          custom_catalog_id
          display_name
          product_numbers
          tags
        }
      }
      token
      setCookie
    }
  }
`;
export const ordersDynamic = /* GraphQL */ `
  query OrdersDynamic(
    $cookie: String
    $Range: String
    $jwt: String
    $client: String
  ) {
    ordersDynamic(cookie: $cookie, Range: $Range, jwt: $jwt, client: $client) {
      _id
      author_id
      catalog_key
      created_at
      customer
      name
      number
      state
      submission {
        _id
        created_at
        state
      }
      submitted_by
      total_prices {
        wBASEUSD
        rBASEUSD
        elastic_wholesale
        elastic_retail
      }
      total_quantity
      catalog_name
      customer_name
      start_date
      total_upcharges
      total_discounts
      net_price
      updated_at
    }
  }
`;
export const orderDynamic = /* GraphQL */ `
  query OrderDynamic(
    $cookie: String
    $id: String
    $jwt: String
    $client: String
  ) {
    orderDynamic(cookie: $cookie, id: $id, jwt: $jwt, client: $client) {
      _id
      catalog_key
      client_created
      client_updated
      customer
      do_reject
      do_review
      do_submit
      exclude_zero_quantity
      name
      note
      notes
      pages {
        arrive_on
        client_fields {
          ship_via
          drop_ship
        }
        customer_number
        location_number
        name
        page_products {
          _id
          color_code
          color_name
          page_items {
            _id
            can_embellish
            quantity
            quantity_source {
              quantity
              source
            }
            stock_item_key
            stock_item_sku
            stock_item_upc
            total_prices {
              wBASEUSD
              rBASEUSD
              elastic_wholesale
              elastic_retail
            }
          }
          position
          product_number
          name
          embellishments {
            data {
              Key
              Value
            }
            type
          }
          total_prices {
            wBASEUSD
            rBASEUSD
            elastic_wholesale
            elastic_retail
          }
          catalog_key
        }
        type
        drop_ship_address {
          city
          country
          state
          address1
          address2
          zip
          name
          phone
          email
        }
      }
      platform_created
      platform_updated
      programs
      products {
        number
        name
        _id
        position
        description
        minimum_purchase
        region
        catalog_key
        primary_warehouse
        price_range {
          wBASEUSD
          rBASEUSD
          elastic_wholesale
          elastic_retail
        }
        variations {
          _id
          name
          code
          permissions
          embellishment_strategy
          can_embellish
          is_dropped
          stock_items {
            number
            name
            key
            upc
            sku
            position
            sold_in_multiples_of
            prices {
              wBASEUSD
              rBASEUSD
              elastic_wholesale
              elastic_retail
            }
            alt_name
          }
          images {
            A {
              icon
              detail
              large
              print
              original
            }
            P {
              icon
              detail
              large
              print
              original
            }
            S {
              icon
              detail
              large
              print
              original
            }
          }
          primary_warehouse
          filtered
        }
      }
      version_created
      version_updated
      number
      discounts
      total_prices {
        wBASEUSD
        rBASEUSD
        elastic_wholesale
        elastic_retail
      }
      net_price
      state
    }
  }
`;
export const salesProgramsDynamic = /* GraphQL */ `
  query SalesProgramsDynamic(
    $cookie: String
    $document_id: String
    $jwt: String
    $client: String
  ) {
    salesProgramsDynamic(
      cookie: $cookie
      document_id: $document_id
      jwt: $jwt
      client: $client
    ) {
      programs {
        id
        name
        description
        display_group
        rules
        discounts
        rules_details {
          type
          message
          target
          passed
          progress
        }
      }
    }
  }
`;
export const forgotPasswordDynamic = /* GraphQL */ `
  query ForgotPasswordDynamic($body: ForgotPasswordInput, $client: String) {
    forgotPasswordDynamic(body: $body, client: $client) {
      type
      content
    }
  }
`;
export const resetPasswordDynamic = /* GraphQL */ `
  query ResetPasswordDynamic($body: ChangePasswordInput, $client: String) {
    resetPasswordDynamic(body: $body, client: $client) {
      ok
      reset_password_token
    }
  }
`;
export const catalogDynamic = /* GraphQL */ `
  query CatalogDynamic(
    $keyword: String
    $tags: String
    $catalog: String
    $customer: String
    $sort: String
    $dropped: Boolean
    $Range: String
    $tag_facets: Boolean
    $hoist_quantities: Boolean
    $jwt: String
    $client: String
  ) {
    catalogDynamic(
      keyword: $keyword
      tags: $tags
      catalog: $catalog
      customer: $customer
      sort: $sort
      dropped: $dropped
      Range: $Range
      tag_facets: $tag_facets
      hoist_quantities: $hoist_quantities
      jwt: $jwt
      client: $client
    ) {
      results {
        number
        name
        _id
        position
        description
        minimum_purchase
        region
        catalog_key
        primary_warehouse
        price_range {
          wBASEUSD
          rBASEUSD
          elastic_wholesale
          elastic_retail
        }
        variations {
          _id
          name
          code
          permissions
          embellishment_strategy
          can_embellish
          is_dropped
          stock_items {
            number
            name
            key
            upc
            sku
            position
            sold_in_multiples_of
            prices {
              wBASEUSD
              rBASEUSD
              elastic_wholesale
              elastic_retail
            }
            alt_name
          }
          images {
            A {
              icon
              detail
              large
              print
              original
            }
            P {
              icon
              detail
              large
              print
              original
            }
            S {
              icon
              detail
              large
              print
              original
            }
          }
          primary_warehouse
          filtered
        }
      }
      stock_shipments {
        key
        warehouse_id
        catalog_key
        available_on
        quantity
        release_date
      }
      facets {
        Brand {
          _type
          missing
          total
          other
          terms
        }
        Collection {
          _type
          missing
          total
          other
          terms
        }
        Customizable {
          _type
          missing
          total
          other
          terms
        }
        Region {
          _type
          missing
          total
          other
          terms
        }
        Season {
          _type
          missing
          total
          other
          terms
        }
        Color {
          _type
          missing
          total
          other
          terms
        }
        CategoryTag {
          _type
          missing
          total
          other
          terms
        }
        ClassTag {
          _type
          missing
          total
          other
          terms
        }
        GenderTag {
          _type
          missing
          total
          other
          terms
        }
        StyleTypeTag {
          _type
          missing
          total
          other
          terms
        }
        TypeTag {
          _type
          missing
          total
          other
          terms
        }
      }
    }
  }
`;
export const catalogDynamicNew = /* GraphQL */ `
  query CatalogDynamicNew(
    $keyword: String
    $tags: String
    $catalog: String
    $customer: String
    $sort: String
    $dropped: Boolean
    $Range: String
    $tag_facets: Boolean
    $hoist_quantities: Boolean
    $jwt: String
    $client: String
  ) {
    catalogDynamicNew(
      keyword: $keyword
      tags: $tags
      catalog: $catalog
      customer: $customer
      sort: $sort
      dropped: $dropped
      Range: $Range
      tag_facets: $tag_facets
      hoist_quantities: $hoist_quantities
      jwt: $jwt
      client: $client
    ) {
      results {
        number
        name
        _id
        position
        description
        minimum_purchase
        region
        catalog_key
        primary_warehouse
        price_range {
          wBASEUSD
          rBASEUSD
          elastic_wholesale
          elastic_retail
        }
        variations {
          _id
          name
          code
          permissions
          embellishment_strategy
          can_embellish
          is_dropped
          stock_items {
            number
            name
            key
            upc
            sku
            position
            sold_in_multiples_of
            prices {
              wBASEUSD
              rBASEUSD
              elastic_wholesale
              elastic_retail
            }
            alt_name
          }
          images {
            A {
              icon
              detail
              large
              print
              original
            }
            P {
              icon
              detail
              large
              print
              original
            }
            S {
              icon
              detail
              large
              print
              original
            }
          }
          primary_warehouse
          filtered
        }
      }
      stock_shipments {
        key
        warehouse_id
        catalog_key
        available_on
        quantity
        release_date
      }
      facets
    }
  }
`;
