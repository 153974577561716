import { useContext } from 'react';
import { ToastContext } from './ToastContext';

export const useToaster = () => {
  const { addNotification, notifications, popNotification } =
    useContext(ToastContext);

  if (ToastContext === undefined) throw new Error('useToast must be used within a ToastProvider');

  return { addNotification, notifications, popNotification };
};