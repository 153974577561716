import {
  IonButton,
  IonRouterLink,
} from "@ionic/react";
import CachedImage from "components/cached-image/CachedImage";
import { useSession } from "services/useSession";
import { getTranslation } from 'translations';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper';

import "./ExploreBrands.scss";
import { useEffect, useState } from "react";

interface ExploreBrandsProps {
  brandList: any[];
}

const ExploreBrands: React.FC<ExploreBrandsProps> = (props) => {
  const [brandsToShow, setBrandsToShow] = useState<any[]>([]);
  const [currentSlidePage, setCurrentSlidePage] = useState<number>(1);
  const { userLanguageCode } = useSession();
  const slidesPerPage = 20;

  useEffect(() => {
    let slidesToAdd = props.brandList.slice(0, slidesPerPage * currentSlidePage)
    setBrandsToShow(slidesToAdd)
  }, [currentSlidePage]);

  function onSwiperSlideChange (swiperCore: any) {
    const { realIndex } = swiperCore;

    if(realIndex == ((currentSlidePage * slidesPerPage) - 1)) {
      setCurrentSlidePage((currentSlidePage+1))
    }
  }

  return (
    <div className="explore-brands">
      <h2>{getTranslation('brands', userLanguageCode)}</h2>
      {brandsToShow.length > 0 && 
        <Swiper
          slidesPerView={1.15}
          spaceBetween={24}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination]}
          onSlideChange={onSwiperSlideChange}
        >
          {brandsToShow.map((brand: any, index: number) => {
            let brandImage = "";
            if(brand.brandTile && typeof brand.brandTile === "string") {
              brandImage = brand.brandTile;
            } else {
              brandImage = brand.image;
            }

            return (
              <SwiperSlide key={brand.name}>
                <IonRouterLink routerLink={"/explore/brands/" + brand.name} className="brand">
                  <CachedImage src={brandImage} useImgTag={true} />
                  <p>{brand.name}</p>
                </IonRouterLink>
              </SwiperSlide>
            );
          })}
        </Swiper>
      }
      <div className="view-all">
        <IonButton routerLink="/explore/brands" color={"primary"} shape="round">{getTranslation('viewAllBrands', userLanguageCode)}</IonButton>
      </div>
    </div>
  );
};

export default ExploreBrands;
