import { IonContent, IonPage, IonTitle} from '@ionic/react';

const Checkout: React.FC = () => {

  return (
    <IonPage className='page-with-menu'>
      <IonContent>
        <IonTitle size="large">Checkout</IonTitle>
        <IonContent class="ion-padding">
          Checkout PAGE
        </IonContent>
      </IonContent>
    </IonPage>
  );
};

export default Checkout;
